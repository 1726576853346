
import React from 'react';

import { compose } from 'recompose';
import { Button ,Drawer,Position} from "@blueprintjs/core";
import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';

import MenuTop from './topMenu'
import Lists from './liste'
import RightBar from '../RightBar'
import BarraCentrale from '../BarraCentrale'
import imgLogo from '../img/logoimg.png'





class Landing extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open:false,

    };
  }




  render() {
    let {open} = this.state;
    return (
      <div className='layoutApp'>
        <Drawer
          style={{background:'#eee'}}
            icon="info-sign"
            onClose={()=>this.setState({open:false})}
            isOpen={open}
            position={Position.LEFT}
            {...this.state}
        ><div style={{marginTop:50}}><Lists color={true} /></div></Drawer>
        <div style={{background:'#dedede',color:'white'}}>
          <div style={{display:'flex'}} id='title'>
            <div onClick={()=>this.setState({open:true})} className='mobileP'><p className='menuM' style={{background:'transparent'}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/></svg></p></div>
            <img style={{marginTop:50,marginBottom:50,marginLeft: 'auto',marginRight: 'auto'}} src={imgLogo} />
          </div>
          <MenuTop/>

          <div className='desckTop'>
            <Lists />
            <div style={{padding:10}}>
              <p onClick={()=>this.props.firebase.doSignOut()} style={{color:'#ff5454',cursor: 'pointer',fontWeight:'800'}}>logout</p>
            </div>
        </div>
        <div className='boxAdd'>
        </div>
        </div>
        <div style={{width:'100%',background:'#f3f3f3',overflow: 'scroll',height:'100vh',position:'relative'}}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

const condition = authUser => {
console.log('mostro auth');
console.log(authUser);
if(authUser){
  if(authUser.roles){

    let indexStaff = authUser.roles.indexOf('STAFF_W')
    let indexAdmin = authUser.roles.indexOf('pollo')
    let okVai = false
    authUser.roles.forEach((item, i) => {
      console.log(item);
      if(item == 'ADMIN_S' || item == 'STAFF_W'){
        console.log('qui dovrei dare true');
        okVai = true
      }
    });

    console.log('qui dò un risultato');
    console.log(okVai);
    return okVai


  /*  if(indexStaff || indexAdmin > -1){
      return true;
    }else {
      return false
    }*/
  }else {
    return false
  }

}else {
  return false
}


}

export default compose(
  withFirebase,
  withAuthorization(condition),
)(Landing);
