import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import {Link} from "react-router-dom";
import Noimg from "../VenditoreOrdine/no-img.jpg";
import ReactTable from 'react-table-6'
import Img from 'react-image'
import 'react-table-6/react-table.css'
import './fix.css'
import {
    Button,
    H5,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Position,
    Spinner,
    Switch,
    Tag,
    Tooltip,
} from "@blueprintjs/core";

function crea(x){
  let ppp = '';
  console.log('mostrooooo');
  console.log(x);
  x.forEach((item, i) => {
    ppp = ppp+' '+item.nome
  });


  return ppp
}

function creaCont(x){



  return x.length
}

const columns = [{Header: () => (
    <p style={{height:30,fontWeight:'800'}}>
        Categoria
    </p>
  ),
 accessor: 'nome', // String-based value accessors!
 Cell: props => <p style={{textAlign: 'center',paddingTop: 31,fontWeight: 800}}>{(props.value ? props.value : 'no' )}</p> // Custom cell components!
 },{
   Header: () => (
      <p style={{height:30,fontWeight:'800'}}>
          Piatti
      </p>
    ),
   accessor: 'piatti', // String-based value accessors!
  Cell: props => <p style={{textAlign: 'center',paddingTop: 31,fontWeight: 800}}>{creaCont(props.value)}</p> // Custom cell components!
},{
   Header: () => (
      <p style={{height:30,fontWeight:'800'}}>
          Stato
      </p>
    ),
   accessor: 'active',
   Cell: props => <p style={{textAlign: 'center',paddingTop: 31,fontWeight: 300}}>{(props.value ? 'attiva' : 'non attiva' )}</p> // Custom cell components!
 }, {
   Header: () => (
      <p style={{height:30,fontWeight:'800'}}>
          Stato
      </p>
    ),
   accessor: '_id',
   Cell: props => <div style={{textAlign:'center'}}><Link to={{
      pathname: '/modificacategoria', state: {
      id: props.value}}
      }>
   <button  type="button" className='actionButton' modifier>modifica</button>
   </Link>
   </div>
 }]

class HomePage extends Component {

  constructor(props) {
    super(props);
    //this.getXXXX()
    this.state = {
      prodotto:[]
    };


  }


  getMyOrganiz(x){
    const ApiTags= new API ({url:'/organizazioneadmin/'+x});
    var entity= {name:"organizazioneadmin"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.organizazioneadmin
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati organ');
        console.log(res.data._id);
        newThis.setState({rifOrganiz:res.data._id})
        newThis.getXXXX(res.data._id);
      });
    })

  }


  getXXXX(x){
    //usa per il get
    const ApiTags= new API ({url:'/categoriabyorg'});
    var entity= {name:"categoriabyorg"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.categoriabyorg
      .getOne({id:x},ppp)
      .then(res=>{

        newThis.setState({prodotto:res.data})
        console.log('ciaooo');
        console.log(newThis.state.prodotto);
      });
    })
  }

   preCancella(x){
     var txt;
     var r = window.confirm("Sei sicuro di cancellare l'articolo?");
     if (r == true) {
     this.cancella(x)
   } else {
   txt = "You pressed Cancel!";
  }
   }

   cancella(x){
     console.log('gang'+x);

     const ApiTags= new API ({url:'/prodotto/'+x});
     var entity= {name:"prodotto"};
     ApiTags.createEntity(entity);
     var newThis = this;
     this.props.firebase.tokenForApi(function(id) {
       var ppp = {'Authorization' : 'Bearer '+id};
       ApiTags.endpoints.prodotto
       .delete({id:x},ppp)
       .then(res=>{

         newThis.getXXXX()
       });
     })


   }



  getPar(){
    const ApiTags= new API ({url:'/lavori'});
    var entity= {name:"lavori"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.lavori
      .getAllOk(ppp)
      .then(res=>{
        //  console.log(res.data);
        });
    })
  }


  componentDidMount() {
    this.getMyOrganiz()

  }

render() {
    let {items,prodotto} = this.state;
    return (
      <div style={{height:'100%',overflowY: 'auto',padding:10,background:'#f9fafb'}}>
        <div style={{maxWidth:1050,marginLeft:'auto',marginRight:'auto'}}>
        <h1 style={{}}>Categorie</h1>
      <div style={{width:'100%',marginBottom:20,height: 31}}>
        <Link to="/newprod"><button  type="button" className='actionButton' modifier>Aggiungi prodotto</button>
        </Link>
      </div>



          <ReactTable
          getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: (e, handleOriginal) => {
                  console.log('A Td Element was clicked!')
                  console.log('it produced this event:', e)
                  console.log('It was in this column:', column)
                  console.log('It was in this row:', rowInfo.row._id)
                  console.log('It was in this table instance:', instance)

                  // IMPORTANT! React-Table uses onClick internally to trigger
                  // events like expanding SubComponents and pivots.
                  // By default a custom 'onClick' handler will override this functionality.
                  // If you want to fire the original onClick handler, call the
                  // 'handleOriginal' function.
                  if (handleOriginal) {
                    handleOriginal()
                  }
                }
              }
            }}
            style={{marginTop:20}}
            className='pollo'
            defaultPageSize={10}
            style={{background:'var(--p-surface,#fff)',borderRadius:5,padding:10}}
            data={prodotto}
            columns={columns}
          />

          <div style={{display:'grid',gridTemplateColumns: '50% 50%',
    gridGap: '10px'}}>
        {/*prodotto.map((x,i)=>{
          return (
          <div style={{display:'flex',marginBottom:'50px'}}>
            <img style={{width:'150px',height:'150px',marginRight:'20px'}} src={x.gallery.length > 0 ? x.gallery[0] : Noimg}/>
        <div>

            <p>{x.nome}</p>
            <p>{x.descrizione}</p>
              <p>{x.prezzo}€</p>
                <Link to={{
    pathname: '/modificaprodotto', state: {
      id: x._id}}
    }>
                <button type="button" class="bp3-button bp3-icon-add .modifier" modifier>modifica</button>
                </Link>
                <button type="button" style={{backgroundColor:'red'}} onClick={()=>this.preCancella(x._id)} class="bp3-button bp3-icon-add .modifier" modifier>cancella</button>

            </div>
          </div>)
        })*/}
</div>
      </div>
      </div>



    )
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(HomePage);
