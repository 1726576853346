import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Layout from '../Layout';
import LayoutDue from '../LayoutDue';
import HomePagePages from '../Home';
import Web from '../Web';
import ClientiPages from '../Clienti';
import OrdiniPages from '../Ordini';
import Prenotazioni from '../Prenotazioni';
import ProdottiPages from '../Prodotti';
import Post from '../Post';
import NewProdPages from '../NewProd';
import AddPiattoProdPages from '../add_piatto';

import addPost from '../add_post';

import NewClientePages from '../NewCliente';
import MaterialePages from '../Materiale';
import NewMaterialePages from '../NewMateriale';
import ModificaMaterialePages from '../ModificaMateriale';
import ModificaProdottoPages from '../ModificaProdotto';
import ModificaCategoriaPages from '../ModificaCategoria';
import ModificaClientePages from '../ModificaCliente';
import Modificopost from '../ModificaPost';
import VenditorePages from '../Venditore';
import VenditoreOrdinePages from '../VenditoreOrdine';
import SettingStore from '../SettingStore';
import SettingTime from '../SettingTime';
import Menus from '../Menus';
import NewMenu from '../NewMenu';
import Menu from '../Menu'

import ListaR from '../ListaR';
import SettingStoreAdmin from '../SettingAdmin';

import QuikChange from '../QuikChange';

//import Pdf from '../Pdf';

import OrdinePages from '../Ordine';



import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import * as ROUTES from '../../constants/routes';

import { withAuthentication } from '../Session';


const FromtEndLayout = ({children, ...rest}) => {
  return (
    <div className="page page-login">
      <Layout  children={children}/>
    </div>
  )
}

const FromtEndLayoutDue = ({children, ...rest}) => {
  return (
    <div className="page page-login">
      <LayoutDue  children={children}/>
    </div>
  )
}

const FromtEndRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={matchProps => (
      <FromtEndLayout>
          <Component {...matchProps} />
      </FromtEndLayout>
    )} />
  )
};

const FromtEndRouteNew = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={matchProps => (
      <FromtEndLayoutDue>
          <Component {...matchProps} />
      </FromtEndLayoutDue>
    )} />
  )
};


const App = () => (
  <Router>
    <div>
      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route path={ROUTES.VENDITORE} component={VenditorePages} />
      <Route path={ROUTES.VENDITOREORDINE} component={VenditoreOrdinePages} />
      {/*<Route path={ROUTES.PDF} component={Pdf} />*/}

      <FromtEndRoute exact path={ROUTES.LANDING} component={HomePagePages} />
      <FromtEndRoute exact path={ROUTES.CLIENTI} component={ClientiPages} />
      <FromtEndRoute exact path={ROUTES.ORDINI} component={OrdiniPages} />
        <FromtEndRoute exact path={ROUTES.PRENOTAZIONI} component={Prenotazioni} />



      <FromtEndRoute exact path={ROUTES.ORDER} component={OrdinePages} />



      <FromtEndRoute exact path={ROUTES.PRODOTTI} component={ProdottiPages} />

      <FromtEndRoute exact path={ROUTES.POST} component={Post} />

      
      <FromtEndRoute exact path={ROUTES.NEWPROD} component={NewProdPages} />
      <FromtEndRoute exact path={ROUTES.NEWPIATTO} component={AddPiattoProdPages} />
      <FromtEndRoute exact path={ROUTES.NEWPOST} component={addPost} />


      

      



      <FromtEndRoute exact path={ROUTES.WEB} component={Web} />
      <FromtEndRoute exact path={ROUTES.RSETTING} component={SettingStore} />
      <FromtEndRoute exact path={ROUTES.ORARISETTING} component={SettingTime} />
      <FromtEndRoute exact path={ROUTES.MENUS} component={Menus} />
      <FromtEndRoute exact path={ROUTES.ADDMENU} component={NewMenu} />
      <FromtEndRoute exact path={ROUTES.MENU} component={Menu} />





      <FromtEndRoute exact path={ROUTES.QUIK} component={QuikChange} />


      <FromtEndRoute exact path={ROUTES.RADMIN} component={ListaR} />
      <FromtEndRoute exact path={ROUTES.RSETTINGADMIN} component={SettingStoreAdmin} />






      <FromtEndRoute exact path={ROUTES.NEWCLIENTE} component={NewClientePages} />
      <FromtEndRoute exact path={ROUTES.GRUPPI} component={MaterialePages} />
      <FromtEndRoute exact path={ROUTES.NEWMATERIALE} component={NewMaterialePages} />
      <FromtEndRoute exact path={ROUTES.MODIFICAMATERIALE} component={ModificaMaterialePages} />
      <FromtEndRoute exact path={ROUTES.CATEGORIEEDIT} component={ModificaCategoriaPages} />
      <FromtEndRoute exact path={ROUTES.MODIFICAPRODOTTO} component={ModificaProdottoPages} />
      <FromtEndRoute exact path={ROUTES.MODIFICAPOST} component={Modificopost} />

      
      <FromtEndRoute exact path={ROUTES.MODIFICACLIENTE} component={ModificaClientePages} />

    </div>
  </Router>
);

export default withAuthentication(App);
