import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import Dropzone from 'react-dropzone'
import firebase from 'firebase';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import Img from 'react-image'
import {Link , Redirect} from "react-router-dom";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import MapInput from '../MapInput';

import {
    Button,
    H5,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Position,
    Spinner,
    Switch,
    Tag,
    Tooltip,
    TagInput
} from "@blueprintjs/core";
import { Select , ItemRenderer } from "@blueprintjs/select";
import { BrowserRouter as Router, Route } from "react-router-dom";
import 'antd/dist/antd.css';
import { TimePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = TimePicker;

const SortableItem = SortableElement(({value, sortIndex}) => (
  <div style={{width:150}}>
      <Img style={{width:150}} src={value} loader={<p>pollo</p>}/>
  </div>
));

const SortableList = SortableContainer(({items}) => {
return (
  <div style={{overflow: 'scroll hidden',whiteSpace:'nowrap',display:'flex'}}>
    {items.map((value, index) => (
      <SortableItem key={`item-${value}`} index={index} value={value} />
    ))}
  </div>
);
});

class NewProd extends Component {

  constructor(props) {
    super(props);
    //this.getXXXX()
    this.state = {
      whatupNumber:null,
      settimana:['lunedì','martedì','mercoledì','giovedì','venerdì','sabato','domenica'],
      typeFlow:null,
      paypalClienid:null,
      take_away:false,
      take_away_time:null,
      delivery:false,
      delivery_time:[],
      preordine:false,
      preordine_time:null,
      orari:{},
      payPal:false,
      contanti_Alla_consegna:false,
      aperto:false,
      active:false,
      ristoranti:[],
      ristorantiSel:[],
      categorie:[],
      categorieSel:[],
      objLocation:null,
      objSpediz:null,
      radiusSped:1000,
      img:null,
      idTelegram:null,
      rifOrganiz:null,
      clienti:[],
      nome:null,
      cod_prod:null,
      descrizione:null,
      prezzo:0,
      variante:[],
      trueVariante:false,
      continuativo:false,
      listax:[{id:'pppp',alias:'pollo'},{id:'yo',alias:'yo'},{id:'suc',alias:'suc'}],
      listaArtigiani:[],
      listaStagione:[],
      gallery:[],
      artigiani:{},
      provaArt:{},
      collezione:null,
      redirect:false,
      valori:null,
      progress:null
    };


  }

  getMyOrganiz(x){
    const ApiTags= new API ({url:'/organizazioneadmin/'+x});
    var entity= {name:"organizazioneadmin"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.organizazioneadmin
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati organ');
        console.log(res.data._id);
        newThis.setState({rifOrganiz:res.data._id})
        newThis.getXXXX(res.data._id)
      });
    })

  }


  getMyStores(x){
    const ApiTags= new API ({url:'/ristorantiadmins/'+x});
    var entity= {name:"ristorantiadmins"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.ristorantiadmins
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati');
        console.log(res.data);
        newThis.setState({ristoranti:res.data})

      });
    })

  }

  addImg(xxx){


    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/prodotto'});
      var entity= {name:"prodotto"};

      Item.createEntity(entity);


      var obj = {
        id:newThis.props.location.state.id,
        gallery:[xxx]


      }

      Item.endpoints.prodotto
      .update(obj,ppp)
      .then(res=>{

      });
    })



  }

  handleUpload = (images) => {
      let img = this.state.img;
      var storage = firebase.storage();
      let newThis = this;
      //const image = images[0];
      images.forEach((image, i) => {
        const uploadTask = storage.ref(`images/${image.name}`).put(image);
        uploadTask.on(
          "state_changed",
          snapshot => {
            // progress function ...
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            this.setState({ progress });
          },
          error => {
            // Error function ...
            console.log(error);
          },
          () => {
            // complete function ...
            storage
              .ref("images")
              .child(image.name)
              .getDownloadURL()
              .then(url => {
                console.log('qui finitoooooo');
                console.log(url);
                img = url;

                newThis.setState({img})
                //this.setState({ url });
              });
          }
        );
      });



    };




  getValori(x){
    console.log('gang'+x);

    const ApiTags= new API ({url:'/ristorante/'+x});
    var entity= {name:"ristorante"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.ristorante
      .getOne({id:x},ppp)
      .then(res=>{
        console.log('qui vloreee');
        console.log(res.data);
        newThis.setState({valori:res.data})
        console.log('gang');
        console.log(newThis.state.valori);
        console.log('suju');

        /*let arrayCateg = [];
        newThis.state.valori.categorie.forEach((item, i) => {
          arrayCateg.push(item._id)
        });*/


        newThis.setState({img:newThis.state.valori.img});
        newThis.setState({nome:newThis.state.valori.nome})
        newThis.setState({descrizione:newThis.state.valori.desc})
        newThis.setState({prezzo:newThis.state.valori.prezzoConsegna})
        newThis.setState({categorieSel:newThis.state.valori.categorie})
        newThis.setState({
          ristorantiSel:newThis.state.valori.rifRistor,
          active:newThis.state.valori.active,
          aperto:newThis.state.valori.open,
          objLocation:newThis.state.valori.indirizzoGeo,
          objSpediz:newThis.state.valori.areaLavoro,
        })
        newThis.setState({
          paypalClienid:newThis.state.valori.paypalClienid,
          whatupNumber:newThis.state.valori.whatupNumber,
          take_away:newThis.state.valori.take_away,
          take_away_time:newThis.state.valori.take_away_time,
          delivery:newThis.state.valori.delivery,
          delivery_time:newThis.state.valori.delivery_time,
          preordine:newThis.state.valori.preordine,
          preordine_time:newThis.state.valori.preordine_time,
          orari:newThis.state.valori.orari,
          payPal:newThis.state.valori.payPal,
          contanti_Alla_consegna:newThis.state.valori.contanti_Alla_consegna,
          typeFlow:newThis.state.valori.typeFlow,
          idTelegram:newThis.state.valori.idTelegram,
        })


        console.log('qui cosa succ');
        console.log(newThis.state.valori.orari);




      });
    })

  }




  getArtigiani(){
    //usa per il get
    const ApiTags= new API ({url:'/artigiano'});
    var entity= {name:"artigiano"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.artigiano
      .getAllParam({alias:newThis.state.name},ppp)
      .then(res=>{

        newThis.setState({listaArtigiani:res.data})
        console.log('brouos');
        console.log(newThis.state.listaArtigiani);
      });
    })


  }

  getStagione(){
    //usa per il get
    const ApiTags= new API ({url:'/stagione'});
    var entity= {name:"stagione"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.stagione
      .getAllParam({alias:newThis.state.name},ppp)
      .then(res=>{
        newThis.setState({listaStagione:res.data})
        console.log('brouos');
        console.log(newThis.state.listaStagione);
      });
    })
  }

  updateGalleryProdotto(array){


    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/prodotto'});
      var entity= {name:"prodotto"};

      Item.createEntity(entity);

      console.log('booooo');




      var obj = {
        id:newThis.props.location.state.id,
        gallery:array,



      }


      Item.endpoints.prodotto
      .update(obj,ppp)
      .then(res=>{

      //console.log(array);
      //alert('salvato')
    //newThis.setState({redirect:true})
      });
    })



  }


  postProdotto(){

    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/ristorante'});
      var entity= {name:"ristorante"};

      Item.createEntity(entity);


      var obj = {
        id:newThis.props.location.state.id,
        nome: newThis.state.nome,
        whatupNumber: newThis.state.whatupNumber,
        img: newThis.state.img,
        desc:newThis.state.descrizione,
        prezzoConsegna:newThis.state.prezzo,
        indirizzoCompleto:newThis.state.objLocation.place_name,
        indirizzoGeo:newThis.state.objLocation.geometry,
        areaLavoro:newThis.state.objSpediz.geometry,
        raggioSpedizione:newThis.state.radiusSped,
        open:newThis.state.aperto,
        active:newThis.state.active,
        take_away:newThis.state.take_away,
        take_away_time:newThis.state.take_away_time,
        delivery:newThis.state.delivery,
        delivery_time:newThis.state.delivery_time,
        preordine:newThis.state.preordine,
        preordine_time:newThis.state.preordine_time,
        payPal:newThis.state.payPal,
        contanti_Alla_consegna:newThis.state.contanti_Alla_consegna,
        orari:newThis.state.orari,
        typeFlow:newThis.state.typeFlow,
        idTelegram:newThis.state.idTelegram,
      }



      console.log('mostra');
      console.log(obj);
      Item.endpoints.ristorante
      .update(obj,ppp)
      .then(res=>{
        alert('salvato')
      //newThis.setState({redirect:true})
    });

    })



  }


  _renderSelect2(item){
    //console.log('scoreggia');
    //console.log(item);
    //console.log(handleClick);
    return(
      <MenuItem
        key={item._id}
        label={item.nome}
        text={item.nome}
        onClick={()=>this.setState({collezione:item})}

      />
    )
  }


  getPar(){


    const ApiTags= new API ({url:'/lavori'});
    var entity= {name:"lavori"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.lavori
      .getAllOk(ppp)
      .then(res=>{
        //  console.log(res.data);
        });
    })
  }


  componentDidMount() {

    console.log('eskerin');

    this.getMyOrganiz();
    this.getMyStores();
    this.getValori(this.props.location.state.id);


  }


  getXXXX(x){
    //usa per il get
    const ApiTags= new API ({url:'/categoriabyorg'});
    var entity= {name:"categoriabyorg"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.categoriabyorg
      .getOne({id:x},ppp)
      .then(res=>{

        newThis.setState({categorie:res.data})
        console.log('ciaooo');
        console.log(newThis.state.prodotto);
      });
    })
  }


  gestioneArtigiani(x){

    let {ristorantiSel} = this.state;
    let index = ristorantiSel.indexOf(x)
    if(index > -1){
      ristorantiSel.splice(index, 1);
    }else {
      ristorantiSel.push(x)
    }


    this.setState({ristorantiSel})

  }

  gestioneCategorie(x){

    let {categorieSel} = this.state;
    let index = categorieSel.indexOf(x)
    if(index > -1){
      categorieSel.splice(index, 1);
    }else {
      categorieSel.push(x)
    }


    this.setState({categorieSel})

  }



  checedRist(x){
    let {ristorantiSel} = this.state;


    let index = ristorantiSel.indexOf(x)
    if(index > -1){
      return true
    }else {
      return false
    }
  }

  checed(x){
    let {categorieSel} = this.state;


    let index = categorieSel.indexOf(x)
    if(index > -1){
      return true
    }else {
      return false
    }
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({gallery}) => ({
      gallery: arrayMove(gallery, oldIndex, newIndex),
    }));
  };


  valueOrario(day){
      let {orari} = this.state;
      let arrayTo = [];
      if(orari[day] == null ){
        return arrayTo = []
      }else {
        return arrayTo = [orari[day].M ?  moment(orari[day].M[0] ,'HH:mm' ) : null , orari[day].M ?  moment(orari[day].M[1] ,'HH:mm' ) : null  ]

      }



  }

  valueOrarioP(day){
      let {orari} = this.state;
      let arrayTo = [];
      if(orari[day] == null ){
        return arrayTo = []
      }else {
        return arrayTo = [orari[day].P ?  moment(orari[day].P[0] ,'HH:mm' ) : null , orari[day].P ?  moment(orari[day].P[1] ,'HH:mm' ) : null  ]

      }



  }

  addOrario(day,array,type){
    let {orari} = this.state;
    if(orari){
      if(orari[day]){
        orari[day][type] = array;
      }else {
        orari[day] = {};
        orari[day][type] = array;
      }
    }else {
     orari = {};
     orari[day] = {};
     orari[day][type] = array;
    }


    console.log(orari);
    this.setState(orari)
  }

render() {
    let {idTelegram,whatupNumber,take_away_time,delivery_time,settimana,typeFlow,payPal,contanti_Alla_consegna,preordine,delivery,take_away, aperto,active,img,categorie, ristoranti,gallery,redirect,clienti,nome,cod_prod,descrizione,prezzo,valori, trueVariante,continuativo,listax, listaArtigiani,listaStagione,artigiani} = this.state;
    if(redirect){
      return <Redirect to="/setting" />
    }else {
      return (
        <div style={{background:'#f9fafb', height:'100%',overflowY: 'auto',padding:10,width:'100%',paddingBottom:50}}>
        <div style={{maxWidth:900,marginLeft:'auto',marginRight:'auto',display:'flex',}}>
      <div style={{width:'70%'}}>
      <Link to={{
        pathname: '/prodotti'}
      }><p style={{marginTop:10,cursor:'pointer',color:'#6d6d6d',fontWeight:'600'}}>{"< Prodotti"}</p>
      </Link>
        <h1>{nome ? nome : '_'}</h1>

        <div>
        <div style={{marginBottom:30, padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <div style={{}}>
          <p>Nome</p>

          <input style={{width:'100%',marginBottom:20}} class="bp3-input .modifier" value={nome}onChange={(x)=>this.setState({nome:x.target.value})} modifier type="text" placeholder="nome" dir="auto" /> <br></br>

          {typeFlow == 'whatp' ? (
            <div>
              <p>Numero whatsapp per ricezione</p>
              <input style={{width:'100%',marginBottom:20}} class="bp3-input .modifier" value={whatupNumber}onChange={(x)=>this.setState({whatupNumber:x.target.value})} modifier type="text" placeholder="numero telefono esempio +393497271159" dir="auto" /> <br></br>
            </div>
          ) : null}
            <div>
              <p>telegramKonnector</p>
              <input style={{width:'100%',marginBottom:20}} class="bp3-input .modifier" value={idTelegram}onChange={(x)=>this.setState({idTelegram:x.target.value})} modifier type="text" placeholder="numero telefono esempio +393497271159" dir="auto" /> <br></br>
            </div>

            {this.state.objLocation ? (
              <div style={{marginBottom:50}}>
                <p>Indirizzo ristorante</p>
                <MapInput value={this.state.objLocation}  callback={(x)=>this.setState({objLocation:x})} />
              </div>
            ) : null}


          <p>Descrizione</p>
          <textarea style={{width:'100%',marginBottom:20,height: 100 }} class="bp3-input .modifier" value={descrizione}onChange={(x)=>this.setState({descrizione:x.target.value})} modifier placeholder="descrizione" dir="auto"></textarea><br></br>
        </div></div>
        <div style={{marginBottom:30,padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
        <div style={{overflow: 'scroll hidden',whiteSpace:'nowrap',}}>

          <div style={{marginBottom:50}}>
            <p>Orari attivita</p>
            <div>
              {settimana.map((giorno,i)=>{
                return(
                  <div key={i} style={{display:''}}>
                    <strong>{giorno}</strong>
                    <div style={{display:'flex',marginBottom: 20}}>
                      <RangePicker minuteStep={15} value={this.valueOrario(giorno)} onChange={(x,timeString)=>this.addOrario(giorno,timeString,'M')} format="HH:mm" />
                      <RangePicker  minuteStep={15} value={this.valueOrarioP(giorno)} onChange={(x,timeString)=>this.addOrario(giorno,timeString,'P')} format="HH:mm" />
                    </div>
                  </div>
                )
              })}

            </div>
          </div>

          <div style={{marginBottom:50}}>
            <p>Raggio di consegna</p>
            {this.state.objSpediz ? (
              <MapInput value={this.state.objSpediz} radius={true} callback={(x)=>this.setState({objSpediz:x})} />

            ) : null}
          </div>



        <SortableList axis='x' items={gallery} onSortEnd={this.onSortEnd} />
        {/*gallery.map((x,i)=>{
          return (
            <Img style={{width:150}} src={x} loader={<p>pollo</p>}/>)
        })*/}

        {img ? (
          <img style={{width:100}} src={img} />
        ) : null}

        <Dropzone multiple={false} onDrop={acceptedFiles => this.handleUpload(acceptedFiles)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div class='drag' {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Trascina immagine copertina ristorante</p>
              </div>
            </section>
          )}
        </Dropzone>
        </div>
        </div>
        <div style={{marginBottom:30,padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <h3 style={{marginBottom:10}}>Costo spedizione</h3>
          <input class="bp3-input .modifier" modifier type="text" value={prezzo} onChange={(x)=>this.setState({prezzo:x.target.value})} placeholder="prezzo" dir="auto" /> <br></br>
        </div>






          {/*<label class="bp3-control bp3-checkbox .modifier">
            <input type="checkbox" modifier checked={trueVariante} onChange={()=>this.setState({trueVariante:!trueVariante})} />
            <span class="bp3-control-indicator"></span>
            il prodotto ha delle varianti
          </label>

          {trueVariante ? (
            <div style={{marginBottom:'1500px'}}>
              <h1 style={{color:'red'}}>opzione</h1>
                <TagInput
                    onChange={(variante: string[]) => this.setState({ variante })}
                    values={this.state.variante}
                />
            </div>
          ) : null}*/}

        </div>

      </div>




      <div style={{width:'30%'}}>
        <div style={{marginTop:103,marginBottom:30, padding:30, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <div style={{paddingBottom:'10px'}}>


      <h1>Attivo</h1>
      <p style={{marginBottom:30}}>Seleziona se vuoi che il ristorante sia online</p>
        <label class="bp3-control bp3-checkbox .modifier">
          <input type="checkbox" modifier checked={active} onChange={()=>this.setState({active:!active})}  />
          <span class="bp3-control-indicator"></span>
          Attiva ristorante
        </label>
      <h1>Aperto</h1>
      <p style={{marginBottom:30}}>Seleziona se vuoi che il ristorante sia aperto e possa prendere ordinazioni</p>
        <label class="bp3-control bp3-checkbox .modifier">
          <input type="checkbox" modifier checked={aperto} onChange={()=>this.setState({aperto:!aperto})}  />
          <span class="bp3-control-indicator"></span>
          Attiva ristorante
        </label>
    </div>

   </div>

   <div style={{marginTop:10,marginBottom:30, padding:30, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
     <div style={{paddingBottom:'10px'}}>


 <h1>Servizi attivi</h1>
 <p style={{marginBottom:30}}>Seleziona i servizi che vuoi servire con questo ristorante</p>
   <label class="bp3-control bp3-checkbox .modifier">
     <input type="checkbox" modifier checked={take_away} onChange={()=>this.setState({take_away:!take_away})}  />
     <span class="bp3-control-indicator"></span>
     Asporto
   </label>
   {take_away ? (
     <div style={{marginBottom:20}}>
       <p>Orari in cui e attivo il take away</p>
       <RangePicker minuteStep={15} value={take_away_time && take_away_time.length > 1 ? [moment(take_away_time[0],'HH:mm'), moment(take_away_time[1],'HH:mm')] : null} onChange={(z,x)=>this.setState({take_away_time:x})} format="HH:mm" />
     </div>
   ) : null}
   <label class="bp3-control bp3-checkbox .modifier">
     <input type="checkbox" modifier checked={delivery} onChange={()=>this.setState({delivery:!delivery})}  />
     <span class="bp3-control-indicator"></span>
     Deliveri
   </label>
   {delivery ? (
     <div style={{marginBottom:20}}>
       <p>Orari in cui e attiva la delivery</p>
       <RangePicker minuteStep={15} value={delivery_time && delivery_time.length > 1 ? [moment(delivery_time[0],'HH:mm'), moment(delivery_time[1],'HH:mm')] : null} onChange={(z,x)=>this.setState({delivery_time:x})} format="HH:mm" />
     </div>
   ) : null}
   <label class="bp3-control bp3-checkbox .modifier">
     <input type="checkbox" modifier checked={preordine} onChange={()=>this.setState({preordine:!preordine})} />
     <span class="bp3-control-indicator"></span>
     Preordine
   </label>
</div>

</div>


<div style={{marginTop:10,marginBottom:30, padding:30, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
  <div style={{paddingBottom:'10px'}}>


<h1>Servizi pagamento</h1>
<p style={{marginBottom:30}}>Seleziona i servizi che vuoi servire con questo ristorante</p>
<label class="bp3-control bp3-checkbox .modifier">
  <input type="checkbox" modifier checked={contanti_Alla_consegna} onChange={()=>this.setState({contanti_Alla_consegna:!contanti_Alla_consegna})}  />
  <span class="bp3-control-indicator"></span>
  Pagamento alla consegna / ritiro
</label>
<label class="bp3-control bp3-checkbox .modifier">
  <input type="checkbox" modifier checked={payPal} onChange={()=>this.setState({payPal:!payPal})}  />
  <span class="bp3-control-indicator"></span>
  payPal
</label>
</div>

</div>
   <div style={{marginBottom:30, padding:30, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
   <div style={{marginBottom:''}}>
     <h3  style={{marginTop:0}}>Varianti</h3>
     <p>esempio <strong>whatp</strong> , <strong>normal</strong></p>
     <input type="text" modifier value={typeFlow} onChange={(x)=>this.setState({typeFlow:x.target.value})}  />


     {/*<TagInput sty
           onChange={(variante: string[]) => this.setState({ variante })}
           values={this.state.variante}
       />*/}
   </div>
   </div>
      </div>





        </div>
        <div style={{maxWidth:900,marginLeft:'auto',marginRight:'auto'}}>
        <button style={{float:'right',background:'#4a69bd',color:'white',fontWeight:'800',padding:10}} type="button" onClick={()=>this.postProdotto()}class="bp3-button .modifier" modifier>salva</button>
        </div>
        </div>



      )
    }

  }
}

const condition = authUser => {
console.log('mostro auth');
console.log(authUser);
if(authUser){
  if(authUser.roles){

    let indexStaff = authUser.roles.indexOf('STAFF_W')
    let indexAdmin = authUser.roles.indexOf('pollo')
    let okVai = false
    authUser.roles.forEach((item, i) => {
      console.log(item);
      if(item == 'ADMIN_S'){
        console.log('qui dovrei dare true');
        okVai = true
      }
    });

    console.log('qui dò un risultato');
    console.log(okVai);
    return okVai


  /*  if(indexStaff || indexAdmin > -1){
      return true;
    }else {
      return false
    }*/
  }else {
    return false
  }

}else {
  return false
}


}

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(NewProd);
