import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import {Link} from "react-router-dom";
import {decimal} from "../../util/functions.js"
import moment from 'moment';
import paypI from '../img/paypa.svg'
import {statusConvert} from  "../../util/functions.js"

class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
        item:null,
    };


  }






  getInfo(idCart){
    const ApiTags= new API ({url:'/cart'});
    var entity= {name:"cart"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.cart
      .getOne({id:idCart},ppp)
      .then(res=>{
          newThis.setState({item:res.data})
        });
    })
  }


  componentDidMount() {
    this.getInfo(this.props.match.params.id)

  }

  subTotal(piatti){
    let subTotal = 0;
		piatti.map((x,i)=>{
      if(x.qty && x.qty > 0){
        if(x.custom){
          subTotal = subTotal + (x.qty * x.total);
        }else {
          subTotal = subTotal + (x.qty * x.prezzo);
        }

      }

		})
		return  decimal(subTotal)+' €'
  }
  
  total10(piatti){
    let subTotal = 0;
		piatti.map((x,i)=>{
      if(x.qty && x.qty > 0){
        if(x.custom){
          subTotal = subTotal + (x.qty * x.total);
        }else {
          subTotal = subTotal + (x.qty * x.prezzo);
         
        }

      }

    })
    subTotal = subTotal - ((subTotal * 10) /100)
		return  decimal(subTotal)+' €'
	}

  pagamento(type){
    switch (type) {
      case 'pay_pal':
          return 'paypal'
        break;
      case 'consegna':
          return 'pagamento alla consegna'
        break;
      default:
          return 'boh'

    }
  }

  updateStatus(status){
    let {item} = this.state;
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/ordinacartstatus'});
      var entity= {name:"ordinacartstatus"};

      Item.createEntity(entity);

      var obj = {
        rifRistor:item.rifRistor,
        id:newThis.props.match.params.id,
        StatusOrdine:status,
      }


      Item.endpoints.ordinacartstatus
      .update(obj,ppp)
      .then(res=>{
        let {item} = newThis.state;
        let imn = item;
        imn.StatusOrdine = status;
        newThis.setState({item:imn})

        });

  }
  )}

  buttonSwitc(StatusOrdine){
    let {item} = this.state;
    switch (StatusOrdine) {
      case "new":
        return(
          <div>
            <button onClick={()=>this.updateStatus('APR')} style={{ width: '100%',fontSize: 19,marginTop: 39}} className='actionButton'>Accetta</button>
              <p style={{marginTop:10,fontWeight:200}}>fai sapere al tuo cliente quando inizi a preparare l'ordine</p>
          </div>
        )
      case "APR":
        return(
          <div>
            <button onClick={()=>this.updateStatus('PREP')} style={{ width: '100%',fontSize: 19,marginTop: 39}} className='actionButton'>Inizia preparazione</button>
              <p style={{marginTop:10,fontWeight:200}}>fai sapere al tuo cliente quando inizi a preparare l'ordine</p>
          </div>
        )
      case "PREP":
        return(
          <div>
            <button onClick={()=>this.updateStatus('SPED')} style={{ width: '100%',fontSize: 19,marginTop: 39}} className='actionButton'>{item && item.type != 'take_away' ? 'Consegna' : 'Pronto per il ritiro'}</button>
              <p style={{marginTop:10,fontWeight:200}}>fai sapere al tuo cliente che il corriere sta partendo</p>
          </div>
        )
        break;
      case "RIFIUTA":
        return(
          null
        )
        break;
      case "SPED":
        return(
          <div>
            <button onClick={()=>this.updateStatus('CHIUD')} style={{ width: '100%',fontSize: 19,marginTop: 39}} className='actionButton'>Ordine chiuso</button>
              <p style={{marginTop:10,fontWeight:200}}>fai sapere al tuo cliente che il corriere sta partendo</p>
          </div>
        )
        break;
      case "CHIUD":
        return(
          <div>

          </div>
        )
        break;
      default:
      return(
        <div>
          <button style={{ width: '100%',fontSize: 19,marginTop: 39}} className='actionButton'>Inizia </button>
            <p style={{marginTop:10,fontWeight:200}}>fai sapere al tuo cliente quando inizi a preparare l'ordine</p>
        </div>
      )
    }
  }

render() {
    let {item} = this.state;
    let dateString = item && item.order_made_time ? moment(Number(item.order_made_time)).format("DD-MM-YYYY HH:mm") : null;
    //let dateStringw = item && item.order_made_time ? moment(Number(item.timeToDelivery)).format("DD-MM-YYYY HH:mm") : null;
    return (
      <div style={{}}>
        {item ? (
          <div className='bigInfo'>
            <h1 style={{marginBottom:50,color: 'var(--color-gray)'}}>Ordine #{item._id}</h1>

            <p>{item.orario}</p>
            {item.type != 'take_away' ? (
              <div>
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M5 18h-1c-.552 0-1-.448-1-1v-2h12v-9h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.121-.728 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-4c0 1.656-1.344 3-3 3s-3-1.344-3-3zm3-1.2c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm10 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-10-8.8h-8v-2h8v-1h-5v-1c0-.552.448-1 1-1h9c.552 0 1 .448 1 1v10h-11v-3h-2v-2h7v-1zm9 0v3h4.715l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.42z"/></svg>
                <h2>Consegna a casa</h2>
            </div>
            ) :
          <div>
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M18 23.999l-12 .001-1.983-11.578.637-1.123 3.692 4.173 7.264.003 3.721-4.214.652 1.16-1.983 11.578zm-5.075-17l1.622-6.258c.12-.447.524-.741.965-.741.651 0 1.139.618.968 1.258l-1.485 5.741h.398l4.214-5.571c.317-.452.941-.562 1.393-.245.452.316.562.94.246 1.392l-3.412 4.424h3.166l-.007.036c.212.382 2.059 3.718 2.869 5.2.083.153.138.318.138.48 0 1.036-1.399 1.384-1.893.48-.61-1.115-1.806-3.279-2.451-4.446l-4.68 5.25h-5.963l-4.642-5.327c-.634 1.146-1.87 3.383-2.494 4.523-.495.907-1.877.551-1.877-.48 0-.162.04-.327.123-.48.828-1.514 2.737-4.962 2.88-5.22l-.003-.016h9.925zm1.075 4.5c0-.276-.224-.5-.5-.5h-3c-.276 0-.5.224-.5.5s.224.5.5.5h3c.276 0 .5-.224.5-.5"/></svg>
            <h2>Passa il cliente</h2>
          </div> }
          <div className='BoxInfo'>
            <div>
              <div style={{marginTop: 41,padding:20,background:'white',marginBottom:20}}>
                <div style={{display:'flex'}}><h2 style={{margin:0}}>Stato ordine </h2>
                <div className='newtag' style={{marginLeft:'auto'}}><h2 style={{color:'white',margin:0}} >{statusConvert(item.StatusOrdine)}</h2></div></div>
                {dateString ? <h2 style={{marginTop:20}}>Effettuato il: {dateString}</h2> : null}
                {this.buttonSwitc(item.StatusOrdine)}
                <div>
                  <button onClick={()=>this.updateStatus('RIFIUTA')} style={{ width: '100%',fontSize: 19,marginTop: 5,background:'#ff5555'}} className='actionButton'>Rifiuta</button>
                    <p style={{marginTop:10,fontWeight:200}}>se riufiuti fai sapere al tuo cliente il perchè, contattalo al suo numero.</p>
                </div>

                <div>
                    <p>{item.cliente ? item.cliente.nome : null }</p>
                    <a target='_blank' href={'https://api.whatsapp.com/send?phone='+item.cliente.telefono+'&text='}><button style={{ width: '100%',fontSize: 19,marginTop: 0,background:'#25d466'}} className='actionButton'>Inizia messaggio su whatsapp</button></a>


                </div>


              </div>
              <p style={{marginBottom:20,fontWeight: 600,fontSize:16}}>Scontrino</p>

            <div style={{padding:20,background:'white'}}>
              {item.items.map((piatto,i)=>{
                if(piatto.qty && piatto.qty > 0){
                  if(piatto.custom){
                    return(
                      <div className='piattoList' style={{display:'flex',fontSize:20}} key={i}>
                        <p>{piatto.qty}X</p>
                        <div>
                          <p style={{marginBottom:0}}>{piatto.nome}</p>
                          <p style={{marginTop:0}}>{piatto.customPart}</p>
                        </div>
                        <p style={{marginLeft:'auto'}}>{decimal(piatto.total * piatto.qty)} €</p>
                      </div>
                    )
                  }else {
                    return(
                      <div className='piattoList' style={{display:'flex',fontSize:20}} key={i}>
                        <p>{piatto.qty}X</p>
                        <p>{piatto.nome}</p>
                        <p style={{marginLeft:'auto'}}>{decimal(piatto.prezzo * piatto.qty)} €</p>
                      </div>
                    )
                  }

                }else {
                  return null
                }

              })}
              <div style={{display:'flex',fontSize:20,fontWeight:900,marginTop:30}}><p>subTotale</p><p style={{marginLeft:'auto',marginRight:30,textDecoration: 'line-through'}}>{this.subTotal(item.items)}</p></div>
              <p>-10% sconto</p>
              <div style={{display:'flex',fontSize:20,fontWeight:900,marginTop:30}}><p>Totale</p><p style={{marginLeft:'auto',marginRight:30}}>{this.total10(item.items)}</p></div>
            </div>

          </div>
            <div>
              <p style={{marginBottom:20,fontWeight: 600,fontSize:16}}>Cliente</p>

              <div style={{ padding:20,background:'white'}}>
                <div>
                    <p>{item.cliente ? item.cliente.nome : null }</p>
                    <p>{item.cliente ? item.cliente.telefono : null } <a target='_blank' href={'https://api.whatsapp.com/send?phone='+item.cliente.telefono+'&text= prova per ordine'}><span  style={{cursor:'pointer',fill:'#25d466'}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.031 6.172c-3.181 0-5.767 2.586-5.768 5.766-.001 1.298.38 2.27 1.019 3.287l-.582 2.128 2.182-.573c.978.58 1.911.928 3.145.929 3.178 0 5.767-2.587 5.768-5.766.001-3.187-2.575-5.77-5.764-5.771zm3.392 8.244c-.144.405-.837.774-1.17.824-.299.045-.677.063-1.092-.069-.252-.08-.575-.187-.988-.365-1.739-.751-2.874-2.502-2.961-2.617-.087-.116-.708-.94-.708-1.793s.448-1.273.607-1.446c.159-.173.346-.217.462-.217l.332.006c.106.005.249-.04.39.298.144.347.491 1.2.534 1.287.043.087.072.188.014.304-.058.116-.087.188-.173.289l-.26.304c-.087.086-.177.18-.076.354.101.174.449.741.964 1.201.662.591 1.221.774 1.394.86s.274.072.376-.043c.101-.116.433-.506.549-.68.116-.173.231-.145.39-.087s1.011.477 1.184.564.289.13.332.202c.045.072.045.419-.1.824zm-3.423-14.416c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.029 18.88c-1.161 0-2.305-.292-3.318-.844l-3.677.964.984-3.595c-.607-1.052-.927-2.246-.926-3.468.001-3.825 3.113-6.937 6.937-6.937 1.856.001 3.598.723 4.907 2.034 1.31 1.311 2.031 3.054 2.03 4.908-.001 3.825-3.113 6.938-6.937 6.938z"/></svg></span></a> </p>
                    <p>{item.cliente ? item.cliente.email : null }</p>
                </div>
              </div>
              <p style={{marginTop:20,marginBottom:20,fontWeight: 600,fontSize:16}}>Spedizione</p>

              <div style={{ padding:20,background:'white'}}>
                <div>
                  {item.type != 'take_away' ? <p>{item.indirizzoCompleto}</p> : <p>Ordine take_away</p> }


                </div>
              </div>
              <p style={{marginTop:40,marginBottom:20,fontWeight: 600,fontSize:16}}>Pagamento</p>
              <div style={{ padding:20,background:'white'}}>

                    <p>{this.pagamento(item.paymanType)}</p>
                      {item.paymanType == 'pay_pal' ? <img style={{fill:'#00457C'}} src={paypI} /> : null }
                    {item.paymanType == 'pay_pal' ? <p>orderID: {item.dataPaypal.orderID}</p> : null}
                    {item.paymanType == 'pay_pal' ?(

                      <div>

                        <p>Dettagli pagamento</p>
                        <p>status: {item.detailsPaypal.status}</p>
                        <p>importo: {item.detailsPaypal.purchase_units[0].amount.value} Euro</p>
                      </div>
                    ):null}
                </div>
            </div>
          </div>

          </div>
        ) : null}


      </div>



    )
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(HomePage);
