import React from 'react';
import { Button, ButtonGroup } from "@blueprintjs/core"
import { Icon } from "@blueprintjs/core";
import { FaHome, FaDraftingCompass } from "react-icons/fa";
import { TiHomeOutline } from "react-icons/ti";
import { IExampleProps } from "@blueprintjs/docs-theme";
import {Link} from "react-router-dom";
import HomeIcon from  "../../images/tent.svg"

class Menu extends React.PureComponent<IExampleProps, IButtonGroupExampleState> {

  constructor(props) {
    super(props);
    this.state = {};
  }




  render() {
    return (
      <div style={{marginTop:50}}>

      </div>
    );
  }
}


export default Menu;
