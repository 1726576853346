import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import Dropzone from 'react-dropzone'
import firebase from 'firebase';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import Img from 'react-image'
import {Link , Redirect} from "react-router-dom";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import MapInput from '../MapInput';

import {
    Button,
    H5,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Position,
    Spinner,
    Switch,
    Tag,
    Tooltip,
    TagInput
} from "@blueprintjs/core";
import { Select , ItemRenderer } from "@blueprintjs/select";
import { BrowserRouter as Router, Route } from "react-router-dom";
import 'antd/dist/antd.css';
import { TimePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = TimePicker;

const SortableItem = SortableElement(({value, sortIndex}) => (
  <div style={{width:150}}>
      <Img style={{width:150}} src={value} loader={<p>pollo</p>}/>
  </div>
));

const SortableList = SortableContainer(({items}) => {
return (
  <div style={{overflow: 'scroll hidden',whiteSpace:'nowrap',display:'flex'}}>
    {items.map((value, index) => (
      <SortableItem key={`item-${value}`} index={index} value={value} />
    ))}
  </div>
);
});


function isNow(start,end){
  console.log('mostro nowwww');
  console.log('vediamo start');
  let ccc = moment().format('HH:mm')
  let startN = start;
  let endN=end;
  console.log('formattt');
  console.log(ccc);
  console.log(startN);
  console.log(endN);
  let value = moment(moment(ccc, 'HH:mm')).isBetween(moment(startN, 'HH:mm'), moment(endN, 'HH:mm'));
  console.log('risultato');
  console.log(value);
  return value

}

function getTimeStops(time,block){
  var startTime = moment(time[0], 'HH:mm');
  var bisTime = moment(time[0], 'HH:mm');
  var endTime = moment(time[1], 'HH:mm');

  if( endTime.isBefore(startTime) ){
    endTime.add(1, 'day');
  }

  var timeStops = [];

  while(startTime <= endTime){
    let itemToUserFor =  bisTime.add(30, 'minutes')
    let toTiem = new moment(startTime).format('HH:mm') + '-'+new moment(itemToUserFor).format('HH:mm')
    let id = toTiem.replace(/:/g, '');
    id = id.replace(/-/g, '');
    let active = block[id];

    timeStops.push({
      string:toTiem,
      id:id,
      disabled:active,
      now:isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm')),
    });
    startTime.add(30, 'minutes');
  }
  console.log('timeStops');
  console.log(timeStops);
  return timeStops;
}



class NewProd extends Component {

  constructor(props) {
    super(props);
    //this.getXXXX()
    this.state = {
      settOra:false,
      whatupNumber:null,
      settimana:['lunedì','martedì','mercoledì','giovedì','venerdì','sabato','domenica'],
      typeFlow:null,
      paypalClienid:null,
      take_away:false,
      take_away_time:null,
      delivery:false,
      delivery_time:[],
      preordine:false,
      preordine_time:null,
      orari:{},
      orari_takeA:{},
      orari_daily_p:{},
      payPal:false,
      contanti_Alla_consegna:false,
      aperto:false,
      active:false,
      ristoranti:[],
      ristorantiSel:[],
      categorie:[],
      categorieSel:[],
      objLocation:null,
      objSpediz:null,
      radiusSped:1000,
      img:null,
      rifOrganiz:null,
      clienti:[],
      nome:null,
      cod_prod:null,
      descrizione:null,
      prezzo:0,
      variante:[],
      trueVariante:false,
      continuativo:false,
      listax:[{id:'pppp',alias:'pollo'},{id:'yo',alias:'yo'},{id:'suc',alias:'suc'}],
      listaArtigiani:[],
      listaStagione:[],
      gallery:[],
      artigiani:{},
      provaArt:{},
      collezione:null,
      redirect:false,
      valori:null,
      progress:null,
      daily_p:false,
      block_takeA:{},
      block_delivery:{},
      block_daily_p:{},
    };


  }

  getMyOrganiz(x){
    const ApiTags= new API ({url:'/organizazioneadmin/'+x});
    var entity= {name:"organizazioneadmin"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.organizazioneadmin
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati organ');
        console.log(res.data._id);
        newThis.setState({rifOrganiz:res.data._id})
        newThis.getXXXX(res.data._id)
      });
    })

  }


  getMyStores(x){
    const ApiTags= new API ({url:'/ristorantiadmins/'+x});
    var entity= {name:"ristorantiadmins"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.ristorantiadmins
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati');
        console.log(res.data);
        newThis.setState({ristoranti:res.data})

      });
    })

  }

  addImg(xxx){


    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/prodotto'});
      var entity= {name:"prodotto"};

      Item.createEntity(entity);


      var obj = {
        id:newThis.props.location.state.id,
        gallery:[xxx]


      }

      Item.endpoints.prodotto
      .update(obj,ppp)
      .then(res=>{

      });
    })



  }

  handleUpload = (images) => {
      let img = this.state.img;
      var storage = firebase.storage();
      let newThis = this;
      //const image = images[0];
      images.forEach((image, i) => {
        const uploadTask = storage.ref(`images/${image.name}`).put(image);
        uploadTask.on(
          "state_changed",
          snapshot => {
            // progress function ...
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            this.setState({ progress });
          },
          error => {
            // Error function ...
            console.log(error);
          },
          () => {
            // complete function ...
            storage
              .ref("images")
              .child(image.name)
              .getDownloadURL()
              .then(url => {
                console.log('qui finitoooooo');
                console.log(url);
                img = url;

                newThis.setState({img})
                //this.setState({ url });
              });
          }
        );
      });



    };




  getValori(x){
    console.log('gang'+x);

    const ApiTags= new API ({url:'/ristorante/'+x});
    var entity= {name:"ristorante"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.ristorante
      .getOne({id:x},ppp)
      .then(res=>{
        console.log('qui vloreee');
        console.log(res.data);
        newThis.setState({valori:res.data})
        console.log('gang');
        console.log(newThis.state.valori);
        console.log('suju');

        /*let arrayCateg = [];
        newThis.state.valori.categorie.forEach((item, i) => {
          arrayCateg.push(item._id)
        });*/


        newThis.setState({img:newThis.state.valori.img});
        newThis.setState({nome:newThis.state.valori.nome})
        newThis.setState({descrizione:newThis.state.valori.desc})
        newThis.setState({prezzo:newThis.state.valori.prezzoConsegna})
        newThis.setState({categorieSel:newThis.state.valori.categorie})
        newThis.setState({
          ristorantiSel:newThis.state.valori.rifRistor,
          active:newThis.state.valori.active,
          aperto:newThis.state.valori.open,
          objLocation:newThis.state.valori.indirizzoGeo,
          objSpediz:newThis.state.valori.areaLavoro,
        })
        newThis.setState({
          paypalClienid:newThis.state.valori.paypalClienid,
          whatupNumber:newThis.state.valori.whatupNumber,
          take_away:newThis.state.valori.take_away,
          take_away_time:newThis.state.valori.take_away_time,
          delivery:newThis.state.valori.delivery,
          delivery_time:newThis.state.valori.delivery_time,
          preordine:newThis.state.valori.preordine,
          preordine_time:newThis.state.valori.preordine_time,
          orari:newThis.state.valori.orari ? newThis.state.valori.orari : {},
          orari_takeA:newThis.state.valori.orari_takeA ? newThis.state.valori.orari_takeA : {},
          orari_daily_p:newThis.state.valori.orari_daily_p ? newThis.state.valori.orari_daily_p : {},
          payPal:newThis.state.valori.payPal,
          contanti_Alla_consegna:newThis.state.valori.contanti_Alla_consegna,
          typeFlow:newThis.state.valori.typeFlow,
          daily_p:newThis.state.valori.daily_p,
          block_takeA:newThis.state.valori.block_takeA ? newThis.state.valori.block_takeA : {},
          block_delivery:newThis.state.valori.block_delivery ? newThis.state.valori.block_delivery : {},
          block_daily_p:newThis.state.valori.block_daily_p ? newThis.state.valori.block_daily_p : {},

        })


        console.log('qui cosa succ');
        console.log(newThis.state.valori.orari);




      });
    })

  }




  getArtigiani(){
    //usa per il get
    const ApiTags= new API ({url:'/artigiano'});
    var entity= {name:"artigiano"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.artigiano
      .getAllParam({alias:newThis.state.name},ppp)
      .then(res=>{

        newThis.setState({listaArtigiani:res.data})
        console.log('brouos');
        console.log(newThis.state.listaArtigiani);
      });
    })


  }

  getStagione(){
    //usa per il get
    const ApiTags= new API ({url:'/stagione'});
    var entity= {name:"stagione"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.stagione
      .getAllParam({alias:newThis.state.name},ppp)
      .then(res=>{
        newThis.setState({listaStagione:res.data})
        console.log('brouos');
        console.log(newThis.state.listaStagione);
      });
    })
  }

  updateGalleryProdotto(array){


    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/prodotto'});
      var entity= {name:"prodotto"};

      Item.createEntity(entity);

      console.log('booooo');




      var obj = {
        id:newThis.props.location.state.id,
        gallery:array,



      }


      Item.endpoints.prodotto
      .update(obj,ppp)
      .then(res=>{

      //console.log(array);
      //alert('salvato')
    //newThis.setState({redirect:true})
      });
    })



  }


  postProdotto(){

    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/ristorante'});
      var entity= {name:"ristorante"};

      Item.createEntity(entity);


      var obj = {
        id:newThis.props.match.params.id,/*newThis.props.location.state.id,*/
        nome: newThis.state.nome,
        whatupNumber: newThis.state.whatupNumber,
        img: newThis.state.img,
        desc:newThis.state.descrizione,
        prezzoConsegna:newThis.state.prezzo,
        indirizzoCompleto:newThis.state.objLocation.place_name,
        indirizzoGeo:newThis.state.objLocation.geometry,
        areaLavoro:newThis.state.objSpediz.geometry,
        raggioSpedizione:newThis.state.radiusSped,
        open:newThis.state.aperto,
        active:newThis.state.active,
        take_away:newThis.state.take_away,
        take_away_time:newThis.state.take_away_time,
        delivery:newThis.state.delivery,
        delivery_time:newThis.state.delivery_time,
        preordine:newThis.state.preordine,
        preordine_time:newThis.state.preordine_time,
        payPal:newThis.state.payPal,
        contanti_Alla_consegna:newThis.state.contanti_Alla_consegna,
        orari:newThis.state.orari,
        daily_p:newThis.state.daily_p,
        block_daily_p:newThis.state.block_daily_p,
        block_delivery:newThis.state.block_delivery,
        block_takeA:newThis.state.block_takeA
      }



      console.log('mostra');
      console.log(obj);
      Item.endpoints.ristorante
      .update(obj,ppp)
      .then(res=>{
        alert('salvato')
      //newThis.setState({redirect:true})
    });

    })



  }



  slotModify(id,type){

    let {block_daily_p,block_takeA,block_delivery} = this.state;
    switch (type) {
      case 'prenot':
        block_daily_p[id] = !block_daily_p[id];
        this.setState({block_daily_p});
        break;
      case 'delivery':
        block_delivery[id] = !block_delivery[id];
        this.setState({block_delivery});
        break;
      default:
        block_takeA[id] = !block_takeA[id];
        this.setState({block_takeA});

    }

  }


  _renderSelect2(item){
    //console.log('scoreggia');
    //console.log(item);
    //console.log(handleClick);
    return(
      <MenuItem
        key={item._id}
        label={item.nome}
        text={item.nome}
        onClick={()=>this.setState({collezione:item})}

      />
    )
  }


  getPar(){


    const ApiTags= new API ({url:'/lavori'});
    var entity= {name:"lavori"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.lavori
      .getAllOk(ppp)
      .then(res=>{
        //  console.log(res.data);
        });
    })
  }


  componentDidMount() {

    console.log('eskerin');

    this.getMyOrganiz();
    this.getMyStores();
    this.getValori(this.props.match.params.id);


  }


  getXXXX(x){
    //usa per il get
    const ApiTags= new API ({url:'/categoriabyorg'});
    var entity= {name:"categoriabyorg"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.categoriabyorg
      .getOne({id:x},ppp)
      .then(res=>{

        newThis.setState({categorie:res.data})
        console.log('ciaooo');
        console.log(newThis.state.prodotto);
      });
    })
  }


  gestioneArtigiani(x){

    let {ristorantiSel} = this.state;
    let index = ristorantiSel.indexOf(x)
    if(index > -1){
      ristorantiSel.splice(index, 1);
    }else {
      ristorantiSel.push(x)
    }


    this.setState({ristorantiSel})

  }

  gestioneCategorie(x){

    let {categorieSel} = this.state;
    let index = categorieSel.indexOf(x)
    if(index > -1){
      categorieSel.splice(index, 1);
    }else {
      categorieSel.push(x)
    }


    this.setState({categorieSel})

  }



  checedRist(x){
    let {ristorantiSel} = this.state;


    let index = ristorantiSel.indexOf(x)
    if(index > -1){
      return true
    }else {
      return false
    }
  }

  checed(x){
    let {categorieSel} = this.state;


    let index = categorieSel.indexOf(x)
    if(index > -1){
      return true
    }else {
      return false
    }
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({gallery}) => ({
      gallery: arrayMove(gallery, oldIndex, newIndex),
    }));
  };


  valueOrario(day){
      let {orari} = this.state;
      let arrayTo = [];
      if(orari[day] == null ){
        return arrayTo = []
      }else {
        return arrayTo = [orari[day].M ?  moment(orari[day].M[0] ,'HH:mm' ) : null , orari[day].M ?  moment(orari[day].M[1] ,'HH:mm' ) : null  ]

      }



  }

  valueOrarioP(day){
      let {orari} = this.state;
      let arrayTo = [];
      if(orari[day] == null ){
        return arrayTo = []
      }else {
        return arrayTo = [orari[day].P ?  moment(orari[day].P[0] ,'HH:mm' ) : null , orari[day].P ?  moment(orari[day].P[1] ,'HH:mm' ) : null  ]

      }



  }

  addOrario(day,array,type){
    let {orari} = this.state;
    if(orari){
      if(orari[day]){
        orari[day][type] = array;
      }else {
        orari[day] = {};
        orari[day][type] = array;
      }
    }else {
     orari = {};
     orari[day] = {};
     orari[day][type] = array;
    }


    console.log(orari);
    this.setState(orari)
  }

  todayIsDaily(p){
    let {orari_daily_p,block_daily_p} = this.state;
    var weekday = new Array(7);
    weekday[0] = "domenica";
    weekday[1] = "lunedì";
    weekday[2] = "martedì";
    weekday[3] = "mercoledì";
    weekday[4] = "giovedì";
    weekday[5] = "venerdì";
    weekday[6] = "sabato";
    let today = new Date()
    console.log('oggi è:');
    let numeberDay = weekday[today.getDay()];

    let orariDays = orari_daily_p[numeberDay];



    let Type = 'M';
    if(p == true){
      Type = 'P';
    }

    if(orariDays && orariDays[Type]){
      return (
        <div>
          {getTimeStops(orariDays[Type],block_daily_p).map((time,i)=>{
            return (<div className={time.now ? 'nowTime' : 'notNow'} onClick={()=>this.slotModify(time.id,'prenot')} style={{background:time.disabled ? '#eee' : null, padding:10,marginBottom:10,display:'inline-block',marginRight:10,cursor:'pointer',color:'black'}} key={time.id}>
                      {time.string}
                    </div>)
          })}
        </div>
      )
    }else {
      return null
    }

  }

  todayIsAsp(p){
    let {orari_takeA,block_takeA} = this.state;
    var weekday = new Array(7);
    weekday[0] = "domenica";
    weekday[1] = "lunedì";
    weekday[2] = "martedì";
    weekday[3] = "mercoledì";
    weekday[4] = "giovedì";
    weekday[5] = "venerdì";
    weekday[6] = "sabato";
    let today = new Date()
    console.log('oggi è:');
    let numeberDay = weekday[today.getDay()];

    let orariDays = orari_takeA[numeberDay];



    let Type = 'M';
    if(p == true){
      Type = 'P';
    }

    if(orariDays && orariDays[Type]){
      return (
        <div>
          {getTimeStops(orariDays[Type],block_takeA).map((time,i)=>{
            return (<div className={time.now ? 'nowTime' : 'notNow'} onClick={()=>this.slotModify(time.id)} style={{background:time.disabled ? '#eee' : null,padding:10,marginBottom:10,display:'inline-block',marginRight:10,cursor:'pointer',color:'black'}} key={time.id}>
                      {time.string}
                    </div>)
          })}
        </div>
      )
    }else {
      return null
    }

  }


  todayIs(p){
    let {orari,block_delivery} = this.state;
    var weekday = new Array(7);
    weekday[0] = "domenica";
    weekday[1] = "lunedì";
    weekday[2] = "martedì";
    weekday[3] = "mercoledì";
    weekday[4] = "giovedì";
    weekday[5] = "venerdì";
    weekday[6] = "sabato";
    let today = new Date()
    console.log('oggi è:');
    let numeberDay = weekday[today.getDay()];

    let orariDays = orari[numeberDay];
    console.log(orariDays);


    let Type = 'M';
    if(p == true){
      Type = 'P';
    }

    if(orariDays && orariDays[Type]){
      return (
        <div>
          {getTimeStops(orariDays[Type],block_delivery).map((time,i)=>{
            return (<div className={time.now ? 'nowTime' : 'notNow'} onClick={()=>this.slotModify(time.id,'delivery')} style={{background:time.disabled ? '#eee' : null,padding:10,marginBottom:10,display:'inline-block',marginRight:10,cursor:'pointer',color:'black'}} key={time.id}>
                      {time.string}
                    </div>)
          })}
        </div>
      )
    }else {
      return null
    }

  }


render() {
    let {daily_p,settOra,whatupNumber,take_away_time,delivery_time,settimana,typeFlow,payPal,contanti_Alla_consegna,preordine,delivery,take_away, aperto,active,img,categorie, ristoranti,gallery,redirect,clienti,nome,cod_prod,descrizione,prezzo,valori, trueVariante,continuativo,listax, listaArtigiani,listaStagione,artigiani} = this.state;
    if(redirect){
      return <Redirect to="/setting" />
    }else {
      return (
        <div style={{background:'#f9fafb', height:'100%',overflowY: 'auto',padding:10,width:'100%',paddingBottom:50}}>
        <div className='flex' style={{maxWidth:900,marginLeft:'auto',marginRight:'auto'}}>
      <div className='x70c'>
      <Link to={{
        pathname: '/'}
      }><p style={{marginTop:10,cursor:'pointer',color:'#6d6d6d',fontWeight:'600'}}>{"< Home"}</p>
      </Link>
        <h1>{nome ? nome : '_'}</h1>

        <div>



        <div style={{marginBottom:30,padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <h1>Slot servizi</h1>

          <p style={{marginBottom:30}}>Qui puoi decidere di spegnere o riattivare uno slot orario se sei troppo impegnata e non riesci a garantire il servizio</p>
            <label class="bp3-control bp3-checkbox .modifier">
              <input type="checkbox" modifier checked={take_away} onChange={()=>this.setState({take_away:!take_away})}  />
              <span class="bp3-control-indicator"></span>
              Asporto
            </label>
            {take_away ? (
              <div style={{marginBottom:20}}>
                  <p>mattino</p>
                  <div>{this.todayIsAsp()}</div>
                  <p>sera</p>
                  <div style={{}}>{this.todayIsAsp(true)}</div>
              </div>
            ) : null}

            <label class="bp3-control bp3-checkbox .modifier">
              <input type="checkbox" modifier checked={delivery} onChange={()=>this.setState({delivery:!delivery})}  />
              <span class="bp3-control-indicator"></span>
              Deliveri
            </label>
            {delivery ? (
              <div style={{marginBottom:20}}>
                <p>Orari in cui e attiva la delivery</p>
                  <div>{this.todayIs()}</div>
                  <div style={{}}>{this.todayIs(true)}</div>
              </div>
            ) : null}
            <label class="bp3-control bp3-checkbox .modifier">
              <input type="checkbox" modifier checked={daily_p} onChange={()=>this.setState({daily_p:!daily_p})} />
              <span class="bp3-control-indicator"></span>
              Daily prenotazione
            </label>
            {daily_p ? (
              <div style={{marginBottom:20}}>
                <p>Orari in cui e attiva la prenotazione</p>
                  <p>mattina</p>
                  <div>{this.todayIsDaily()}</div>
                  <p>sera</p>
                  <div style={{}}>{this.todayIsDaily(true)}</div>
              </div>
            ) : null}

        <div style={{overflow: 'scroll hidden',whiteSpace:'nowrap',}}>







        <SortableList axis='x' items={gallery} onSortEnd={this.onSortEnd} />

        </div>
        </div>
        <div style={{marginBottom:30,padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>

        </div>






          {/*<label class="bp3-control bp3-checkbox .modifier">
            <input type="checkbox" modifier checked={trueVariante} onChange={()=>this.setState({trueVariante:!trueVariante})} />
            <span class="bp3-control-indicator"></span>
            il prodotto ha delle varianti
          </label>

          {trueVariante ? (
            <div style={{marginBottom:'1500px'}}>
              <h1 style={{color:'red'}}>opzione</h1>
                <TagInput
                    onChange={(variante: string[]) => this.setState({ variante })}
                    values={this.state.variante}
                />
            </div>
          ) : null}*/}

        </div>

      </div>







        </div>
        <div style={{maxWidth:900,marginLeft:'auto',marginRight:'auto'}}>
        <button style={{float:'right',background:'#4a69bd',color:'white',fontWeight:'800',padding:10}} type="button" onClick={()=>this.postProdotto()}class="bp3-button .modifier" modifier>salva</button>
        </div>
        </div>



      )
    }

  }
}

const condition = authUser => {
console.log('mostro auth');
console.log(authUser);
if(authUser){
  if(authUser.roles){

    let indexStaff = authUser.roles.indexOf('STAFF_W')
    let indexAdmin = authUser.roles.indexOf('pollo')
    let okVai = false
    authUser.roles.forEach((item, i) => {
      console.log(item);
      if(item == 'STAFF_W'){
        console.log('qui dovrei dare true');
        okVai = true
      }
    });

    console.log('qui dò un risultato');
    console.log(okVai);
    return okVai


  /*  if(indexStaff || indexAdmin > -1){
      return true;
    }else {
      return false
    }*/
  }else {
    return false
  }

}else {
  return false
}


}

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(NewProd);
