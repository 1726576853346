import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import { Spinner } from "@blueprintjs/core";
import API from "../../util/Api.js"
import {Link} from "react-router-dom";
import Stripe from './stripe.png';



class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
        loading:true,
        items:[],
    };


  }





  getMyStores(x){
    const ApiTags= new API ({url:'/ristorantiadmins/'+x});
    var entity= {name:"ristorantiadmins"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      console.log('mostro token')
      console.log(obj.idToken)
      var ppp = {'FIREBASE_AUTH_TOKEN' : obj.idToken};
      ApiTags.endpoints.ristorantiadmins
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati');
        console.log(res.data);
        newThis.setState({items:res.data,loading:false})

      });
    })

  }

  componentDidMount() {
    console.log('procccccc')
    this.getMyStores()

  }

  loading(){
    let {loading} = this.state;
    if(loading){
      return 'bp3-skeleton';
    }else {
      return null
    }
  }

render() {
    let {items} = this.state;
    return (
        <div style={{background:'#f9fafb', height:'100%',overflowY: 'auto',padding:10,width:'100%',paddingBottom:50}}>
          <div style={{marginTop:50, maxWidth:1050,marginLeft:'auto',marginRight:'auto'}}>
              <h1 style={{marginBottom:100}}>Settaggi</h1>
                <div className='gridWEb'>
                  <div>
                    <h2>Ristorante</h2>
                    <p>qui puoi gestire i settaggi del tuo ristorante</p>
                  </div>
                  <div>
                    {items.map((x,i)=>{
                      return(
                        <Link to={{
                           pathname: '/ristorantesetting', state: {
                           id: x._id}}
                         }>
                        <div style={{background:'white',padding:20,borderRadius:8,maxWidth:500}} key={i}>
                          <div style={{display:'flex'}}>
                            <div style={{width:100}}>
                              <img style={{width:'100%'}} src={x.img} />
                            </div>
                            <div style={{paddingLeft: 15, color: 'black'}}>
                              <strong>{x.nome}</strong>
                              <p>{x.indirizzoCompleto}</p>
                            </div>
                          </div>
                        </div>
                        </Link>
                      )
                    })}
                  </div>
                </div>


                <div className='gridWEb'>
                  <div>
                    <h2>Orari/ tipo di sevizio </h2>
                    <p>qui gestisci gli orari di delivery o asporto e puoi accendere o scpegnere i vari sevizi</p>
                  </div>
                  <div>
                    {items.map((x,i)=>{
                      return(
                        <Link to={{
                           pathname: '/orarisetting', state: {
                           id: x._id}}
                         }>
                        <div style={{padding:20,borderRadius:8,maxWidth:500}} key={i}>
                          <div style={{display:'flex'}}>
                            <div style={{}}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 14h-7v-8h2v6h5v2z"/></svg>
                            </div>
                            <div style={{paddingLeft: 15, color: 'black'}}>
                              <strong>Orari</strong>
                            </div>
                          </div>
                        </div>
                        </Link>
                      )
                    })}
                  </div>
                </div>


                <div className='gridWEb'>
                  <div>
                    <h2>Metodi di pagamento accettati</h2>
                    <p>qui puoi trovare e attivare i metodi di pagamento accettati sul tuo sistema</p>
                  </div>
                  <div>
                    {items.map((x,i)=>{
                      return(
                        
                        <div style={{padding:20,borderRadius:8,maxWidth:500}} key={i}>
                          <div style={{display:'flex'}}>
                            <div style={{}}>
                              <img style={{height:70}} src={Stripe}  alt='striep'/>
                              <br></br>
                              <a href='https://connect.stripe.com/oauth/authorize?client_id=ca_HD50XQdRo0LjUyDZV00SEiTD0Vlrzhg8&state={STATE_VALUE}&scope=read_write&response_type=code'>prova</a>
                              {/*items[0].stripe ? 'yes' : 'no'*/}
                            </div>
                            
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>


                {/*<div className='gridWEb'>
                  <div>
                    <h2>Metodo di pagamento </h2>
                    <p>qui puo gestire i metodi di pagamento per i vari servizi</p>
                  </div>
                  <div>
                    {items.map((x,i)=>{
                      return(
                        <Link to={{
                           pathname: '/orarisetting', state: {
                           id: x._id}}
                         }>
                        <div style={{padding:20,borderRadius:8,maxWidth:500}} key={i}>
                          <div style={{display:'flex'}}>
                            <div style={{}}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.164 7.165c-1.15.191-1.702 1.233-1.231 2.328.498 1.155 1.921 1.895 3.094 1.603 1.039-.257 1.519-1.252 1.069-2.295-.471-1.095-1.784-1.827-2.932-1.636zm1.484 2.998l.104.229-.219.045-.097-.219c-.226.041-.482.035-.719-.027l-.065-.387c.195.03.438.058.623.02l.125-.041c.221-.109.152-.387-.176-.453-.245-.054-.893-.014-1.135-.552-.136-.304-.035-.621.356-.766l-.108-.239.217-.045.104.229c.159-.026.345-.036.563-.017l.087.383c-.17-.021-.353-.041-.512-.008l-.06.016c-.309.082-.21.375.064.446.453.105.994.139 1.208.612.173.385-.028.648-.36.774zm10.312 1.057l-3.766-8.22c-6.178 4.004-13.007-.318-17.951 4.454l3.765 8.22c5.298-4.492 12.519-.238 17.952-4.454zm-2.803-1.852c-.375.521-.653 1.117-.819 1.741-3.593 1.094-7.891-.201-12.018 1.241-.667-.354-1.503-.576-2.189-.556l-1.135-2.487c.432-.525.772-1.325.918-2.094 3.399-1.226 7.652.155 12.198-1.401.521.346 1.13.597 1.73.721l1.315 2.835zm2.843 5.642c-6.857 3.941-12.399-1.424-19.5 5.99l-4.5-9.97 1.402-1.463 3.807 8.406-.002.007c7.445-5.595 11.195-1.176 18.109-4.563.294.648.565 1.332.684 1.593z"/></svg>
                            </div>
                            <div style={{paddingLeft: 15, color: 'black'}}>
                              <strong>Metodi di pagamento</strong>
                            </div>
                          </div>
                        </div>
                        </Link>
                      )
                    })}
                  </div>
                </div>


                <div className='gridWEb'>
                  <div>
                    <h2>Delivery </h2>
                    <p>se hai attivato la funzione delivery qui puoi gestire il raggio di consegna e i vari settaggi relativi alla spedizione</p>
                  </div>
                  <div>
                    {items.map((x,i)=>{
                      return(
                        <Link to={{
                           pathname: '/orarisetting', state: {
                           id: x._id}}
                         }>
                        <div style={{padding:20,borderRadius:8,maxWidth:500}} key={i}>
                          <div style={{display:'flex'}}>
                            <div style={{}}>
                              <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M6.804 10.336l1.181-2.331-.462-1.005h-4.523v-1h5.992c.238 0 .5.19.5.5 0 .311-.26.5-.5.5h-.368l.47 1h6.483l-.841-2h3.243c.823.005 1.49.675 1.49 1.5 0 .828-.672 1.5-1.5 1.5-.711 0-.727-1 0-1 .239 0 .5-.189.5-.5 0-.239-.189-.5-.5-.5h-1.727l1.324 3.209c.454-.136.936-.209 1.434-.209 2.76 0 5 2.24 5 5s-2.24 5-5 5c-2.759 0-5-2.24-5-5 0-1.906 1.069-3.564 2.64-4.408l-.43-1.039-4.493 5.947h-1.742c-.251 2.525-2.384 4.5-4.975 4.5-2.759 0-5-2.24-5-5s2.241-5 5-5c.636 0 1.244.119 1.804.336zm-.455.897c-.421-.151-.876-.233-1.349-.233-2.207 0-4 1.792-4 4s1.793 4 4 4c2.038 0 3.723-1.528 3.97-3.5h-3.103c-.174.299-.497.5-.867.5-.551 0-1-.448-1-1 0-.533.419-.97.946-.998l1.403-2.769zm10.675.29c-1.208.688-2.024 1.988-2.024 3.477 0 2.208 1.792 4 4 4s4-1.792 4-4-1.792-4-4-4c-.363 0-.716.049-1.05.14l1.182 2.869c.49.064.868.484.868.991 0 .552-.448 1-1 1-.551 0-1-.448-1-1 0-.229.077-.44.207-.609l-1.183-2.868zm-9.783.164l-1.403 2.766.029.047h3.103c-.147-1.169-.798-2.183-1.729-2.813zm.454-.898c1.254.804 2.126 2.152 2.28 3.711h.998l-2.455-5.336-.823 1.625zm7.683-1.789h-5.839l2.211 4.797 3.628-4.797zm-14.378 0h4v-1h-4v1zm1-4h4v-1h-4v1z"/></svg>
                            </div>
                            <div style={{paddingLeft: 15, color: 'black'}}>
                              <strong>Delivery</strong>
                            </div>
                          </div>
                        </div>
                        </Link>
                      )
                    })}
                  </div>
                </div>
                */}
            </div>
          </div>
      )
  }
}

const condition = authUser => {
console.log('mostro auth');
console.log(authUser);
if(authUser){
  if(authUser.roles){
    let indexStaff = authUser.roles.indexOf('STAFF_W')
    if(indexStaff > -1){
      return true;
    }else {
      return false
    }
  }else {
    return false
  }

}else {
  return false
}


}

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(HomePage);
