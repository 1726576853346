import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import {Link , Redirect} from "react-router-dom";
import {
    Button,
    H5,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Position,
    Spinner,
    Switch,
    Tag,
    Tooltip,
    TagInput
} from "@blueprintjs/core";
import { Select , ItemRenderer } from "@blueprintjs/select";
import { BrowserRouter as Router, Route } from "react-router-dom";



class NewCliente extends Component {

  constructor(props) {
    super(props);
    //this.getXXXX()
    this.state = {
      clienti:[],
      societa:null,
      rag_sociale:null,
      cod_fiscale:null,
      p_iva:null,
      variante:[],
      trueVariante:false,
      listax:[{id:'pppp',alias:'pollo'},{id:'yo',alias:'yo'},{id:'suc',alias:'suc'}],
      listaStagione:[],
      provaArt:{},
      collezione:null,
      redirect:false,
    };


  }



  getXXXX(){
    //usa per il get
    const ApiTags= new API ({url:'/cliente'});
    var entity= {name:"cliente"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.cliente
      .getAllParam({alias:newThis.state.name},ppp)
      .then(res=>{

        newThis.setState({clienti:res.data})
        console.log('ciaooo');
        console.log(newThis.state.clienti);
      });
    })


  }


  postProdotto(){

    console.log('proviamo a vedere');
    console.log(this.state);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/cliente'});
      var entity= {name:"cliente"};

      Item.createEntity(entity);

      console.log('booooo');

      var obj = {
        societa:  newThis.state.societa,
        cod_fiscale:newThis.state.descrizione,
        rag_sociale:newThis.state.rag_sociale,
        p_iva:newThis.state.p_iva

      }


      console.log('mostra');
      console.log(obj);
      Item.endpoints.cliente
      .create(obj,ppp)
      .then(res=>{

    newThis.setState({redirect:true})
      });
    })



  }


  _renderSelect2(item){
    //console.log('scoreggia');
    //console.log(item);
    //console.log(handleClick);
    return(
      <MenuItem
        key={item._id}
        label={item.nome}
        text={item.nome}
        onClick={()=>this.setState({collezione:item})}

      />
    )
  }


  getPar(){


    const ApiTags= new API ({url:'/lavori'});
    var entity= {name:"lavori"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.lavori
      .getAllOk(ppp)
      .then(res=>{
        //  console.log(res.data);
        });
    })
  }


  componentDidMount() {
    this.getXXXX();


  }

render() {
    let {redirect,clienti,societa,rag_sociale,cod_fiscale,p_iva, trueVariante,continuativo,listax, listaArtigiani,listaStagione,artigiani} = this.state;
    if(redirect){
      return <Redirect to="/clienti" />
    }else {
      return (
        <div style={{height:'100%',overflowY: 'auto',padding:10,display:'flex'}}>

      <div style={{width:'70%'}}>
        <h1> Nuovo cliente</h1>

        <button type="button" onClick={()=>this.postProdotto()}class="bp3-button bp3-icon-add .modifier" modifier>salva</button>
        <div style={{display:'inline-grid',padding:'10px'}}>
        <input class="bp3-input .modifier" value={societa}onChange={(x)=>this.setState({societa:x.target.value})} modifier type="text" placeholder="Società" dir="auto" /> <br></br>
        <input class="bp3-input .modifier" value={rag_sociale}onChange={(x)=>this.setState({rag_sociale:x.target.value})} modifier type="text" placeholder="rag_sociale" dir="auto" /><br></br>
        <input class="bp3-input .modifier" value={cod_fiscale}onChange={(x)=>this.setState({cod_fiscale:x.target.value})} modifier type="text" placeholder="cod_fiscale" dir="auto" /><br></br>
        <input class="bp3-input .modifier" value={p_iva}onChange={(x)=>this.setState({p_iva:x.target.value})} modifier type="text" placeholder="partita iva" dir="auto" /><br></br>

        </div>

      </div>


        </div>



      )
    }

  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(NewCliente);
