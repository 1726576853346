import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import Dropzone from 'react-dropzone'
import firebase from 'firebase';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import Img from 'react-image'
import {Link , Redirect} from "react-router-dom";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Noimg from "../VenditoreOrdine/no-img.jpg";
import {
    Button,
    H5,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Position,
    Spinner,
    Switch,
    Tag,
    Tooltip,
    TagInput
} from "@blueprintjs/core";
import { Select , ItemRenderer } from "@blueprintjs/select";
import { BrowserRouter as Router, Route } from "react-router-dom";




const SortableItem = SortableElement(({value}) => (
  <div>
    <div style={{display:'flex',paddingLeft:20,paddingRight:20,cursor:'grab',marginBottom:10}}>
      <div>
        <svg style={{height: 18,width: 18}} id="next-drag-handle"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Drag-Handle</title><path d="M7 2c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm0 6c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm0 6c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm6-8c1.104 0 2-.896 2-2s-.896-2-2-2-2 .896-2 2 .896 2 2 2zm0 2c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm0 6c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2z"></path></svg></svg>
      </div>
      <div  style={{display:'flex',width:'100%'}}>
       
        <div >
          {value && value.img && value.img.length > 0 ? <img style={{width:100}} src={value.img[0]} /> : <img style={{width:100}} src={Noimg} />}
        </div>
        <div  style={{marginLeft:10}}>
          <p>{value.nome}</p>
            {value.sconto && value.sconto != 0 ? <p className='tagFas'>{value.sconto}% Delivery</p> : null}
            {value.scontoAsp && value.scontoAsp != 0 ? <p style={{background:'#ff6d00'}} className='tagFas'>{value.scontoAsp}% asporto</p> : null}
        </div>
        <div  style={{marginLeft: 'auto'}}>
          <h3>{value.prezzo}€</h3>
        </div>
      </div>
    </div>
</div>
));

const SortableList = SortableContainer(({items}) => {
  return (
    <div style={{position:'relative'}}>
      {items.map((value, index) => (
        <SortableItem  key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  );
});

var _ = require('lodash');

class NewProd extends Component {

  constructor(props) {
    super(props);
    //this.getXXXX()
    this.state = {
      open:false,
      redirect:false,
      nome:null,
      piattiOk:[],
      active:false,
      rifRistor:[],
      rifOrganiz:null,
      items:[],
      clienti:[],
      ristoranti:[],
      ristorantiSel:[],
      piatti:[],
      selectedP:{},
      arraySele:[],
      piattiFilter:[],
      menu:null,
      posizione:null,
    };


  }


  selectedPA(item){
    let {selectedP} = this.state;
    selectedP[item] = selectedP[item] ? !selectedP[item] : true
    this.setState({selectedP})
  }

  getMyOrganiz(x){
    const ApiTags= new API ({url:'/organizazioneadmin/'+x});
    var entity= {name:"organizazioneadmin"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.organizazioneadmin
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati organ');
        console.log(res.data._id);
        newThis.setState({rifOrganiz:res.data._id})
        newThis.getXXXX(res.data._id);


      });
    })

  }


  getMyStores(x){
    const ApiTags= new API ({url:'/ristorantiadmins/'+x});
    var entity= {name:"ristorantiadmins"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.ristorantiadmins
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati');
        console.log(res.data);
        newThis.setState({ristoranti:res.data})

      });
    })

  }

  getStagione(){
    //usa per il get
    const ApiTags= new API ({url:'/stagione'});
    var entity= {name:"stagione"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.stagione
      .getAllParam({alias:newThis.state.name},ppp)
      .then(res=>{
        newThis.setState({listaStagione:res.data})
        console.log('brouos');
        console.log(newThis.state.listaStagione);
      });
    })
  }


  rimuoviProdotto(){


    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/categoria'});
      var entity= {name:"categoria"};

      Item.createEntity(entity);



      Item.endpoints.categoria
      .delete({id:newThis.props.location.state.id},ppp)
      .then(res=>{

      newThis.setState({redirect:true})
        });


    })



  }

  addPiatti(array){
    var keys = Object.keys(array);

    var filtered = keys.filter(function(key) {
        return array[key]
    });
    console.log('filtered');
    console.log(filtered);


    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/categoria'});
      var entity= {name:"categoria"};

      Item.createEntity(entity);
;

      let arrayArti = []

      newThis.state.ristoranti.forEach((item, i) => {
          if(item[item._id]){
            arrayArti.push(item._id)
          }

      });


      var obj = {
        id:newThis.props.location.state.id,
        piatti:filtered,

      }


      Item.endpoints.categoria
      .update(obj,ppp)
      .then(res=>{


        newThis.setState({open:false})
        newThis.getValori(newThis.props.location.state.id);
        newThis.getXXXX(newThis.state.rifOrganiz)
        });


    })



  }

  postProdotto(){

    console.log('proviamo a vedere');
    console.log(this.state);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/categoria'});
      var entity= {name:"categoria"};

      Item.createEntity(entity);
;

      let arrayArti = []

      newThis.state.ristoranti.forEach((item, i) => {
          if(item[item._id]){
            arrayArti.push(item._id)
          }

      });


      var obj = {
        id:newThis.props.location.state.id,
        nome:newThis.state.nome,
        active:newThis.state.active,
        rifRistor:newThis.state.ristorantiSel,
        posizione:newThis.state.posizione,
        piatti:newThis.state.piattiOk



      }



      console.log('mostra');
      console.log(obj);

      Item.endpoints.categoria
      .update(obj,ppp)
      .then(res=>{

      //newThis.setState({redirect:true})
      alert('cambio salvato')


        });


    })



  }

  gestioneArtigiani(x){

    let {ristorantiSel} = this.state;
    let index = ristorantiSel.indexOf(x)
    if(index > -1){
      ristorantiSel.splice(index, 1);
    }else {
      ristorantiSel.push(x)
    }


    this.setState({ristorantiSel})

  }


  getXXXX(xId){
    //usa per il get
    const ApiTags= new API ({url:'/piattobyorg'});
    var entity= {name:"piattobyorg"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.piattobyorg
      .getOne({id:xId},ppp)
      .then(res=>{

        newThis.setState({piatti:res.data,piattiFilter:res.data})
        console.log('ciaooo');
        console.log(res.data);
      });
    })
  }

  getValori(x){
    console.log('gang'+x);

    const ApiTags= new API ({url:'/categoria/'+x});
    var entity= {name:"categoria"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.categoria
      .getOne({id:x},ppp)
      .then(res=>{
        console.log('qui vloreee');
        console.log(res.data);
        newThis.setState({valori:res.data})
        console.log('gang');
        console.log(newThis.state.valori);
        console.log('suju');

        /*let arrayCateg = [];
        newThis.state.valori.categorie.forEach((item, i) => {
          arrayCateg.push(item._id)
        });*/

        let selectedP = {}
        newThis.state.valori.piatti.forEach((item, i) => {
          selectedP[item._id] = true
        });
        console.log('selecteee');
        console.log(selectedP);

        newThis.setState({menu:newThis.state.valori.rifMenu, nome:newThis.state.valori.nome,piattiOk:newThis.state.valori.piatti,selectedP,posizione:newThis.state.valori.posizione})





        newThis.setState({ristorantiSel:newThis.state.valori.rifRistor,active:newThis.state.valori.active})



      });
    })

  }

  onSortEnd = ({oldIndex, newIndex}) => {
    let {piattiOk} = this.state;
    this.setState(({piattiOk}) => ({
      piattiOk: arrayMove(piattiOk, oldIndex, newIndex),
    }));
  };




  componentDidMount() {

      this.getMyOrganiz();
      this.getMyStores();
      this.getValori(this.props.location.state.id);

  }

  checedRist(x){
    let {ristorantiSel} = this.state;


    let index = ristorantiSel.indexOf(x)
    if(index > -1){
      return true
    }else {
      return false
    }
  }

  filterPiatto(x){
    let {piatti} = this.state;


    var re = new RegExp("^" + x.target.value, "i");
    var test = piatti.filter(function (item) {
      return re.test(item.nome) ;
    });
    this.setState({piattiFilter:test})
  }


render() {
    let {posizione,active, menu,piattiFilter,piattiOk,selectedP,piatti,open,ristoranti,gallery,redirect,clienti,nome,cod_prod,descrizione,prezzo,valori, trueVariante,continuativo,listax, listaArtigiani,listaStagione,artigiani} = this.state;
    if(redirect){
      return <Redirect to={"/menu/"+(menu ? menu._id : null)} />
    }else {
      return (
        <div style={{background:'#f9fafb', height:'100%',overflowY: 'auto',padding:10,width:'100%',paddingBottom:50}}>

        <div style={{maxWidth:900,marginLeft:'auto',marginRight:'auto',display:'flex',}}>
      <div style={{width:'70%'}}>
      <Link onClick={()=>this.props.history.goBack()} ><p style={{marginTop:10,cursor:'pointer',color:'#6d6d6d',fontWeight:'600'}}>{menu ? '<'+menu.nome : null}</p>
      </Link>
        <h1>{nome}</h1>

        <div style={{marginTop:79}}>
        <div style={{marginBottom:30, padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <div style={{}}>
          <p>Nome categoria</p>
          <input style={{width:'100%',marginBottom:20,fontSize:20,padding:24}} class="bp3-input .modifier" value={nome} onChange={(x)=>this.setState({nome:x.target.value})} modifier type="text" placeholder="nome" dir="auto" /> <br></br>
          <p>Posizione</p>
          <input style={{width:'100%',marginBottom:20,fontSize:20,padding:24}} class="bp3-input .modifier" value={posizione} onChange={(x)=>this.setState({posizione:x.target.value})} modifier type="number" placeholder="posizione nel menu" dir="auto" /> <br></br>
          <div style={{padding:10}}>
            <p>visibile</p>
            <input checked={active} onClick={()=>this.setState({active:!active})} type='checkbox' />
          </div>

          </div></div>
      <Modal  open={open} onClose={()=>this.setState({open:false})} center>
        <h2>Seleziona piatti esistenti</h2>
        <input onChange={(x)=>this.filterPiatto(x)} style={{width:'100%',border:'none',border:'1px solid #ddd',marginBottom:20,padding:10,borderRadius:10}} type='text'/>
          <div className='modals'>

            {piattiFilter.map((piatto,i)=>{
              return (
                <div style={{padding: 10,display:'flex',paddingLeft:20,paddingRight:20,cursor:'pointer',marginBottom:10, background: selectedP[piatto._id] ? '#f7f7f7' : null, opacity: selectedP[piatto._id] ? 1 : 0.4 }}>
                  {/*<div style={{padding:10}}>
                    <input checked={selectedP[piatto._id]} onClick={()=>this.selectedPA(piatto._id)} type='checkbox' />
                  </div>*/}
                  <div onClick={()=>this.selectedPA(piatto._id)} style={{display:'flex',width:'100%'}}>
                    <div >
                      {piatto && piatto.img && piatto.img.length > 0 ? <img style={{width:100}} src={piatto.img[0]} /> : <img style={{width:100}} src={Noimg} />}
                    </div>
                    <div  style={{marginLeft:10}}>
                      <p>{piatto.nome}</p>
                        {piatto.sconto && piatto.sconto != 0 ? <p className='tagFas'>sconto {piatto.sconto}% Delivery sul prodotto</p> : null}
                        {piatto.scontoAsp && piatto.scontoAsp != 0 ? <p style={{background:'#ff6d00'}} className='tagFas'>sconto {piatto.scontoAsp}% asporto sul prodotto</p> : null}
                    </div>
                    <div  style={{marginLeft: 'auto'}}>
                      <h3>{piatto.prezzo}€</h3>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div style={{padding:10}}>
            <Button style={{color:'white',fontWeight:800,background:'#1cb7cd'}} onClick={()=> this.addPiatti(selectedP)}>Aggiungi</Button>
          </div>
        </Modal>
        <div style={{marginBottom:30, padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
        <div style={{textAlign: 'right'}}><Button onClick={()=>this.setState({open:true})} style={{background:'#1cb7cd',color:'white',border:'none'}}>Aggiungi/rimuovi piatto</Button></div>
        <div style={{marginTop:20}}>
          <SortableList items={piattiOk} onSortEnd={this.onSortEnd} />

        {/*piattiOk.map((p,i)=>{
            return (
              <div style={{display:'flex',paddingLeft:20,paddingRight:20,cursor:'pointer',marginBottom:10}}>
                <div  style={{display:'flex',width:'100%'}}>
                  <div >
                    {p && p.img && p.img.length > 0 ? <img style={{width:100}} src={p.img[0]} /> : <img style={{width:100}} src={Noimg} />}
                  </div>
                  <div  style={{marginLeft:10}}>
                    <p>{p.nome}</p>
                      {p.sconto && p.sconto != 0 ? <p className='tagFas'>{p.sconto}% Delivery</p> : null}
                      {p.scontoAsp && p.scontoAsp != 0 ? <p style={{background:'#ff6d00'}} className='tagFas'>{p.scontoAsp}% asporto</p> : null}
                  </div>
                  <div  style={{marginLeft: 'auto'}}>
                    <h3>{p.prezzo}€</h3>
                  </div>
                </div>
              </div>
            )
          })*/}
        </div>
        </div>
        </div>
        </div>
        <div style={{width:'30%'}}>
        <div style={{marginTop:138}}>
        <h3>Nome menu</h3>
        <div style={{marginBottom:30, padding:30, paddingBottom:0, paddingTop: 10, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
        <div style={{paddingBottom:'10px'}}>

        <h2 style={{textAlign: 'center',textTransform: 'capitalize'}}>{menu ? menu.nome : null}</h2>


        </div>

        </div>
      </div>

        </div>






        </div>
        <div style={{maxWidth:900,marginLeft:'auto',marginRight:'auto'}}>
          <button style={{float:'right',background:'#4a69bd',color:'white',fontWeight:'800',padding:10}} type="button" onClick={()=>this.postProdotto()}class="bp3-button .modifier" modifier>salva</button>

          <button style={{float:'right',background:'red',color:'white',fontWeight:'800',padding:10,marginRight:10}} type="button" onClick={()=>this.rimuoviProdotto()}class="bp3-button .modifier" modifier>Rimuovi</button>
        </div>
        </div>



      )
    }

  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(NewProd);
