
import React from 'react';

import { compose } from 'recompose';
import { Button ,Drawer} from "@blueprintjs/core";
import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';

import MenuTop from './topMenu'
import Lists from './liste'
import RightBar from '../RightBar'
import BarraCentrale from '../BarraCentrale'





class Landing extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open:false
    };
  }




  render() {
    return (
      <div className='contenxt_absolute'>
        <div style={{width:'20%',background:'#151b26',position: 'relative'}}>
          <MenuTop/>
          <Lists />
        <div className='boxAdd'>

          </div>
        </div>
        <div style={{width:'100%',background:'#ffffff'}}>
          <BarraCentrale />
          {this.props.children}
        </div>
        <div style={{width:'30%',background:'#fbfcff',borderLeft: '1px solid #ededed',height:'100%',overflowY:'auto'}}>
        <div style={{padding: 108}}><Button  onClick={()=>this.setState({open:true})} style={{ right:'21%', bottom:2, background:'#e55039',color:'white',fontWeight:'900',border:'none'}} text='Invita' />
        </div>
        <Drawer
            size='20%'
            isOpen={this.state.open}
            onClose={()=>this.setState({open:false})}
        >
        </Drawer>
        </div>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
)(Landing);
