import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import {Link , Redirect} from "react-router-dom";
import {
    Button,
    H5,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Position,
    Spinner,
    Switch,
    Tag,
    Tooltip,
    TagInput
} from "@blueprintjs/core";
import { Select , ItemRenderer } from "@blueprintjs/select";
import { BrowserRouter as Router, Route } from "react-router-dom";



class NewMateriale extends Component {

  constructor(props) {
    super(props);
    //this.getXXXX()
    this.state = {
      clienti:[],
      nome:null,
      cod_prod:null,
      descrizione:null,
      prezzo:null,
      variante:[],
      trueVariante:false,
      continuativo:false,
      listax:[{id:'pppp',alias:'pollo'},{id:'yo',alias:'yo'},{id:'suc',alias:'suc'}],
      listaArtigiani:[],
      listaStagione:[],
      artigiani:{},
      provaArt:{},
      collezione:null,
      redirect:false,
    };


  }



  getXXXX(){
    //usa per il get
    const ApiTags= new API ({url:'/cliente'});
    var entity= {name:"cliente"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.cliente
      .getAllParam({alias:newThis.state.name},ppp)
      .then(res=>{

        newThis.setState({clienti:res.data})
        console.log('ciaooo');
        console.log(newThis.state.clienti);
      });
    })


  }

  getArtigiani(){
    //usa per il get
    const ApiTags= new API ({url:'/artigiano'});
    var entity= {name:"artigiano"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.artigiano
      .getAllParam({alias:newThis.state.name},ppp)
      .then(res=>{

        newThis.setState({listaArtigiani:res.data})
        console.log('brouos');
        console.log(newThis.state.listaArtigiani);
      });
    })


  }

  getStagione(){
    //usa per il get
    const ApiTags= new API ({url:'/stagione'});
    var entity= {name:"stagione"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.stagione
      .getAllParam({alias:newThis.state.name},ppp)
      .then(res=>{
        newThis.setState({listaStagione:res.data})
        console.log('brouos');
        console.log(newThis.state.listaStagione);
      });
    })
  }


  postProdotto(){

    console.log('proviamo a vedere');
    console.log(this.state);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/materiale'});
      var entity= {name:"materiale"};

      Item.createEntity(entity);

      console.log('booooo');

      let arrayArti = []
      for (const property in newThis.state.artigiani) {

        if(newThis.state.artigiani[property])
          {console.log('eskerin');
            arrayArti.push(property)
          }
        }

      var obj = {
        nome:  newThis.state.nome,
        artigiano:arrayArti
      }

      if(newThis.state.collezione){
        obj.stagione = newThis.state.collezione._id;
      }

      console.log('mostra');
      console.log(obj);
      Item.endpoints.materiale
      .create(obj,ppp)
      .then(res=>{

    newThis.setState({redirect:true})
      });
    })



  }


  _renderSelect2(item){
    //console.log('scoreggia');
    //console.log(item);
    //console.log(handleClick);
    return(
      <MenuItem
        key={item._id}
        label={item.nome}
        text={item.nome}
        onClick={()=>this.setState({collezione:item})}

      />
    )
  }


  getPar(){


    const ApiTags= new API ({url:'/lavori'});
    var entity= {name:"lavori"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.lavori
      .getAllOk(ppp)
      .then(res=>{
        //  console.log(res.data);
        });
    })
  }


  componentDidMount() {
    this.getXXXX();
      this.getArtigiani();
      this.getStagione()

  }

  gestioneArtigiani(x){
    let {artigiani} = this.state;
    artigiani[x] = !artigiani[x]
    this.setState({artigiani})

    console.log(artigiani);
    for (const property in artigiani) {

      if(artigiani[property])
        {console.log('eskerin');}
//  console.log(`${property}: ${artigiani[property]}`);
}
  }

render() {
    let {redirect,clienti,nome,cod_prod,descrizione,prezzo, trueVariante,continuativo,listax, listaArtigiani,listaStagione,artigiani} = this.state;
    if(redirect){
      return <Redirect to="/materiale" />
    }else {
      return (
        <div style={{height:'100%',overflowY: 'auto',padding:10,display:'flex'}}>

      <div style={{width:'70%'}}>
        <h1> add Materiale</h1>

        <button type="button" onClick={()=>this.postProdotto()}class="bp3-button bp3-icon-add .modifier" modifier>salva</button>
        <div style={{display:'inline-grid',padding:'10px'}}>
        <input class="bp3-input .modifier" value={nome}onChange={(x)=>this.setState({nome:x.target.value})} modifier type="text" placeholder="nome" dir="auto" /> <br></br>

        <h1>variante</h1>

          <label class="bp3-control bp3-checkbox .modifier">
            <input type="checkbox" modifier checked={trueVariante} onChange={()=>this.setState({trueVariante:!trueVariante})} />
            <span class="bp3-control-indicator"></span>
            il prodotto ha delle varianti
          </label>

          {trueVariante ? (
            <div style={{marginBottom:'1500px'}}>
              <h1 style={{color:'red'}}>opzione</h1>
                <TagInput
                    onChange={(variante: string[]) => this.setState({ variante })}
                    values={this.state.variante}
                />
            </div>
          ) : null}

        </div>

      </div>




      <div style={{width:'30%'}}>
        <div style={{backgroundColor:'#ececec',borderRadius:'5px',border:'1px solid #dedcdc'}}>
          <div style={{margin:'10px',paddingBottom:'10px'}}>
    <h1> collezione</h1>
        <Select filterable={false} onItemSelect={(x)=>console.log('selexionato: '+x)}   itemRenderer={this._renderSelect2.bind(this)}  items={listaStagione} ><Button  style={{marginBottom: '10px'}}text={!this.state.collezione ?'Seleziona Stagione' : this.state.collezione.nome} disabled={false} /></Select>


      <label class="bp3-control bp3-checkbox .modifier">
        <input type="checkbox" modifier checked={continuativo} onChange={()=>this.setState({continuativo:!continuativo})} />
        <span class="bp3-control-indicator"></span>
        continuativo
      </label>

      <h1>artigiani</h1>
      {listaArtigiani.map((a,i)=>{
        return (
          <label class="bp3-control bp3-checkbox .modifier">
            <input type="checkbox" modifier checked={artigiani[a._id]} onChange={()=>this.gestioneArtigiani(a._id)} />
            <span class="bp3-control-indicator"></span>
            {a.nome}
          </label>
        )
      })}
    </div>

   </div>
      </div>






        </div>



      )
    }

  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(NewMateriale);
