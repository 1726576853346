import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import Dropzone from 'react-dropzone'
import firebase from 'firebase';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import Img from 'react-image'
import {Link , Redirect} from "react-router-dom";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {
    Button,
    H5,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Position,
    Spinner,
    Switch,
    Tag,
    Tooltip,
    TagInput
} from "@blueprintjs/core";
import { Select , ItemRenderer } from "@blueprintjs/select";
import { BrowserRouter as Router, Route } from "react-router-dom";



class NewProd extends Component {

  constructor(props) {
    super(props);
    //this.getXXXX()
    this.state = {
      redirect:false,
      nome:null,
      active:true,
      rifRistor:[],
      rifOrganiz:null,
      items:[],
      clienti:[],
      ristoranti:[],
      ristorantiSel:[]
    };


  }


  getMyOrganiz(x){
    const ApiTags= new API ({url:'/organizazioneadmin/'+x});
    var entity= {name:"organizazioneadmin"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.organizazioneadmin
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati organ');
        console.log(res.data._id);
        newThis.setState({rifOrganiz:res.data._id})

      });
    })

  }


  getMyStores(x){
    const ApiTags= new API ({url:'/ristorantiadmins/'+x});
    var entity= {name:"ristorantiadmins"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.ristorantiadmins
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati');
        console.log(res.data);
        newThis.setState({ristoranti:res.data})

      });
    })

  }

  getStagione(){
    //usa per il get
    const ApiTags= new API ({url:'/stagione'});
    var entity= {name:"stagione"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.stagione
      .getAllParam({alias:newThis.state.name},ppp)
      .then(res=>{
        newThis.setState({listaStagione:res.data})
        console.log('brouos');
        console.log(newThis.state.listaStagione);
      });
    })
  }




  postProdotto(){

    console.log('proviamo a vedere');
    console.log(this.state);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/categoria'});
      var entity= {name:"categoria"};

      Item.createEntity(entity);
;

      let arrayArti = []

      newThis.state.ristoranti.forEach((item, i) => {
          if(item[item._id]){
            arrayArti.push(item._id)
          }

      });


      var obj = {
        nome:newThis.state.nome,
        active:true,
        rifRistor:newThis.state.ristorantiSel,
        rifOrganiz:newThis.state.rifOrganiz,


      }



      console.log('mostra');
      console.log(obj);

      Item.endpoints.categoria
      .create(obj,ppp)
      .then(res=>{

      newThis.setState({redirect:true})
        });


    })



  }

  gestioneArtigiani(x){

    let {ristorantiSel} = this.state;
    let index = ristorantiSel.indexOf(x)
    if(index > -1){
      ristorantiSel.splice(index, 1);
    }else {
      ristorantiSel.push(x)
    }


    this.setState({ristorantiSel})

  }





  componentDidMount() {


      this.getMyOrganiz();
      this.getMyStores();

  }


render() {
    let {ristoranti,gallery,redirect,clienti,nome,cod_prod,descrizione,prezzo,valori, trueVariante,continuativo,listax, listaArtigiani,listaStagione,artigiani} = this.state;
    if(redirect){
      return <Redirect to="/categorie" />
    }else {
      return (
        <div style={{background:'#f9fafb', height:'100%',overflowY: 'auto',padding:10,width:'100%',paddingBottom:50}}>
        <div style={{maxWidth:900,marginLeft:'auto',marginRight:'auto',display:'flex',}}>
      <div style={{width:'70%'}}>
      <Link to={{
        pathname: '/'}
      }><p style={{marginTop:10,cursor:'pointer',color:'#6d6d6d',fontWeight:'600'}}>{"< Categorie"}</p>
      </Link>
        <h1>{nome}</h1>

        <div style={{marginTop:79}}>
        <div style={{marginBottom:30, padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <div style={{}}>
          <p>Nome categoria</p>
          <input style={{width:'100%',marginBottom:20}} class="bp3-input .modifier" value={nome}onChange={(x)=>this.setState({nome:x.target.value})} modifier type="text" placeholder="nome" dir="auto" /> <br></br>
        </div></div>
        </div>
        </div>
        <div style={{width:'30%'}}>
        <div style={{marginTop:108,marginBottom:30, padding:30, paddingTop: 10, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
        <div style={{paddingBottom:'10px'}}>


        <h1>Ristoranti</h1>
        <p style={{marginBottom:30}}>Seleziona i ristoranti in cui si trova la categoria</p>
        {ristoranti.map((a,i)=>{
        return (
        <label key={i} class="bp3-control bp3-checkbox .modifier">
        <input type="checkbox" modifier checked={ristoranti[a._id]} onChange={()=>this.gestioneArtigiani(a._id)} />
        <span class="bp3-control-indicator"></span>
        {a.nome}
        </label>
        )
        })}
        </div>

        </div>

        </div>






        </div>
        <div style={{maxWidth:900,marginLeft:'auto',marginRight:'auto'}}>
        <button style={{float:'right',background:'#4a69bd',color:'white',fontWeight:'800',padding:10}} type="button" onClick={()=>this.postProdotto()}class="bp3-button .modifier" modifier>salva</button>
        </div>
        </div>



      )
    }

  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(NewProd);
