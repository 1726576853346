
import React from 'react';
import API from "../../util/Api.js"
import {Link} from "react-router-dom";

class Barra extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      items:[]
    };
  }



  componentDidMount() {
  }


  render() {
    const {items} = this.state;
    return (
      <div className='itemUser' style={{background:'white',height:0,marginTop:-10}}>
        <p style={{textAlign:'center'}}></p>
      </div>
    );
  }
}



export default Barra
