import React from 'react';
import { Button, ButtonGroup,Drawer } from "@blueprintjs/core"
import { IExampleProps } from "@blueprintjs/docs-theme";


import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';

class Liste extends React.PureComponent<IExampleProps, IButtonGroupExampleState> {

  constructor(props) {
    super(props);
    this.state = {
      open:false
    };
  }




  render() {
    return (
      <div>
      <Drawer
          position='left'
          size='80%'
          isOpen={this.state.open}
          onClose={()=>this.setState({open:false})}
      >
      </Drawer>

      <ButtonGroup style={{padding:10 }} minimal={true} vertical={true} alignText={'left'}>
          <h4 style={{color:'#929292'}}>RICERCA</h4>
          <input style={{padding: 8, borderRadius: 11, background: '#2a3344', border: 'none'}} type="text" placeholder='ricerca' onClick={()=>this.setState({open:true})} />

          {/*<Button style={{color:'white',fontWeight: 600}}>one</Button>
          <Button style={{color:'white',fontWeight: 600}}>two</Button>
          <Button style={{color:'white',fontWeight: 600}} >tree</Button>*/}
      </ButtonGroup>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
)(Liste);
//export default Liste;
