import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import Dropzone from 'react-dropzone'
import firebase from 'firebase';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import Img from 'react-image'
import {Link , Redirect} from "react-router-dom";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import MapInput from '../MapInput';
import { loadStripe } from '@stripe/stripe-js';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.


import {
    Button,
    H5,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Position,
    Spinner,
    Switch,
    Tag,
    Tooltip,
    TagInput
} from "@blueprintjs/core";
import { Select , ItemRenderer } from "@blueprintjs/select";
import { BrowserRouter as Router, Route } from "react-router-dom";
import 'antd/dist/antd.css';
import { TimePicker } from 'antd';
import moment from 'moment';
import Grid from 'antd/lib/card/Grid';

const axios = require('axios')
const stripePromise = loadStripe('pk_test_rFDgx5pPYhBrtBn6FsiZb6w1');

const onToken = (token) => {
  console.log('Stripe Token', token);
};

const { RangePicker } = TimePicker;

const SortableItem = SortableElement(({value, sortIndex}) => (
  <div style={{width:150}}>
      <Img style={{width:150}} src={value} loader={<p>pollo</p>}/>
  </div>
));

const SortableList = SortableContainer(({items}) => {
return (
  <div style={{overflow: 'scroll hidden',whiteSpace:'nowrap',display:'flex'}}>
    {items.map((value, index) => (
      <SortableItem key={`item-${value}`} index={index} value={value} />
    ))}
  </div>
);
});

class NewProd extends Component {

  constructor(props) {
    super(props);
    //this.getXXXX()
    this.state = {
      settOraT:false,
      settOra:false,
      whatupNumber:null,
      settimana:['lunedì','martedì','mercoledì','giovedì','venerdì','sabato','domenica'],
      typeFlow:null,
      paypalClienid:null,
      take_away:false,
      take_away_time:null,
      delivery:false,
      delivery_time:[],
      preordine:false,
      preordine_time:null,
      orari:{},
      orari_takeA:{},
      payPal:false,
      payPal_takeA:false,
      stripe_takeA:false,
      stripe:false,
      contanti_Alla_consegna:false,
      contanti_Alla_consegna_takeA:false,
      aperto:false,
      active:false,
      ristoranti:[],
      ristorantiSel:[],
      categorie:[],
      categorieSel:[],
      objLocation:null,
      objSpediz:null,
      radiusSped:1000,
      img:null,
      imgGallery:[],
      matrimimgGallery:[],
      rifOrganiz:null,
      clienti:[],
      nome:null,
      cod_prod:null,
      descrizione:null,
      prezzo:0,
      minino_asporto:0,
      minino_delivery:0,
      variante:[],
      trueVariante:false,
      continuativo:false,
      listax:[{id:'pppp',alias:'pollo'},{id:'yo',alias:'yo'},{id:'suc',alias:'suc'}],
      listaArtigiani:[],
      listaStagione:[],
      gallery:[],
      artigiani:{},
      provaArt:{},
      collezione:null,
      redirect:false,
      valori:null,
      progress:null,
      titoloMatrim:null,
      descMatrim:null,
      copertMatr:null,
      copertEvent:null,
      galleria:[],
      titoloGalleria:null ,
      descGalleria:null,
      copertGallery:null
    };


  }

  getMyOrganiz(x){
    const ApiTags= new API ({url:'/organizazioneadmin/'+x});
    var entity= {name:"organizazioneadmin"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.organizazioneadmin
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati organ');
        console.log(res.data._id);
        newThis.setState({rifOrganiz:res.data._id})
        newThis.getXXXX(res.data._id)
      });
    })

  }


  getMyStores(x){
    const ApiTags= new API ({url:'/ristorantiadmins/'+x});
    var entity= {name:"ristorantiadmins"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.ristorantiadmins
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati');
        console.log(res.data);
        newThis.setState({ristoranti:res.data})

      });
    })

  }

  async getUsers(){
    let res = await axios.get("http://localhost:3001/api/v1/ristorantipayment");
    let { data } = res.data;
    console.log('mostroooo');
    console.log(res.data);
    return res.data.id
  };

  async handleClick(){
      const ApiTags= new API ({url:'/ristorantipayment/'});
      var entity= {name:"ristorantipayment"};
        var ppp = {'Authorization' : 'Bearer dsdsds'};
      const sessions = await this.getUsers();
      console.log('sessionId');
      console.log(sessions);
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId:sessions,
      });
  }

  addImg(xxx){


    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/prodotto'});
      var entity= {name:"prodotto"};

      Item.createEntity(entity);


      var obj = {
        id:newThis.props.location.state.id,
        gallery:[xxx]


      }

      Item.endpoints.prodotto
      .update(obj,ppp)
      .then(res=>{

      });
    })




  }



  handleUploadcopert = (images,matrim) => {
    let img = this.state.img;
    let copertMatr = this.state.coperinaMatr;
    
    var storage = firebase.storage();
    let newThis = this;
    //const image = images[0];
    images.forEach((image, i) => {
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        snapshot => {
          // progress function ...
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({ progress });
        },
        error => {
          // Error function ...
          console.log(error);
        },
        () => {
          // complete function ...
          storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then(url => {
              console.log('qui finitoooooo');
              console.log(url);
              img = url;

              if(matrim){
                 
                newThis.setState({copertMatr:img})
              }else{
                
                newThis.setState({copertEvent:img})
              }
              
              //this.setState({ url });
            });
        }
      );
    });
  }



  handleUploadGallery = (images,copertina) => {
    let img = this.state.img;
    let galleria = this.state.galleria;
    
    var storage = firebase.storage();
    let newThis = this;
    //const image = images[0];
    images.forEach((image, i) => {
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        snapshot => {
          // progress function ...
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({ progress });
        },
        error => {
          // Error function ...
          console.log(error);
        },
        () => {
          // complete function ...
          storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then(url => {
              console.log('qui finitoooooo');
              console.log(url);
              img = url;

              if(copertina){
                 
                newThis.setState({copertGallery:img})
              }else{
                
                galleria.push({
                  img:img,
                  text:'null'
                })
                newThis.setState({galleria})
                
              }
              
              //this.setState({ url });
            });
        }
      );
    });
  }

  handleUpload = (images,gallery,matrim) => {
      let img = this.state.img;
      let imgGallery = this.state.imgGallery;
      let matrimimgGallery = this.state.matrimimgGallery;
      var storage = firebase.storage();
      let newThis = this;
      //const image = images[0];
      images.forEach((image, i) => {
        const uploadTask = storage.ref(`images/${image.name}`).put(image);
        uploadTask.on(
          "state_changed",
          snapshot => {
            // progress function ...
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            this.setState({ progress });
          },
          error => {
            // Error function ...
            console.log(error);
          },
          () => {
            // complete function ...
            storage
              .ref("images")
              .child(image.name)
              .getDownloadURL()
              .then(url => {
                console.log('qui finitoooooo');
                console.log(url);
                img = url;

                if(gallery){
                   
                  imgGallery.push({
                    img:img,
                    text:'null'
                  })
                  newThis.setState({imgGallery})
                }else{
                  if(matrim){
                    matrimimgGallery.push({
                      img:img,
                      text:'null'
                    })
                    newThis.setState({matrimimgGallery})
                  }else{
                    newThis.setState({img})
                  }
                  
                }
                
                //this.setState({ url });
              });
          }
        );
      });



    };




  getValori(x){
    console.log('gang'+x);

    const ApiTags= new API ({url:'/ristorante/'+x});
    var entity= {name:"ristorante"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.ristorante
      .getOne({id:x},ppp)
      .then(res=>{
        console.log('qui vloreee');
        console.log(res.data);
        newThis.setState({valori:res.data})
        console.log('gang');
        console.log(newThis.state.valori);
        console.log('suju');


        console.log('vediamo orari');
        console.log(newThis.state.valori.orari ? newThis.state.valori.orari : {} );

        /*let arrayCateg = [];
        newThis.state.valori.categorie.forEach((item, i) => {
          arrayCateg.push(item._id)
        });*/


        newThis.setState({img:newThis.state.valori.img});
        newThis.setState({nome:newThis.state.valori.nome})
        newThis.setState({descrizione:newThis.state.valori.desc})
        newThis.setState({prezzo:newThis.state.valori.prezzoConsegna})
        newThis.setState({minino_delivery:newThis.state.valori.minino_delivery,minino_asporto:newThis.state.valori.minino_asporto})
        newThis.setState({categorieSel:newThis.state.valori.categorie})
        newThis.setState({
          ristorantiSel:newThis.state.valori.rifRistor,
          active:newThis.state.valori.active,
          aperto:newThis.state.valori.open,
          objLocation:newThis.state.valori.indirizzoGeo,
          objSpediz:newThis.state.valori.areaLavoro,
          titoloMatrim:newThis.state.valori.titoloMatrim,
          descMatrim:newThis.state.valori.descMatrim,
          titoloEvent:newThis.state.valori.titoloEvent,
          descEvent:newThis.state.valori.descEvent,
          copertMatr:newThis.state.valori.copertMatr,
          copertEvent:newThis.state.valori.copertEvent,
          titoloGalleria:newThis.state.valori.titoloGalleria, 
          descGalleria:newThis.state.valori.descGalleria,
          copertGallery:newThis.state.valori.copertGallery,
          galleria:newThis.state.valori.galleria, 

        })
        newThis.setState({
          paypalClienid:newThis.state.valori.paypalClienid,
          whatupNumber:newThis.state.valori.whatupNumber,
          imgGallery:newThis.state.valori.imgGallery ?  newThis.state.valori.imgGallery  : [],
          matrimimgGallery:newThis.state.valori.matrimimgGallery ?  newThis.state.valori.matrimimgGallery  : [],
          take_away:newThis.state.valori.take_away,
          take_away_time:newThis.state.valori.take_away_time,
          delivery:newThis.state.valori.delivery,
          delivery_time:newThis.state.valori.delivery_time,
          preordine:newThis.state.valori.preordine,
          preordine_time:newThis.state.valori.preordine_time,
          orari:newThis.state.valori.orari ? newThis.state.valori.orari : {},
          orari_takeA:newThis.state.valori.orari_takeA ? newThis.state.valori.orari_takeA : {},
          payPal:newThis.state.valori.payPal,
          payPal_takeA:newThis.state.valori.payPal_takeA,
          stripe:newThis.state.valori.stripe,
          stripe_takeA:newThis.state.valori.stripe_takeA,
          contanti_Alla_consegna:newThis.state.valori.contanti_Alla_consegna,
          contanti_Alla_consegna_takeA:newThis.state.valori.contanti_Alla_consegna_takeA,
          typeFlow:newThis.state.valori.typeFlow,
        })


        console.log('qui cosa succ');
        console.log(newThis.state.valori.orari);




      });
    })

  }




  getArtigiani(){
    //usa per il get
    const ApiTags= new API ({url:'/artigiano'});
    var entity= {name:"artigiano"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.artigiano
      .getAllParam({alias:newThis.state.name},ppp)
      .then(res=>{

        newThis.setState({listaArtigiani:res.data})
        console.log('brouos');
        console.log(newThis.state.listaArtigiani);
      });
    })


  }

  getStagione(){
    //usa per il get
    const ApiTags= new API ({url:'/stagione'});
    var entity= {name:"stagione"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.stagione
      .getAllParam({alias:newThis.state.name},ppp)
      .then(res=>{
        newThis.setState({listaStagione:res.data})
        console.log('brouos');
        console.log(newThis.state.listaStagione);
      });
    })
  }

  updateGalleryProdotto(array){


    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/prodotto'});
      var entity= {name:"prodotto"};

      Item.createEntity(entity);

      console.log('booooo');




      var obj = {
        id:newThis.props.location.state.id,
        gallery:array,



      }


      Item.endpoints.prodotto
      .update(obj,ppp)
      .then(res=>{

      //console.log(array);
      //alert('salvato')
    //newThis.setState({redirect:true})
      });
    })



  }


  postProdotto(){

    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/ristorante'});
      var entity= {name:"ristorante"};

      Item.createEntity(entity);


      var obj = {
        id:newThis.props.location.state.id,
        nome: newThis.state.nome,
        whatupNumber: newThis.state.whatupNumber,
        img: newThis.state.img,
        imgGallery: newThis.state.imgGallery,
        matrimimgGallery: newThis.state.matrimimgGallery,
        desc:newThis.state.descrizione,
        prezzoConsegna:newThis.state.prezzo,
        minino_asporto:newThis.state.minino_asporto,
        minino_delivery:newThis.state.minino_delivery,
        indirizzoCompleto:newThis.state.objLocation.place_name,
        indirizzoGeo:newThis.state.objLocation.geometry,
        areaLavoro:newThis.state.objSpediz.geometry,
        raggioSpedizione:newThis.state.radiusSped,
        open:newThis.state.aperto,
        active:newThis.state.active,
        take_away:newThis.state.take_away,
        take_away_time:newThis.state.take_away_time,
        delivery:newThis.state.delivery,
        delivery_time:newThis.state.delivery_time,
        preordine:newThis.state.preordine,
        preordine_time:newThis.state.preordine_time,
        payPal:newThis.state.payPal,
        payPal_takeA:newThis.state.payPal_takeA,
        stripe:newThis.state.stripe,
        stripe_takeA:newThis.state.stripe_takeA,
        contanti_Alla_consegna:newThis.state.contanti_Alla_consegna,
        contanti_Alla_consegna_takeA:newThis.state.contanti_Alla_consegna_takeA,
        orari:newThis.state.orari,
        orari_takeA:newThis.state.orari_takeA,
        titoloMatrim:newThis.state.titoloMatrim,
        descMatrim:newThis.state.descMatrim,
        titoloEvent:newThis.state.titoloEvent,
        descEvent:newThis.state.descEvent,
        copertMatr:newThis.state.copertMatr,
        copertEvent:newThis.state.copertEvent,
        titoloGalleria:newThis.state.titoloGalleria, 
        descGalleria:newThis.state.descGalleria,
        copertGallery:newThis.state.copertGallery,
        galleria:newThis.state.galleria, 
      }



      console.log('mostra');
      console.log(obj);
      Item.endpoints.ristorante
      .update(obj,ppp)
      .then(res=>{

      //newThis.setState({redirect:true})
      alert('modifiche salvate')
    });

    })



  }


  _renderSelect2(item){
    //console.log('scoreggia');
    //console.log(item);
    //console.log(handleClick);
    return(
      <MenuItem
        key={item._id}
        label={item.nome}
        text={item.nome}
        onClick={()=>this.setState({collezione:item})}

      />
    )
  }


  getPar(){


    const ApiTags= new API ({url:'/lavori'});
    var entity= {name:"lavori"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.lavori
      .getAllOk(ppp)
      .then(res=>{
        //  console.log(res.data);
        });
    })
  }


  componentDidMount() {

    console.log('eskerin');

    this.getMyOrganiz();
    this.getMyStores();
    this.getValori(this.props.location.state.id);


  }


  getXXXX(x){
    //usa per il get
    const ApiTags= new API ({url:'/categoriabyorg'});
    var entity= {name:"categoriabyorg"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.categoriabyorg
      .getOne({id:x},ppp)
      .then(res=>{

        newThis.setState({categorie:res.data})
        console.log('ciaooo');
        console.log(newThis.state.prodotto);
      });
    })
  }


  gestioneArtigiani(x){

    let {ristorantiSel} = this.state;
    let index = ristorantiSel.indexOf(x)
    if(index > -1){
      ristorantiSel.splice(index, 1);
    }else {
      ristorantiSel.push(x)
    }


    this.setState({ristorantiSel})

  }

  gestioneCategorie(x){

    let {categorieSel} = this.state;
    let index = categorieSel.indexOf(x)
    if(index > -1){
      categorieSel.splice(index, 1);
    }else {
      categorieSel.push(x)
    }


    this.setState({categorieSel})

  }



  checedRist(x){
    let {ristorantiSel} = this.state;


    let index = ristorantiSel.indexOf(x)
    if(index > -1){
      return true
    }else {
      return false
    }
  }

  checed(x){
    let {categorieSel} = this.state;


    let index = categorieSel.indexOf(x)
    if(index > -1){
      return true
    }else {
      return false
    }
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({gallery}) => ({
      gallery: arrayMove(gallery, oldIndex, newIndex),
    }));
  };


  valueOrario(day){
      let {orari} = this.state;
      let arrayTo = [];
      if(orari[day] == null ){
        return arrayTo = []
      }else {
        return arrayTo = [orari[day].M ?  moment(orari[day].M[0] ,'HH:mm' ) : null , orari[day].M ?  moment(orari[day].M[1] ,'HH:mm' ) : null  ]

      }



  }




  valueOrarioP(day){
      let {orari} = this.state;
      let arrayTo = [];
      if(orari[day] == null ){
        return arrayTo = []
      }else {
        return arrayTo = [orari[day].P ?  moment(orari[day].P[0] ,'HH:mm' ) : null , orari[day].P ?  moment(orari[day].P[1] ,'HH:mm' ) : null  ]

      }



  }


  valueOrarioT(day){
      let {orari_takeA} = this.state;
      let arrayTo = [];
      if(orari_takeA){
        if(orari_takeA[day] == null ){
          return arrayTo = []
        }else {
          return arrayTo = [orari_takeA[day].M ?  moment(orari_takeA[day].M[0] ,'HH:mm' ) : null , orari_takeA[day].M ?  moment(orari_takeA[day].M[1] ,'HH:mm' ) : null  ]

        }
      }else {
        return arrayTo = []
      }




  }




  valueOrarioPT(day){
      let {orari_takeA} = this.state;
      let arrayTo = [];
      if(orari_takeA){
        if(orari_takeA[day] == null ){
          return arrayTo = []
        }else {
          return arrayTo = [orari_takeA[day].P ?  moment(orari_takeA[day].P[0] ,'HH:mm' ) : null , orari_takeA[day].P ?  moment(orari_takeA[day].P[1] ,'HH:mm' ) : null  ]

        }
      }else {
        return arrayTo = []
      }




  }

  addOrario(day,array,type){
    let {orari} = this.state;
    if(orari){
      if(orari[day]){
        orari[day][type] = array;
      }else {
        orari[day] = {};
        orari[day][type] = array;
      }
    }else {
     orari = {};
     orari[day] = {};
     orari[day][type] = array;
    }


    console.log(orari);
    this.setState(orari)
  }

  addOrarioT(day,array,type){
    let {orari_takeA} = this.state;
    if(orari_takeA){
      if(orari_takeA[day]){
        orari_takeA[day][type] = array;
      }else {
        orari_takeA[day] = {};
        orari_takeA[day][type] = array;
      }
    }else {
     orari_takeA = {};
     orari_takeA[day] = {};
     orari_takeA[day][type] = array;
    }


    //console.log(orari);
    this.setState(orari_takeA)
  }

  removeImgG(index){
      let {imgGallery} =this.state;
      if (index > -1) {
        imgGallery.splice(index, 1);
      }
      this.setState({imgGallery})

  }


  removeImgGM(index){
    let {matrimimgGallery} =this.state;
    if (index > -1) {
      matrimimgGallery.splice(index, 1);
    }
    this.setState({matrimimgGallery})

}

  removeImgGia(index){
    let {galleria} =this.state;
    if (index > -1) {
      galleria.splice(index, 1);
    }
    this.setState({galleria})

}
  testoImgG(index,text,matr){
    if(matr){
      let {matrimimgGallery} =this.state;
      matrimimgGallery[index].text = text;
      this.setState({matrimimgGallery})
    }else{
      let {imgGallery} =this.state;
      imgGallery[index].text = text;
      this.setState({imgGallery})
    }


    
    

}


testoImgGia(index,text){
  let {galleria} =this.state;
  galleria[index].text = text;
    this.setState({galleria})


  
  

}

render() {
    let {galleria, matrimimgGallery,imgGallery, minino_asporto,minino_delivery, stripe,stripe_takeA, payPal_takeA,contanti_Alla_consegna_takeA,settOraT,settOra,whatupNumber,take_away_time,delivery_time,settimana,typeFlow,payPal,contanti_Alla_consegna,preordine,delivery,take_away, aperto,active,img,categorie, ristoranti,gallery,redirect,clienti,nome,cod_prod,descrizione,prezzo,valori, trueVariante,continuativo,listax, listaArtigiani,listaStagione,artigiani} = this.state;
    if(redirect){
      return <Redirect to="/setting" />
    }else {
      return (
        <div style={{background:'#f9fafb', height:'100%',overflowY: 'auto',padding:10,width:'100%',paddingBottom:50}}>
        <div className='flex' style={{maxWidth:900,marginLeft:'auto',marginRight:'auto'}}>
      <div className='x70c'>
      <Link to={{
        pathname: '/setting'}
      }><p style={{marginTop:10,cursor:'pointer',color:'#6d6d6d',fontWeight:'600'}}>{"< Settaggi"}</p>
      </Link>
        <h1>{nome ? nome : '_'}</h1>

        <div>
        <div style={{marginBottom:30, padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <div style={{}}>
          <p>Nome</p>

          <input style={{width:'100%',marginBottom:20}} class="bp3-input .modifier" value={nome}onChange={(x)=>this.setState({nome:x.target.value})} modifier type="text" placeholder="nome" dir="auto" /> <br></br>

          {typeFlow == 'whatp' ? (
            <div>
              <p>Numero whatsapp per ricezione</p>
              <input style={{width:'100%',marginBottom:20}} class="bp3-input .modifier" value={whatupNumber}onChange={(x)=>this.setState({whatupNumber:x.target.value})} modifier type="text" placeholder="numero telefono esempio +393497271159" dir="auto" /> <br></br>
            </div>
          ) : null}


            {this.state.objLocation ? (
              <div style={{marginBottom:50}}>
                <p>Indirizzo ristorante</p>
                <MapInput value={this.state.objLocation}  callback={(x)=>this.setState({objLocation:x})} />
              </div>
            ) : null}


          <p>Descrizione</p>
          <textarea style={{width:'100%',marginBottom:20,height: 100 }} class="bp3-input .modifier" value={descrizione}onChange={(x)=>this.setState({descrizione:x.target.value})} modifier placeholder="descrizione" dir="auto"></textarea><br></br>



      </div></div>
      <div style={{marginBottom:30, padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
        {img ? (
          <img style={{width:100}} src={img} />
        ) : null}

        <Dropzone multiple={false} onDrop={acceptedFiles => this.handleUpload(acceptedFiles)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div class='drag' {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Trascina immagine copertina ristorante</p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>


      <div style={{marginBottom:30, padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
        <h2>Immagini galleria Home</h2>
        <div style={{display:'grid',gridTemplateColumns:'25% 25% 25% 25%'}}>
        {imgGallery.map((x,i)=>{
          return (<div key={i}><div>
                    <img style={{width:100}} src={x.img} />
                    <div>
                    <input style={{border:'none'}} onChange={(e)=>this.testoImgG(i,e.target.value)} value={x.text} type='text' placeholder='testo...'></input>
                    <br></br>
                    <button onClick={()=>this.removeImgG(i)}>Elimina</button>
                     </div> 
                    
                  </div>
                    </div>)
        })}
        </div>

        <Dropzone multiple={false} onDrop={acceptedFiles => this.handleUpload(acceptedFiles,true)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div class='drag' {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Trascina immagine galleria homePage</p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>



      <div style={{marginBottom:30, padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
        <h2>Immagini galleria Matrimonio</h2>
        {this.state.copertMatr ? (
          <img style={{width:100}} src={this.state.copertMatr} />
        ) : null}
        <Dropzone multiple={false} onDrop={acceptedFiles => this.handleUploadcopert(acceptedFiles,true)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div class='drag' {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Trascina copertina Matrimonio</p>
              </div>
            </section>
          )}
        </Dropzone>
           <br></br> 
           <br></br> 
        <input style={{border:'1px solid #eee',marginBottom:10,padding:10}} onChange={(x)=>this.setState({titoloMatrim:x.target.value})} value={this.state.titoloMatrim} type='text' placeholder='titolo'></input>
        <br></br>
        <textarea style={{border:'1px solid #eee',marginBottom:10,padding:10,width:'100%'}} onChange={(x)=>this.setState({descMatrim:x.target.value})} value={this.state.descMatrim}  type='text' placeholder='descrixione'></textarea>
        <div style={{display:'grid',gridTemplateColumns:'25% 25% 25% 25%'}}>
          {matrimimgGallery.map((x,i)=>{
            return (<div key={i}><div>
                      <img style={{width:100}} src={x.img} />
                      <div>
                      <input style={{border:'none'}} onChange={(e)=>this.testoImgG(i,e.target.value,true)} value={x.text} type='text' placeholder='testo...'></input>
                      <br></br>
                      <button onClick={()=>this.removeImgGM(i,true)}>Elimina</button>
                      </div> 
                      
                    </div>
                      </div>)
          })}

        </div>
        

        <Dropzone multiple={true} onDrop={acceptedFiles => this.handleUpload(acceptedFiles,false,true)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div class='drag' {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Trascina immagine galleria matrimonio</p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>



      <div style={{marginBottom:30, padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
        <h2>Galleria Immagini</h2>
        {this.state.copertGallery ? (
          <img style={{width:100}} src={this.state.copertGallery} />
        ) : null}
        <Dropzone multiple={false} onDrop={acceptedFiles => this.handleUploadGallery(acceptedFiles,true)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div class='drag' {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Trascina copertina Matrimonio</p>
              </div>
            </section>
          )}
        </Dropzone>
           <br></br> 
           <br></br> 
        <input style={{border:'1px solid #eee',marginBottom:10,padding:10}} onChange={(x)=>this.setState({titoloGalleria:x.target.value})} value={this.state.titoloGalleria} type='text' placeholder='titolo'></input>
        <br></br>
        <textarea style={{border:'1px solid #eee',marginBottom:10,padding:10,width:'100%'}} onChange={(x)=>this.setState({descGalleria:x.target.value})} value={this.state.descGalleria}  type='text' placeholder='descrizione'></textarea>
        <div style={{display:'grid',gridTemplateColumns:'25% 25% 25% 25%'}}>
          {galleria.map((x,i)=>{
            return (<div key={i}><div>
                      <img style={{width:100}} src={x.img} />
                      <div>
                      <input style={{border:'none'}} onChange={(e)=>this.testoImgGia(i,e.target.value)} value={x.text} type='text' placeholder='testo...'></input>
                      <br></br>
                      <button onClick={()=>this.removeImgGia(i,true)}>Elimina</button>
                      </div> 
                      
                    </div>
                      </div>)
          })}

        </div>
        

        <Dropzone multiple={true} onDrop={acceptedFiles => this.handleUploadGallery(acceptedFiles)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div class='drag' {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Trascina immagine galleria matrimonio</p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>


      <div style={{marginBottom:30, padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
        <h2>Sezione eventi</h2>
        {this.state.copertMatr ? (
          <img style={{width:100}} src={this.state.copertEvent} />
        ) : null}
        <Dropzone multiple={false} onDrop={acceptedFiles => this.handleUploadcopert(acceptedFiles)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div class='drag' {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Trascina copertina eventi</p>
              </div>
            </section>
          )}
        </Dropzone>
        <br></br> 
           <br></br>
        <input style={{border:'1px solid #eee',marginBottom:10,padding:10}} onChange={(x)=>this.setState({titoloEvent:x.target.value})} value={this.state.titoloEvent} type='text' placeholder='titolo'></input>
        <br></br>
        <textarea style={{border:'1px solid #eee',marginBottom:10,padding:10,width:'100%'}} onChange={(x)=>this.setState({descEvent:x.target.value})} value={this.state.descEvent}  type='text' placeholder='descrixione'></textarea>
        <div style={{display:'grid',gridTemplateColumns:'25% 25% 25% 25%'}}>
          

        </div>
        
      </div>






        <div style={{marginBottom:30,padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>


        <div style={{overflow: 'scroll hidden',whiteSpace:'nowrap',}}>



          <div style={{marginBottom:50}}>
            <p>Raggio di consegna</p>
            {this.state.objSpediz ? (
              <MapInput value={this.state.objSpediz} radius={true} callback={(x)=>this.setState({objSpediz:x})} />

            ) : null}
          </div>



        <SortableList axis='x' items={gallery} onSortEnd={this.onSortEnd} />
        {/*gallery.map((x,i)=>{
          return (
            <Img style={{width:150}} src={x} loader={<p>pollo</p>}/>)
        })*/}


        </div>
        </div>
        <div style={{marginBottom:30,padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <h3 style={{marginBottom:10}}>Ordine minimo asporto</h3>
          <input class="bp3-input .modifier" modifier type="text" value={minino_asporto} onChange={(x)=>this.setState({minino_asporto:x.target.value})} placeholder="minimo asporto" dir="auto" /> <br></br>
        </div>
        <div style={{marginBottom:30,padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <h3 style={{marginBottom:10}}>Ordine minimo delivery</h3>
          <input class="bp3-input .modifier" modifier type="text" value={minino_delivery} onChange={(x)=>this.setState({minino_delivery:x.target.value})} placeholder="minimo delivery" dir="auto" /> <br></br>
        </div>
        <div style={{marginBottom:30,padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <h3 style={{marginBottom:10}}>Costo spedizione</h3>
          <input class="bp3-input .modifier" modifier type="text" value={prezzo} onChange={(x)=>this.setState({prezzo:x.target.value})} placeholder="prezzo" dir="auto" /> <br></br>
        </div>






          {/*<label class="bp3-control bp3-checkbox .modifier">
            <input type="checkbox" modifier checked={trueVariante} onChange={()=>this.setState({trueVariante:!trueVariante})} />
            <span class="bp3-control-indicator"></span>
            il prodotto ha delle varianti
          </label>

          {trueVariante ? (
            <div style={{marginBottom:'1500px'}}>
              <h1 style={{color:'red'}}>opzione</h1>
                <TagInput
                    onChange={(variante: string[]) => this.setState({ variante })}
                    values={this.state.variante}
                />
            </div>
          ) : null}*/}

        </div>

      </div>




      <div className='x30c' >
        <div style={{marginTop:103,marginBottom:30, padding:30, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <div style={{paddingBottom:'10px'}}>


      <h1>Attivo</h1>
        {/*<button role="link" onClick={()=>this.handleClick()}>
          Checkout d
        </button>*/}

      <p style={{marginBottom:30}}>Seleziona se vuoi che il ristorante sia online</p>
        <label class="bp3-control bp3-checkbox .modifier">
          <input type="checkbox" modifier checked={active} onChange={()=>this.setState({active:!active})}  />
          <span class="bp3-control-indicator"></span>
          Attiva ristorante
        </label>
      <h1>Aperto</h1>
      <p style={{marginBottom:30}}>Seleziona se vuoi che il ristorante sia aperto e possa prendere ordinazioni</p>
        <label class="bp3-control bp3-checkbox .modifier">
          <input type="checkbox" modifier checked={aperto} onChange={()=>this.setState({aperto:!aperto})}  />
          <span class="bp3-control-indicator"></span>
          Attiva ristorante
        </label>
    </div>

   </div>



{delivery ? (
  <div style={{marginTop:10,marginBottom:30, padding:30, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
    <div style={{paddingBottom:'10px'}}>


  <h1>Servizi pagamento delivery</h1>
  <p style={{marginBottom:30}}>Seleziona i servizi che vuoi servire con questo ristorante</p>
  <label class="bp3-control bp3-checkbox .modifier">
    <input type="checkbox" modifier checked={contanti_Alla_consegna} onChange={()=>this.setState({contanti_Alla_consegna:!contanti_Alla_consegna})}  />
    <span class="bp3-control-indicator"></span>
    Pagamento alla consegna / ritiro
  </label>
  <label class="bp3-control bp3-checkbox .modifier">
    <input type="checkbox" modifier checked={payPal} onChange={()=>this.setState({payPal:!payPal})}  />
    <span class="bp3-control-indicator"></span>
    payPal
  </label>
  <label class="bp3-control bp3-checkbox .modifier">
    <input type="checkbox" modifier checked={stripe} onChange={()=>this.setState({stripe:!stripe})}  />
    <span class="bp3-control-indicator"></span>
    Stripe
  </label>
  </div>

  </div>
) : null }



{take_away ? (
  <div style={{marginTop:10,marginBottom:30, padding:30, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
    <div style={{paddingBottom:'10px'}}>


  <h1>Servizi pagamento asporto</h1>
  <p style={{marginBottom:30}}>Seleziona i servizi che vuoi servire con questo ristorante</p>
  <label class="bp3-control bp3-checkbox .modifier">
    <input type="checkbox" modifier checked={contanti_Alla_consegna_takeA} onChange={()=>this.setState({contanti_Alla_consegna_takeA:!contanti_Alla_consegna_takeA})}  />
    <span class="bp3-control-indicator"></span>
    Pagamento alla consegna / ritiro
  </label>
  <label class="bp3-control bp3-checkbox .modifier">
    <input type="checkbox" modifier checked={payPal_takeA} onChange={()=>this.setState({payPal_takeA:!payPal_takeA})}  />
    <span class="bp3-control-indicator"></span>
    payPal
  </label>
  <label class="bp3-control bp3-checkbox .modifier">
    <input type="checkbox" modifier checked={stripe_takeA} onChange={()=>this.setState({stripe_takeA:!stripe_takeA})}  />
    <span class="bp3-control-indicator"></span>
    Stripe
  </label>
  </div>

  </div>
) : null }

   <div style={{marginBottom:30, padding:30, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
   <div style={{marginBottom:''}}>
     <h3  style={{marginTop:0}}>Sitema di gestione</h3>
     {typeFlow == 'whatp' ? (
        <div>
          <h2>Il tuo Piano prevede la gestione ordini tramite whatsapp</h2>
          <p>per cambiare il piano e saperne di più contatta </p>
        </div>

     ) : null}

     {/*<TagInput sty
           onChange={(variante: string[]) => this.setState({ variante })}
           values={this.state.variante}
       />*/}
   </div>
   </div>
      </div>





        </div>
        <div style={{maxWidth:900,marginLeft:'auto',marginRight:'auto'}}>
        <button style={{float:'right',background:'#4a69bd',color:'white',fontWeight:'800',padding:10}} type="button" onClick={()=>this.postProdotto()}class="bp3-button .modifier" modifier>salva</button>
        </div>
        </div>



      )
    }

  }
}

const condition = authUser => {
console.log('mostro auth');
console.log(authUser);
if(authUser){
  if(authUser.roles){

    let indexStaff = authUser.roles.indexOf('STAFF_W')
    let indexAdmin = authUser.roles.indexOf('pollo')
    let okVai = false
    authUser.roles.forEach((item, i) => {
      console.log(item);
      if(item == 'STAFF_W'){
        console.log('qui dovrei dare true');
        okVai = true
      }
    });

    console.log('qui dò un risultato');
    console.log(okVai);
    return okVai


  /*  if(indexStaff || indexAdmin > -1){
      return true;
    }else {
      return false
    }*/
  }else {
    return false
  }

}else {
  return false
}


}

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(NewProd);
