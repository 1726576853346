import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import { Spinner } from "@blueprintjs/core";
import API from "../../util/Api.js"
import {Link} from "react-router-dom";



class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
        loading:true,
        items:[],
    };


  }





  getMyStores(x){
    const ApiTags= new API ({url:'/ristoranti/'+x});
    var entity= {name:"ristoranti"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.ristoranti
      .getAllOk(ppp)
      .then(res=>{

        console.log('mostro dati');
        console.log(res.data);
        newThis.setState({items:res.data,loading:false})

      });
    })

  }

  componentDidMount() {
    this.getMyStores()

  }

  loading(){
    let {loading} = this.state;
    if(loading){
      return 'bp3-skeleton';
    }else {
      return null
    }
  }

render() {
    let {items} = this.state;
    return (
        <div style={{background:'#f9fafb', height:'100%',overflowY: 'auto',padding:10,width:'100%',paddingBottom:50}}>
          <div style={{maxWidth:1050,marginLeft:'auto',marginRight:'auto'}}>
              <h1>Settaggi</h1>
                <div style={{display: "grid",gridTemplateColumns: '250px auto',marginTop:20,gridColumnGap:'5%'}}>
                  <div>
                    <h2>Ristoranti</h2>
                    <p>qui puoi gestire i settaggi dei tuoi ristoranti</p>
                  </div>
                  <div>
                    {items.map((x,i)=>{
                      return(
                        <Link to={{
                           pathname: '/ristorantesettingadmin', state: {
                           id: x._id}}
                         }>
                        <div style={{background:'white',padding:20,borderRadius:8,maxWidth:500}} key={i}>
                          <div style={{display:'flex'}}>
                            <div style={{width:100}}>
                              <img style={{width:'100%'}} src={x.img} />
                            </div>
                            <div>
                              <strong>{x.nome}</strong>
                              <p>{x.indirizzoCompleto}</p>
                            </div>
                          </div>
                        </div>
                        </Link>
                      )
                    })}
                  </div>
                </div>
            </div>
          </div>
      )
  }
}

const condition = authUser => {
console.log('mostro auth');
console.log(authUser);
if(authUser){
  if(authUser.roles){
    let indexStaff = authUser.roles.indexOf('ADMIN_S')
    if(indexStaff > -1){
      return true;
    }else {
      return false
    }
  }else {
    return false
  }

}else {
  return false
}


}

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(HomePage);
