import React, {Component} from 'react';
import {compose} from 'recompose';
import {withAuthorization, withEmailVerification} from '../Session';
import {withFirebase} from '../Firebase';
import API from "../../util/Api.js"
import {Link} from "react-router-dom";
import Scontrino from "./scontrino.png"
import Noimg from "./no-img.jpg";
import Img from 'react-image'

import {
  Button,
  Classes,
  Code,
  Divider,
  H5,
  HTMLSelect,
  IOptionProps,
  Label,
  Position,
  Switch,
  Drawer
} from "@blueprintjs/core";
import {NumericInput} from "@blueprintjs/core";

class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      prodotto: [],
      riepilogo: [],
      isOpen: false,
      isOpen: false,
      oggetto: null,
      listaOrdine: null,
      productOrder: [],
      valueSingleProd: {},
      value: {},
      id_ordine: null,
      totaleOrdine: null

    };

  }

  getXXXX() {
    //usa per il get
    const ApiTags = new API({url: '/prodotto'});
    var entity = {
      name: "prodotto"
    };
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {
        'Authorization': 'Bearer ' + id
      };
      ApiTags.endpoints.prodotto.getAllParam({
        alias: newThis.state.name
      }, ppp).then(res => {

        newThis.setState({prodotto: res.data})
        console.log('ciaooo');
        console.log(newThis.state.prodotto);
      });
    })
  }

  add_Prod(value, id, variante, ref_id) {
    let statoValore = this.state.valueSingleProd;
    console.log(value);
    console.log(ref_id);
    statoValore[id] = {
      quantita: value,
      prod_ref: ref_id,
      id_prodVar: id,
      nomeVariante: variante
    };

    this.setState({
      valueSingleProd: statoValore
    }, () => {
      console.log('qui ci provo');
      console.log(this.state.valueSingleProd)

      //this.postQuantitaOrdine(value, id, variante)
    });

  }

  add_ProdOld(value, id, variante, ref_id) {
    let statoValore = this.state.value;
    console.log('mostro stato valore');
    console.log(statoValore);
    console.log('mostro v ');
    console.log('ciollibustok' + value);
    statoValore[id] = value;
    this.setState({
      value: statoValore
    }, () => {
      console.log(this.state.value)
      console.log(id);

      //this.postQuantitaOrdine(value, id, variante)
    });

  }

  bridgOrderValue(a) {
    let {value} = this.state;
    a.forEach((item, i) => {
      value[item.id_prodVar] = item.quantita
    });
    this.setState({value})

  }

  getAllProdutOrder() {
    console.log('facciiiiiioooo');
    const myAPI = new API({url: "/prod_var_ords"});
    var entity = {
      name: "prod_var_ords"
    };
    myAPI.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //alert('Sei il benvenuto! '+newThis.props.match.params.id);

      var ppp = {
        'Authorization': 'Bearer ' + id
      };

      myAPI.endpoints.prod_var_ords.getOne({
        id: newThis.props.match.params.id
      }, ppp).then(res => {

        const productOrder = res.data;

        console.log('mostro i i dati singoli:');
        console.log(productOrder);
        console.log('totalizzante');
        var totaleOrdine=0;
        productOrder.map((items, i) => {
          totaleOrdine=totaleOrdine+items.prezzoTot

        })
        console.log(totaleOrdine);
        newThis.setState({totaleOrdine:totaleOrdine})
        newThis.setState({
          productOrder,
          loading: false,
          riepilogo: res.data
        }, () => {
          newThis.bridgOrderValue(productOrder)
        })

      });
    });
  }

  aggiungi(prezzo) {
    let {valueSingleProd} = this.state;
    for (const property in valueSingleProd) {
      console.log(`${property}`);
      console.log(valueSingleProd[property]);
      this.updateProductOrder(valueSingleProd[property], prezzo)
    }

    this.setState({
      valueSingleProd: {},
      isOpen: false,
      loading: true
    }, () => {})

  }

  updateProductOrder(x, prezzo) {
    let {lavoro} = this.state;

    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {
        'Authorization': 'Bearer ' + id
      };
      const productOrder = new API({url: '/prod_var_ord'});
      var entity = {
        name: "prod_var_ord"
      };

      productOrder.createEntity(entity);

      let newL = lavoro;
      console.log('nuovo l: ' + newL);
      let obj = {

        id_prodVar: x.id_prodVar,
        nomeVariante: x.nomeVariante,
        prod_ref: x.prod_ref,
        quantita: x.quantita,
        ordine_ref: newThis.props.match.params.id,
        prezzoTot: x.quantita * prezzo

      }

      productOrder.endpoints.prod_var_ord.update(obj, ppp).then(res => {

        newThis.getAllProdutOrder();
        //alert('Modifiche salvate')

      });
    })

  }


  postProdOrdine(){

    console.log('proviamo a vedere');
    console.log(this.state);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/ordine'});
      var entity= {name:"ordine"};

      Item.createEntity(entity);


      var obj = {
        id:newThis.props.match.params.id,
        lista_Prodotti:newThis.state.productOrder,
        prezzoTotale:newThis.state.totaleOrdine

      }


      console.log('mostra');
      console.log(obj);
      Item.endpoints.ordine
      .update(obj,ppp)
      .then(res=>{

console.log(res.data);
      });
    })





  }

  componentDidMount() {
    this.getXXXX()
    this.getAllProdutOrder()
    console.log('kappa');
    console.log(window.location.href);
    let ordine_id_url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    console.log(ordine_id_url);
    this.setState({id_ordine: ordine_id_url})

  }

  ckVarisnt(varianti, id) {
    console.log('parte fun per mostrare numero rosso di quantita');
    let {value} = this.state;
    let valoreStam = null
    if (varianti.length != 0) {
      varianti.forEach((item, i) => {
        let idObj = id + item;
        let exist = value[idObj];
        if (exist) {
          valoreStam = valoreStam + exist;
          //valoreStam[item] = exist;
        }

      });

    } else {
      let idObj = id + 'default';
      let exist = value[idObj];
      valoreStam = valoreStam + exist;
    }

    return valoreStam

  }
  render() {
    let {
      items,
      prodotto,
      isOpen,
      isOpenRiepilog,
      oggetto,
      value,
      productOrder,
      riepilogo
    } = this.state;
    return (<div style={{
        height: '100%',
        overflowY: 'auto'
      }}>
      <Drawer style={{
          overflow: 'auto',
          padding: 20
        }} isOpen={isOpen} onClose={() => this.setState({isOpen: false})} title={oggetto
          ? oggetto.nome + ' ' + oggetto.cod_prod
          : ''} position={Position.BOTTOM} size={Drawer.SIZE_LARGE = '70%'}>

        <div style={{}}>
          <div style={{
              overflow: 'scroll hidden',
              whiteSpace: 'nowrap'
            }}>
            {
              oggetto
                ? (oggetto.gallery.map((img, i) => {
                  return <Img style={{
                      width: 150,
                      margin: 20
                    }} src={img} loader={<p> carica</p>}/>
                }))
                : null
            }
          </div>

          <p>{
              oggetto
                ? oggetto.descrizione
                : ''
            }</p>
        </div>

        {
          oggetto
            ? (<div>
              {
                oggetto.variante.length > 0
                  ? (oggetto.variante.map((x, i) => {
                    return (<div style={{
                        display: 'grid',
                        gridTemplateColumns: '10% 80% 10%'
                      }}>
                      <h3>{x}</h3>
                      <NumericInput min='0' placeholder='0' onValueChange={(z) => this.add_Prod(z, oggetto._id + x, x, oggetto._id)} value={this.state.value[oggetto._id + x]}/>
                      <p>{oggetto.prezzo}€</p>
                      <p>spendi {oggetto.prezzo * this.state.value[oggetto._id + x]}</p>
                    </div>)
                  }))

                  : <div style={{
                        display: ''
                      }}>
                      <h3>bella</h3>
                      <NumericInput min='0' placeholder='0' onValueChange={(x) => this.add_Prod(x, oggetto._id + 'default', 'default', oggetto._id)} value={this.state.value[oggetto._id + 'default']}/>
                      <p>{oggetto.prezzo}€</p>
                      <p>spendi {oggetto.prezzo * this.state.value[oggetto._id + 'default']}</p>
                    </div>

              }
            </div>)
            : null
        }

        <button type="button" onClick={() => this.aggiungi(oggetto.prezzo)} style={{
            backgroundColor: '#1E93E4',
            color: 'white',
            border: 'none',
            borderRadius: '100px',
            height: '50px',
            width: '50%',
            fontSize: '20px',
            fontWeight: 'bolder'
          }} modifier="modifier">aggiungi</button>

      </Drawer>

      <Drawer style={{
          overflowY: 'scroll',
          padding: 20
        }} isOpen={isOpenRiepilog} icon="info-sign" onClose={() => this.setState({isOpenRiepilog: false})} title='Riepilogo' position={Position.RIGHT} size={Drawer.SIZE_LARGE = '70%'}>

        {
          productOrder.map((x, i) => {
            console.log('sullato');
            console.log(x);
            return (<div style={{
                position: 'relative',
                display: 'flex',
                marginBottom: '0px',
                borderBottom: '2px solid gray',
                backgroundColor: 'white'
              }}>
              <Img style={{
                  width: 80,
                  height: 80,
                  margin: 20,
                  borderRadius: '100%'
                }} src={x.prod_ref.gallery.length > 0
                  ? x.prod_ref.gallery[0]
                  : Noimg} loader={<p> carica</p>}/>

                <div onClick={() => console.log(x)}>

                <h3>{x.prod_ref.nome}

                  {x.nomeVariante!='default'?' - '+x.nomeVariante:''}
                  </h3>
                <div style={{
                    display: 'flex'
                  }}>
                  <h3>{x.prod_ref.prezzo}€ cad.</h3>
                  <p style={{
                      backgroundColor: 'white',
                      width: '40px',
                      border: '1px solid gray',
                      borderRadius: '50px',
                      textAlign: 'center',
                      margin: '20px'
                    }}>{x.quantita}</p>
                  <h3>totale: {x.prezzoTot}€</h3>
                </div>

              </div>
            </div>)

          })
        }

        <h3>Totale {
            this.state.totaleOrdine
              ? <h3>{this.state.totaleOrdine}€</h3>
              : ''
          }</h3>
        <button type="button" onClick={() => this.postProdOrdine()} style={{
              backgroundColor: '#1E93E4',
              color: 'white',
              border: 'none',
              borderRadius: '100px',
              height: '50px',
              width: '50%',
              fontSize: '20px',
              fontWeight: 'bolder'
            }} modifier="modifier">aggiorna ordine</button>

      </Drawer>

      <div style={{
          height: '100%',
          overflowY: 'auto',
          padding: 0,
          marginBottom: '160px',
          backgroundColor: '#FCE158'
        }}>
        <h1 style={{
            textAlign: 'center',
            fontSize: 25,
            fontWeight: '800',
            marginBottom: 100,
            color: 'black',
            fontFamily: 'Oswald'
          }}>
          Crea ordine</h1>

        <div class="bp3-input-group .modifier" style={{
            width: '200px',
            marginBottom: '50px',
            marginRight: 'auto',
            marginLeft: 'auto'
          }}>
          <span class="bp3-icon bp3-icon-search"></span>
          <input class="bp3-input" modifier="modifier" type="search" placeholder="Search input" dir="auto"/>
          <img style={{
              position: 'fixed',
              top: '10px',
              right: '10px'
            }} onClick={() => {
              this.setState({isOpenRiepilog: true});
              this.getAllProdutOrder()
            }} src={Scontrino}/>

        </div>
        {
          this.state.loading
            ? <p>...</p>
            : null
        }
        <div style={{
            borderTop: '1px solid gray'
          }}>
          {
            prodotto.map((x, i) => {
              console.log(x);
              return (<div style={{
                  position: 'relative',
                  display: 'flex',
                  marginBottom: '0px',
                  borderBottom: '2px solid gray',
                  backgroundColor: 'white'
                }}>
                <Img style={{
                    width: 150,
                    margin: 20
                  }} src={x.gallery.length > 0
                    ? x.gallery[0]
                    : Noimg} loader={<p> carica</p>}/>

                <div onClick={() => this.setState({isOpen: true, oggetto: x})}>

                  <h3>{x.nome}</h3>
                  <p class='trunche' style={{
                      margin: 10,
                      fontSize: 'smaller'
                    }}>{x.descrizione}</p>
                  <p class='priceList'>{x.prezzo}€</p>
                  <p>{
                      x.variante.length > 0
                        ? '+ Varianti'
                        : null
                    }</p>

                  {
                    this.ckVarisnt(x.variante, x._id)
                      ? <div>
                          <p class='conter' style={{}}>{this.ckVarisnt(x.variante, x._id)}</p>
                          <p class='' style={{
                              color: 'red'
                            }}>{
                              'totale: ' + (
                              this.ckVarisnt(x.variante, x._id)) * x.prezzo + '€'
                            }</p>
                        </div>
                      : null
                  }

                </div>
              </div>)
            })
          }
        </div>

      </div>
    </div>)
  }
}

const condition = authUser => !!authUser;

export default compose(withFirebase, withEmailVerification, withAuthorization(condition),)(HomePage);
