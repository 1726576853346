import React from 'react';
import { Button, ButtonGroup,Drawer } from "@blueprintjs/core"
import { IExampleProps } from "@blueprintjs/docs-theme";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';

class Liste extends React.PureComponent<IExampleProps, IButtonGroupExampleState> {

  constructor(props) {
    super(props);
    this.state = {
      open:false
    };
  }




  render() {
    return (
      <div className='listtt'>
      <Drawer
          position='left'
          size='80%'
          isOpen={this.state.open}
          onClose={()=>this.setState({open:false})}
      >
      qui
      </Drawer>

      <ButtonGroup style={{padding:10 }} minimal={true} vertical={true} alignText={'left'}>

          {/*<input style={{padding: 8, borderRadius: 11, background: '#2a3344', border: 'none',width:'90%'}} type="text" placeholder='ricerca' onClick={()=>this.setState({open:true})} />*/}

        <Link to="/">
          <Button style={{fontWeight: 600}}>Home</Button></Link>
        <Link to="/menus">
          <Button style={{fontWeight: 600}}>Menus</Button></Link>
        <Link to="/piatti">
          <Button style={{fontWeight: 600}}>Piatti</Button></Link>
        <Link to="/post">
        <Button style={{fontWeight: 600}}>Post</Button></Link>
        {/*<Link to="/categorie">
          <Button style={{fontWeight: 600}}>categorie</Button></Link>*/}
        <Link to="/clienti">
          <Button style={{fontWeight: 600}}>clienti</Button></Link>
        <Link to="/ordini">
          <Button style={{fontWeight: 600}} >ordini</Button></Link>
        <Link to="/prenotazioni">
          <Button style={{fontWeight: 600}} >prenotazioni</Button></Link>

      </ButtonGroup>
      <ButtonGroup style={{padding:10 }} minimal={true} vertical={true} alignText={'left'}>
        <h3 style={{marginBottom:10}}>Canale di vendita</h3>
          <Link to="/setting">
              <Button style={{fontWeight: 600}} >Settings</Button>
          </Link>
      </ButtonGroup>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
)(Liste);
//export default Liste;
