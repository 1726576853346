import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import Dropzone from 'react-dropzone'
import firebase from 'firebase';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import Img from 'react-image'
import {Link , Redirect} from "react-router-dom";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {
    Button,
    H5,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Position,
    Spinner,
    Switch,
    Tag,
    Tooltip,
    TagInput
} from "@blueprintjs/core";
import { Select , ItemRenderer } from "@blueprintjs/select";
import { BrowserRouter as Router, Route } from "react-router-dom";


const SortableItem = SortableElement(({value, sortIndex}) => (
  <div style={{width:150}}>
      <Img style={{width:150}} src={value} loader={<p>pollo</p>}/>
  </div>
));

const SortableList = SortableContainer(({items}) => {
return (
  <div style={{overflow: 'scroll hidden',whiteSpace:'nowrap',display:'flex'}}>
    {items.map((value, index) => (
      <SortableItem key={`item-${value}`} index={index} value={value} />
    ))}
  </div>
);
});

class NewProd extends Component {

  constructor(props) {
    super(props);
    //this.getXXXX()
    this.state = {
      ristoranti:[],
      ristorantiSel:[],
      categorie:[],
      categorieSel:[],
      img:null,
      rifOrganiz:null,
      clienti:[],
      nome:null,
      cod_prod:null,
      descrizione:null,
      prezzo:0,
      variante:[],
      trueVariante:false,
      continuativo:false,
      listax:[{id:'pppp',alias:'pollo'},{id:'yo',alias:'yo'},{id:'suc',alias:'suc'}],
      listaArtigiani:[],
      listaStagione:[],
      gallery:[],
      artigiani:{},
      provaArt:{},
      collezione:null,
      redirect:false,
      valori:null,
      progress:null,
      vegano:null,
      vegetariano:null,
      noGlutine:null,
      biologico:null,
      piccante:null,
      codAllerg:null,
    };


  }

  getMyOrganiz(x){
    const ApiTags= new API ({url:'/organizazioneadmin/'});
    var entity= {name:"organizazioneadmin"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.organizazioneadmin
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log(obj.mongoId);
        console.log('mostro dati organ');
        console.log(res.data._id);
        newThis.setState({rifOrganiz:res.data._id})
        newThis.getXXXX(res.data._id)
      });
    })

  }


  getMyStores(x){
    const ApiTags= new API ({url:'/ristorantiadmins/'+x});
    var entity= {name:"ristorantiadmins"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.ristorantiadmins
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati');
        console.log(res.data);
        newThis.setState({ristoranti:res.data})

      });
    })

  }

  addImg(xxx){


    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/prodotto'});
      var entity= {name:"prodotto"};

      Item.createEntity(entity);


      var obj = {
        id:newThis.props.location.state.id,
        gallery:[xxx]


      }

      Item.endpoints.prodotto
      .update(obj,ppp)
      .then(res=>{

      });
    })



  }

  handleUpload = (images) => {
      let img = this.state.img;
      var storage = firebase.storage();
      let newThis = this;
      //const image = images[0];
      images.forEach((image, i) => {
        const uploadTask = storage.ref(`images/${image.name}`).put(image);
        uploadTask.on(
          "state_changed",
          snapshot => {
            // progress function ...
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            this.setState({ progress });
          },
          error => {
            // Error function ...
            console.log(error);
          },
          () => {
            // complete function ...
            storage
              .ref("images")
              .child(image.name)
              .getDownloadURL()
              .then(url => {
                console.log('qui finitoooooo');
                console.log(url);
                img = url;

                newThis.setState({img})
                //this.setState({ url });
              });
          }
        );
      });



    };




  getValori(x){
    console.log('gang'+x);

    const ApiTags= new API ({url:'/prodotto/'+x});
    var entity= {name:"prodotto"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.prodotto
      .getOne({id:x},ppp)
      .then(res=>{
        console.log('qui vloreee');
        console.log(res.data);
        newThis.setState({valori:res.data})
        console.log('gang');
        console.log(newThis.state.valori);
        console.log('suju');

        newThis.setState({gallery:newThis.state.valori.gallery});
        newThis.setState({nome:newThis.state.valori.nome})
        newThis.setState({cod_prod:newThis.state.valori.cod_prod})
        newThis.setState({descrizione:newThis.state.valori.descrizione})
        newThis.setState({prezzo:newThis.state.valori.prezzo})
        newThis.setState({variante:newThis.state.valori.variante})


      });
    })

  }




  getArtigiani(){
    //usa per il get
    const ApiTags= new API ({url:'/artigiano'});
    var entity= {name:"artigiano"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.artigiano
      .getAllParam({alias:newThis.state.name},ppp)
      .then(res=>{

        newThis.setState({listaArtigiani:res.data})
        console.log('brouos');
        console.log(newThis.state.listaArtigiani);
      });
    })


  }

  getStagione(){
    //usa per il get
    const ApiTags= new API ({url:'/stagione'});
    var entity= {name:"stagione"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.stagione
      .getAllParam({alias:newThis.state.name},ppp)
      .then(res=>{
        newThis.setState({listaStagione:res.data})
        console.log('brouos');
        console.log(newThis.state.listaStagione);
      });
    })
  }

  updateGalleryProdotto(array){


    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/prodotto'});
      var entity= {name:"prodotto"};

      Item.createEntity(entity);

      console.log('booooo');




      var obj = {
        id:newThis.props.location.state.id,
        gallery:array,



      }


      Item.endpoints.prodotto
      .update(obj,ppp)
      .then(res=>{

      //console.log(array);
      //alert('salvato')
    //newThis.setState({redirect:true})
      });
    })



  }


  postProdotto(){

    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/posts'});
      var entity= {name:"posts"};


      console.log();
      Item.createEntity(entity);


      var obj = {
        nome: newThis.state.nome,
        img: newThis.state.img,
        desc:newThis.state.descrizione,
        rifRistor:newThis.state.ristorantiSel,
        rifOrganiz:newThis.state.rifOrganiz,
        categorie:newThis.state.categorieSel,
        prezzo:newThis.state.prezzo,
        vegano:newThis.state.vegano,
        vegetariano:newThis.state.vegetariano,
        noGlutine:newThis.state.noGlutine,
        biologico:newThis.state.biologico,
        piccante:newThis.state.piccante,
        codAllerg:newThis.state.codAllerg,
      }



      console.log('mostra');
      console.log(obj);
      Item.endpoints.posts
      .create(obj,ppp)
      .then(res=>{

      newThis.setState({redirect:true})
        });

    })



  }


  _renderSelect2(item){
    //console.log('scoreggia');
    //console.log(item);
    //console.log(handleClick);
    return(
      <MenuItem
        key={item._id}
        label={item.nome}
        text={item.nome}
        onClick={()=>this.setState({collezione:item})}

      />
    )
  }


  getPar(){


    const ApiTags= new API ({url:'/lavori'});
    var entity= {name:"lavori"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.lavori
      .getAllOk(ppp)
      .then(res=>{
        //  console.log(res.data);
        });
    })
  }


  componentDidMount() {

    console.log('eskerin');

    this.getMyOrganiz();
    this.getMyStores();


  }


  getXXXX(x){
    //usa per il get
    const ApiTags= new API ({url:'/categoriabyorg'});
    var entity= {name:"categoriabyorg"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.categoriabyorg
      .getOne({id:x},ppp)
      .then(res=>{

        newThis.setState({categorie:res.data})
        console.log('ciaooo');
        console.log(newThis.state.prodotto);
      });
    })
  }


  gestioneArtigiani(x){

    let {ristorantiSel} = this.state;
    let index = ristorantiSel.indexOf(x)
    if(index > -1){
      ristorantiSel.splice(index, 1);
    }else {
      ristorantiSel.push(x)
    }


    this.setState({ristorantiSel})

  }

  gestioneCategorie(x){

    let {categorieSel} = this.state;
    let index = categorieSel.indexOf(x)
    if(index > -1){
      categorieSel.splice(index, 1);
    }else {
      categorieSel.push(x)
    }


    this.setState({categorieSel})

  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({gallery}) => ({
      gallery: arrayMove(gallery, oldIndex, newIndex),
    }));
  };

render() {
    let {codAllerg,vegano,vegetariano,noGlutine,biologico,piccante,img,categorie, ristoranti,gallery,redirect,clienti,nome,cod_prod,descrizione,prezzo,valori, trueVariante,continuativo,listax, listaArtigiani,listaStagione,artigiani} = this.state;
    if(redirect){
      return <Redirect to="/post" />
    }else {
      return (
        <div style={{background:'#f9fafb', height:'100%',overflowY: 'auto',padding:10,width:'100%',paddingBottom:50}}>
        <div className='flex'>
      <div className='x70c' >
      <Link to={{
        pathname: '/post'}
      }><p style={{marginTop:10,cursor:'pointer',color:'#6d6d6d',fontWeight:'600'}}>{"< post"}</p>
      </Link>
        <h1>{nome ? nome : '_'}</h1>

        <div>
        <div style={{marginBottom:30, padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <div style={{}}>
          <p>Nome</p>
          <input style={{width:'100%',marginBottom:20}} class="bp3-input .modifier" value={nome}onChange={(x)=>this.setState({nome:x.target.value})} modifier type="text" placeholder="nome" dir="auto" /> <br></br>
          <p>Desc</p>
          <textarea style={{width:'100%',marginBottom:20,height: 200 }} class="bp3-input .modifier" value={descrizione}onChange={(x)=>this.setState({descrizione:x.target.value})} modifier placeholder="descrizione" dir="auto"></textarea><br></br>
            

        </div></div>
        <div style={{marginBottom:30,padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
        <div style={{overflow: 'scroll hidden',whiteSpace:'nowrap',}}>

        <SortableList axis='x' items={gallery} onSortEnd={this.onSortEnd} />
        {/*gallery.map((x,i)=>{
          return (
            <Img style={{width:150}} src={x} loader={<p>pollo</p>}/>)
        })*/}

        {img ? (
          <img style={{width:100}} src={img} />
        ) : null}

        <Dropzone multiple={false} onDrop={acceptedFiles => this.handleUpload(acceptedFiles)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div class='drag' {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Trascina immagini del prodotto</p>
              </div>
            </section>
          )}
        </Dropzone>
        </div>
        </div>
        {/*<div style={{marginBottom:30,padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <h3>Prezzi</h3>
          <p>Prezzo</p>
          <input class="bp3-input .modifier" modifier type="text" value={prezzo} onChange={(x)=>this.setState({prezzo:x.target.value})} placeholder="prezzo" dir="auto" /> <br></br>
        </div>*/}






          {/*<label class="bp3-control bp3-checkbox .modifier">
            <input type="checkbox" modifier checked={trueVariante} onChange={()=>this.setState({trueVariante:!trueVariante})} />
            <span class="bp3-control-indicator"></span>
            il prodotto ha delle varianti
          </label>

          {trueVariante ? (
            <div style={{marginBottom:'1500px'}}>
              <h1 style={{color:'red'}}>opzione</h1>
                <TagInput
                    onChange={(variante: string[]) => this.setState({ variante })}
                    values={this.state.variante}
                />
            </div>
          ) : null}*/}

        </div>

      </div>




      <div className='x30c'>
        <div style={{marginTop:103,marginBottom:30, padding:30, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          {/*<div style={{paddingBottom:'10px'}}>


      <h1>Ristoranti</h1>
      <p style={{marginBottom:30}}>Seleziona i ristoranti in cui verra pubblicato queto piatto</p>
      {ristoranti.map((a,i)=>{
        return (
          <label class="bp3-control bp3-checkbox .modifier">
            <input type="checkbox" modifier checked={ristoranti[a._id]} onChange={()=>this.gestioneArtigiani(a._id)} />
            <span class="bp3-control-indicator"></span>
            {a.nome}
          </label>
        )
      })}
    </div>*/}

   </div>

   <div style={{marginTop:10,marginBottom:30, padding:30, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
     {/*<div style={{paddingBottom:'10px'}}>


        <h1>Etichette</h1>
        <p style={{marginBottom:30}}>Seleziona i ristoranti in cui verra pubblicato queto piatto</p>
          <label class="bp3-control bp3-checkbox .modifier">
            <input name='vegetariano' type="checkbox" modifier checked={vegetariano} onChange={(x)=>this.setState({[x.target.name]:!this.state[x.target.name]})} />
            <span class="bp3-control-indicator"></span>
            Vegetariano
          </label>

          <label class="bp3-control bp3-checkbox .modifier">
            <input name='vegano' type="checkbox" modifier checked={vegano} onChange={(x)=>this.setState({[x.target.name]:!this.state[x.target.name]})} />
            <span class="bp3-control-indicator"></span>
            Vegano
          </label>

          <label class="bp3-control bp3-checkbox .modifier">
            <input name='noGlutine' type="checkbox" modifier checked={noGlutine} onChange={(x)=>this.setState({[x.target.name]:!this.state[x.target.name]})} />
            <span class="bp3-control-indicator"></span>
            noGlutine
          </label>

          <label class="bp3-control bp3-checkbox .modifier">
            <input name='biologico' type="checkbox" modifier checked={biologico} onChange={(x)=>this.setState({[x.target.name]:!this.state[x.target.name]})} />
            <span class="bp3-control-indicator"></span>
            biologico
          </label>

          <label class="bp3-control bp3-checkbox .modifier">
            <input name='piccante' type="checkbox" modifier checked={piccante} onChange={(x)=>this.setState({[x.target.name]:!this.state[x.target.name]})} />
            <span class="bp3-control-indicator"></span>
            piccante
          </label>
        </div>*/}

</div>
   <div style={{marginBottom:30, padding:30, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
   {/*<div style={{marginBottom:''}}>
     <h3  style={{marginTop:0}}>Varianti</h3>
     <TagInput sty
           onChange={(variante: string[]) => this.setState({ variante })}
           values={this.state.variante}
       />
   </div>*/}
   </div>
      </div>





        </div>
        <div style={{maxWidth:900,marginLeft:'auto',marginRight:'auto'}}>
        <button style={{float:'right',background:'#4a69bd',color:'white',fontWeight:'800',padding:10}} type="button" onClick={()=>this.postProdotto()}class="bp3-button .modifier" modifier>salva</button>
        </div>
        </div>



      )
    }

  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(NewProd);
