import React from 'react';
import { Button, ButtonGroup } from "@blueprintjs/core"
import { Icon } from "@blueprintjs/core";
import { FaHome, FaDraftingCompass } from "react-icons/fa";
import { TiHomeOutline } from "react-icons/ti";
import { IExampleProps } from "@blueprintjs/docs-theme";
import {Link} from "react-router-dom";
import HomeIcon from  "../../images/tent.svg"

class Menu extends React.PureComponent<IExampleProps, IButtonGroupExampleState> {

  constructor(props) {
    super(props);
    this.state = {};
  }




  render() {
    return (
      <div>
      <ButtonGroup style={{ minWidth: 200,marginTop:40,padding:10 }} minimal={true} large={true} vertical={true} alignText={'left'}>
          <Button style={{color:'white',fontWeight: 600,borderLeft: '3px solid'}}><span style={{marginLeft:10}}><Link to="/">Home</Link></span></Button>
          <Button style={{color:'white',fontWeight: 600,borderLeft: '6px solid transparent'}}><span style={{marginLeft:10}}><Link to="/clienti">Clienti</Link></span></Button>
          <Button style={{color:'white',fontWeight: 600,borderLeft: '6px solid transparent'}}><span style={{marginLeft:10}}><Link to="/categorie">Categorie interne</Link></span></Button>

      </ButtonGroup>
      </div>
    );
  }
}


export default Menu;
