import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import {Link} from "react-router-dom";
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import '../Prodotti/fix.css'
import {
    Button,
    H5,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Position,
    Spinner,
    Switch,
    Tag,
    Tooltip,
} from "@blueprintjs/core";


const columns = [{Header: () => (
   <p style={{height:30,fontWeight:'800'}}>
       Nome
   </p>
 ),
accessor: 'nome', // String-based value accessors!
Cell: props => <p style={{textAlign: 'center',paddingTop: 31,fontWeight: 300}}>{(props.value ? props.value : 'no' )}</p> // Custom cell components!
},{
   Header: () => (
      <p style={{height:30,fontWeight:'800'}}>
          Email
      </p>
    ),
   accessor: 'email', // String-based value accessors!
  Cell: props => <p style={{textAlign: 'center',paddingTop: 31,fontWeight: 800}}>{(props.value ? props.value : 'no' )}</p> // Custom cell components!
},{
   Header: () => (
      <p style={{height:30,fontWeight:'800'}}>
          telefono
      </p>
    ),
   accessor: 'telefono',
   Cell: props => <p style={{textAlign: 'center',paddingTop: 31,fontWeight: 300}}>{(props.value ? '+'+props.value : 'no' )}</p> // Custom cell components!
 }, {
   accessor: '_id',
   Cell: props => <div style={{textAlign:'center'}}><Link to={{
      pathname: '/modificacliente', state: {
      id: props.value}}
      }>
   <button style={{marginTop:20,background:'#4a69bd',color:'white',fontWeight:'800',}} type="button" class="bp3-button .modifier" modifier>modifica</button>
   </Link>
   </div>
 }]


class HomePage extends Component {

  constructor(props) {
    super(props);
    //this.getXXXX()
    this.state = {
      clienti:[],
      rifOrganiz:null
    };


  }


  getMyOrganiz(){
    const ApiTags= new API ({url:'/organizazioneadmin/'});
    var entity= {name:"organizazioneadmin"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.organizazioneadmin
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati organ');
        console.log(res.data._id);
        newThis.setState({rifOrganiz:res.data._id})
        newThis.getXXXX(res.data._id);
      });
    })

  }

  getXXXX(idR){
    //usa per il get
    console.log('id rist');
    console.log(idR);
    const ApiTags= new API ({url:'/clienteburist'});
    var entity= {name:"clienteburist"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.clienteburist
      .getOne({id:idR},ppp)
      .then(res=>{

        newThis.setState({clienti:res.data})
        console.log('ciaooo');
        console.log(newThis.state.clienti);
      });
    })
  }

  preCancella(x){
    var txt;
    var r = window.confirm("Sei sicuro di cancellare il cliente "+x+"?");
    if (r == true) {
    this.cancella(x)
  } else {
  txt = "You pressed Cancel!";
 }
  }

  cancella(x){
    console.log('gang'+x);

    const ApiTags= new API ({url:'/cliente/'+x});
    var entity= {name:"cliente"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.cliente
      .delete({id:x},ppp)
      .then(res=>{

        newThis.getXXXX()
      });
    })


  }




  componentDidMount() {
    this.getMyOrganiz()

  }

  search(x){

      var newThis = this;
      this.props.firebase.tokenForApi(function(id) {
        //console.log('Bearer '+JSON.parse(token).idToken);
        var ppp = {'Authorization' : 'Bearer '+id};
        const Item= new API ({url:'/searchcliente'});
        var entity= {name:"searchcliente"};

        Item.createEntity(entity);

        console.log('booooo');

        var obj = {
          text:  x,
          org: newThis.state.rifOrganiz,
        }

        Item.endpoints.searchcliente
        .create(obj,ppp)
        .then(res=>{

          newThis.setState({clienti:res.data})
        });
      })

  }

render() {
    let {items,clienti} = this.state;
    return (
      <div style={{height:'100%',overflowY: 'auto',padding:10,background:'#f9fafb'}}>
        <div style={{maxWidth:1050,marginLeft:'auto',marginRight:'auto'}}>
        <h1>Clienti</h1>
        <div style={{width:'100%',marginBottom:20,height: 31}}>
          {/*<Link to="/newcliente"><button style={{background:'#4a69bd',color:'white',fontWeight:'800',float:'right',padding:10}} type="button" class="bp3-button .modifier" modifier>Aggiungi cliente</button>
          </Link>*/}
        </div>
        <div class="bp3-input-group .modifier" style={{width:'200px',marginBottom:'50px'}}>
          <span class="bp3-icon bp3-icon-search"></span>
          <input onChange={(x)=>this.search(x.target.value)} class="bp3-input" modifier type="search" placeholder="Cerca cliente" dir="auto" />
        </div>


          <ReactTable
          getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: (e, handleOriginal) => {
                  console.log('A Td Element was clicked!')
                  console.log('it produced this event:', e)
                  console.log('It was in this column:', column)
                  console.log('It was in this row:', rowInfo.row._id)
                  console.log('It was in this table instance:', instance)

                  // IMPORTANT! React-Table uses onClick internally to trigger
                  // events like expanding SubComponents and pivots.
                  // By default a custom 'onClick' handler will override this functionality.
                  // If you want to fire the original onClick handler, call the
                  // 'handleOriginal' function.
                  if (handleOriginal) {
                    handleOriginal()
                  }
                }
              }
            }}
            style={{marginTop:20}}
            className='pollo'
            defaultPageSize={10}
            style={{background:'var(--p-surface,#fff)',borderRadius:5,padding:10}}
            data={clienti}
            columns={columns}
          />

          {/*<div class="bp3-input-group .modifier" style={{width:'200px',marginBottom:'50px'}}>
            <span class="bp3-icon bp3-icon-search"></span>
            <input class="bp3-input" modifier type="search" placeholder="Search input" dir="auto" />
          </div>*/}
        {/*clienti.map((x,i)=>{
          return (
          <div style={{borderBottom:'10px solid blue',height:'auto',display:'flex',marginBottom:'10px'}}>
          <div style={{display:'block'}}>
          <h3>{x.societa}</h3>
          <p>{x._id}</p>
            <p>{x.societa}</p>
          </div>
          <div>
            <Link to={{
                pathname: '/modificacliente', state: {
                  id: x._id}}
                }>
            <button type="button" class="bp3-button bp3-icon-add .modifier" modifier>modifica</button>
            </Link>
            <button type="button" style={{backgroundColor:'red'}} onClick={()=>this.preCancella(x._id)} class="bp3-button bp3-icon-add .modifier" modifier>cancella</button>

          </div>
          </div>

        )
      })*/}

      </div>
      </div>



    )
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(HomePage);
