import React, {PureComponent} from 'react';
import {Marker} from 'react-map-gl';
import {Link} from "react-router-dom";

const Cond = '<svg id="Layer_1" enable-background="new 0 0 496 496" height="512" viewBox="0 0 496 496" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m433.551 464h-31.551v-392c0-8.837-7.163-16-16-16h-120v-40c0-8.837-7.163-16-16-16h-111c-8.837 0-16 7.163-16 16v40h-13c-8.837 0-16 7.163-16 16v392h-31.551c-8.618 0-16.03 6.626-16.431 15.234-.428 9.189 6.888 16.766 15.982 16.766h114c4.418 0 8-3.582 8-8v-92h-6.548c-8.615 0-16.028-6.621-16.434-15.226-.433-9.188 6.889-16.774 15.982-16.774 4.831 0 145.545 0 141.548 0 8.615 0 16.028 6.621 16.434 15.226.433 9.188-6.888 16.774-15.982 16.774h-7v92c0 4.418 3.582 8 8 8h114c9.094 0 16.41-7.577 15.982-16.766-.401-8.608-7.813-15.234-16.431-15.234zm-224.551-133h-18c-8.836 0-16-7.164-16-16s7.164-16 16-16h18c8.836 0 16 7.164 16 16s-7.164 16-16 16zm0-60h-18c-8.836 0-16-7.164-16-16s7.164-16 16-16h18c8.836 0 16 7.164 16 16s-7.164 16-16 16zm0-60h-18c-8.836 0-16-7.164-16-16s7.164-16 16-16h18c8.836 0 16 7.164 16 16s-7.164 16-16 16zm0-60h-18c-8.836 0-16-7.164-16-16s7.164-16 16-16h18c8.836 0 16 7.164 16 16s-7.164 16-16 16zm95 180h-18c-8.837 0-16-7.164-16-16s7.163-16 16-16h18c8.837 0 16 7.164 16 16s-7.163 16-16 16zm0-60h-18c-8.837 0-16-7.164-16-16s7.163-16 16-16h18c8.837 0 16 7.164 16 16s-7.163 16-16 16zm0-60h-18c-8.837 0-16-7.164-16-16s7.163-16 16-16h18c8.837 0 16 7.164 16 16s-7.163 16-16 16zm0-60h-18c-8.837 0-16-7.164-16-16s7.163-16 16-16h18c8.837 0 16 7.164 16 16s-7.163 16-16 16zm-88 337v-84c0-4.418 3.582-8 8-8h48c4.418 0 8 3.582 8 8v84c0 4.418-3.582 8-8 8h-48c-4.418 0-8-3.582-8-8z"/></svg>'

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7`;

const SIZE = 20;

// Important for perf: the markers never change, avoid rerender when the map viewport changes
export default class Pins extends PureComponent {
  render() {
    const {data, select, onClick} = this.props;
    console.log('mostra data');
    console.log(data);

    return data.map((city, index) => (
      <Marker key={`marker-${index}`} longitude={city.coordinates[0]} latitude={city.coordinates[1]}>
        <div style={{background:'#ffffff8a',padding:10,fontWeight:800}}>
          <Link to={"/ordine/"+city.idMongo}><p>{city.fascia}</p></Link>
        </div>
        <svg id="Layer_1" enable-background="new 0 0 496 496" height={30} style={{
          cursor: 'pointer',
          fill: select && select._id == city._id ? '#black' :'#fff',
          stroke: 'none',
          background: select && select._id == city._id ? '#000' :'#fcc157',
          padding:10,
          borderRadius:20,
          transform: `translate(${-SIZE / 2}px,${-SIZE}px)`
        }} viewBox="0 0 496 496" xmlns="http://www.w3.org/2000/svg" onClick={() => onClick(city)}><path d="m433.551 464h-31.551v-392c0-8.837-7.163-16-16-16h-120v-40c0-8.837-7.163-16-16-16h-111c-8.837 0-16 7.163-16 16v40h-13c-8.837 0-16 7.163-16 16v392h-31.551c-8.618 0-16.03 6.626-16.431 15.234-.428 9.189 6.888 16.766 15.982 16.766h114c4.418 0 8-3.582 8-8v-92h-6.548c-8.615 0-16.028-6.621-16.434-15.226-.433-9.188 6.889-16.774 15.982-16.774 4.831 0 145.545 0 141.548 0 8.615 0 16.028 6.621 16.434 15.226.433 9.188-6.888 16.774-15.982 16.774h-7v92c0 4.418 3.582 8 8 8h114c9.094 0 16.41-7.577 15.982-16.766-.401-8.608-7.813-15.234-16.431-15.234zm-224.551-133h-18c-8.836 0-16-7.164-16-16s7.164-16 16-16h18c8.836 0 16 7.164 16 16s-7.164 16-16 16zm0-60h-18c-8.836 0-16-7.164-16-16s7.164-16 16-16h18c8.836 0 16 7.164 16 16s-7.164 16-16 16zm0-60h-18c-8.836 0-16-7.164-16-16s7.164-16 16-16h18c8.836 0 16 7.164 16 16s-7.164 16-16 16zm0-60h-18c-8.836 0-16-7.164-16-16s7.164-16 16-16h18c8.836 0 16 7.164 16 16s-7.164 16-16 16zm95 180h-18c-8.837 0-16-7.164-16-16s7.163-16 16-16h18c8.837 0 16 7.164 16 16s-7.163 16-16 16zm0-60h-18c-8.837 0-16-7.164-16-16s7.163-16 16-16h18c8.837 0 16 7.164 16 16s-7.163 16-16 16zm0-60h-18c-8.837 0-16-7.164-16-16s7.163-16 16-16h18c8.837 0 16 7.164 16 16s-7.163 16-16 16zm0-60h-18c-8.837 0-16-7.164-16-16s7.163-16 16-16h18c8.837 0 16 7.164 16 16s-7.163 16-16 16zm-88 337v-84c0-4.418 3.582-8 8-8h48c4.418 0 8 3.582 8 8v84c0 4.418-3.582 8-8 8h-48c-4.418 0-8-3.582-8-8z"/></svg>
      </Marker>
    ));
  }
}
