import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import {Link} from "react-router-dom";
import moment from 'moment';
import {statusConvert} from  "../../util/functions.js"


class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
        ordini:[],
    };


  }

  getMyStores(){
    const ApiTags= new API ({url:'/ristorantiadmins/'});
    var entity= {name:"ristorantiadmins"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.ristorantiadmins
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        newThis.setState({items:res.data,loading:false})

        let isadmin = obj.roles.indexOf('ADMIN_S')
        if(isadmin > -1){
          //newThis.getOrdini(res.data[0]._id)
        }else {
          newThis.getOrdini(res.data[0]._id)
          //newThis.getOrderSync(res.data[0]._id)
        }


      });
    })

  }

  getOrdini(idRist){
    console.log('pppppp');
    console.log(idRist);
    const ApiTags= new API ({url:'/prenotazionirist'});
    var entity= {name:"prenotazionirist"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.prenotazionirist
      .getOne({id:idRist},ppp)
      .then(res=>{
        console.log('clienenene');
        console.log(res.data);


        newThis.setState({ordini:res.data})


      });
    })
  }





  componentDidMount() {
    this.getMyStores()

  }

render() {
    let {ordini} = this.state;
    return (
      <div style={{height:'100%',overflowY: 'auto',padding:10}}>
        <h1>Prenotazioni</h1>
        {ordini.map((ordine,i)=>{
          return (
            <div className='overAct cardss' style={{width:'100%', fontWeight:400,fontSize:14,color:'gray',padding:10,borderRadius:10,marginBottom:10, display: 'table',display:'flex'}}>
                <div style={{width:'100%'}}>
                  <p style={{marginLeft:'auto',textAlign: 'right'}}>{moment(Number(ordine.giorno)).format("DD-MM-YYYY")}</p>
                  <strong style={{margin:0}}>{ordine.cliente.nome}</strong>
                  <p style={{margin:0}}>{ordine.cliente.telefono}</p>
                  <p style={{marginTop:0}}>{ordine.cliente.email}</p>

                  <p>Fascia: {ordine.fasciaOraria}</p>
                  <p  style={{background: '#fcc157' }} className='tagFas'>{ordine.npersone} persone</p>
                  <p className='tagFas colorStatus'>{statusConvert(ordine.status)}</p>
                  <a target='_blank' href={'https://api.whatsapp.com/send?phone='+ordine.cliente.telefono+'&text='}><button style={{ width: '100%',fontSize: 19,marginTop: 0,background:'#25d466'}} className='actionButton'>Inizia messaggio su whatsapp</button></a>

                </div>
              </div>
          )
        })}


      </div>



    )
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(HomePage);
