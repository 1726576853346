import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import Dropzone from 'react-dropzone'
import firebase from 'firebase';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import Img from 'react-image'
import {Link , Redirect} from "react-router-dom";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {
    Button,
    H5,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Position,
    Spinner,
    Switch,
    Tag,
    Tooltip,
    TagInput
} from "@blueprintjs/core";
import { Select , ItemRenderer } from "@blueprintjs/select";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Modal } from 'react-responsive-modal';


const SortableItem = SortableElement(({value, sortIndex}) => (
  <div style={{width:150}}>
      <Img style={{width:150}} src={value} loader={<p>pollo</p>}/>
  </div>
));

const SortableList = SortableContainer(({items}) => {
return (
  <div style={{overflow: 'scroll hidden',whiteSpace:'nowrap',display:'flex'}}>
    {items.map((value, index) => (
      <SortableItem key={`item-${value}`} index={index} value={value} />
    ))}
  </div>
);
});

class NewProd extends Component {

  constructor(props) {
    super(props);
    //this.getXXXX()
    this.state = {
      ristoranti:[],
      ristorantiSel:[],
      categorie:[],
      categorieSel:[],
      img:null,
      rifOrganiz:null,
      clienti:[],
      nome:null,
      cod_prod:null,
      descrizione:null,
      prezzo:0,
      sconto:0,
      scontoAsp:0,
      variante:[],
      trueVariante:false,
      continuativo:false,
      listax:[{id:'pppp',alias:'pollo'},{id:'yo',alias:'yo'},{id:'suc',alias:'suc'}],
      listaArtigiani:[],
      listaStagione:[],
      gallery:[],
      artigiani:{},
      provaArt:{},
      collezione:null,
      redirect:false,
      valori:null,
      progress:null,
      vegano:null,
      vegetariano:null,
      noGlutine:null,
      biologico:null,
      piccante:null,
      codAllerg:null,
      open:false,
      option:[],
      nomeOptions:null,
      maxSelect:0,
      chois:'Extra',
      optiontoSave:[],
      creaNew:false,
      optinRet:[],
      componibile:false,
    };


  }


  getOption(idR){
    const ApiTags= new API ({url:'/optionyrist/'});
    var entity= {name:"optionyrist"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.optionyrist
      .getOne({id:idR},ppp)
      .then(res=>{

        console.log('opzioni:');
        console.log(res.data);
        newThis.setState({optinRet:res.data})

      });
    })
  }


  getMyOrganiz(x){
    const ApiTags= new API ({url:'/organizazioneadmin/'+x});
    var entity= {name:"organizazioneadmin"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.organizazioneadmin
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati organ');
        console.log(res.data._id);
        newThis.setState({rifOrganiz:res.data._id})
        newThis.getXXXX(res.data._id)
      });
    })

  }

  addToArr(item){
    let {optiontoSave} = this.state;
    optiontoSave.push(item);
    this.setState(optiontoSave)
  }


  getMyStores(x){
    const ApiTags= new API ({url:'/ristorantiadmins/'+x});
    var entity= {name:"ristorantiadmins"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.ristorantiadmins
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati');
        console.log(res.data);
        newThis.setState({ristoranti:res.data})
        newThis.getOption(res.data[0]._id)

      });
    })

  }


  addOption(){
    let {option} = this.state;
    let cont = option.length;
    option.push({id:cont+1,nome:'la mia nuova scelta',price:0})
    this.setState({option})
  }

  cambiaOpt(value,index){
    let {option} = this.state;
    option[index][value.target.name]= value.target.value;
    this.setState({option})
  }
  remove(index){
    let {option} = this.state;
    option.splice(index, 1);
    this.setState({option})

  }

  removeFromTosave(index){
    let {optiontoSave} = this.state;
    optiontoSave.splice(index, 1);
    this.setState({optiontoSave})
  }

  addImg(xxx){


    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/prodotto'});
      var entity= {name:"prodotto"};

      Item.createEntity(entity);


      var obj = {
        id:newThis.props.location.state.id,
        gallery:[xxx]


      }

      Item.endpoints.prodotto
      .update(obj,ppp)
      .then(res=>{

      });
    })



  }

  handleUpload = (images) => {
      let img = this.state.img;
      var storage = firebase.storage();
      let newThis = this;
      //const image = images[0];
      images.forEach((image, i) => {
        const uploadTask = storage.ref(`images/${image.name}`).put(image);
        uploadTask.on(
          "state_changed",
          snapshot => {
            // progress function ...
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            this.setState({ progress });
          },
          error => {
            // Error function ...
            console.log(error);
          },
          () => {
            // complete function ...
            storage
              .ref("images")
              .child(image.name)
              .getDownloadURL()
              .then(url => {
                console.log('qui finitoooooo');
                console.log(url);
                img = url;

                newThis.setState({img})
                //this.setState({ url });
              });
          }
        );
      });



    };




  getValori(x){
    console.log('gang'+x);

    const ApiTags= new API ({url:'/piatto/'+x});
    var entity= {name:"piatto"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.piatto
      .getOne({id:x},ppp)
      .then(res=>{
        console.log('qui vloreee');
        console.log(res.data);
        newThis.setState({valori:res.data})
        console.log('gang');
        console.log(newThis.state.valori);
        console.log('suju');

        /*let arrayCateg = [];
        newThis.state.valori.categorie.forEach((item, i) => {
          arrayCateg.push(item._id)
        });*/


        newThis.setState({img:newThis.state.valori.img});
        newThis.setState({nome:newThis.state.valori.nome})
        newThis.setState({descrizione:newThis.state.valori.desc})
        newThis.setState({prezzo:newThis.state.valori.prezzo,sconto:newThis.state.valori.sconto,
        scontoAsp:newThis.state.valori.scontoAsp,})
        newThis.setState({categorieSel:newThis.state.valori.categorie})
        newThis.setState({ristorantiSel:newThis.state.valori.rifRistor})
        newThis.setState({vegano:newThis.state.valori.vegano,vegetariano:newThis.state.valori.vegetariano,noGlutine:newThis.state.valori.noGlutine,biologico:newThis.state.valori.biologico,piccante:newThis.state.valori.piccante})
        newThis.setState({codAllerg:newThis.state.valori.codAllerg})
        newThis.setState({optiontoSave:newThis.state.valori.options,componibile:newThis.state.valori.componibile})

      });
    })

  }




  getArtigiani(){
    //usa per il get
    const ApiTags= new API ({url:'/artigiano'});
    var entity= {name:"artigiano"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.artigiano
      .getAllParam({alias:newThis.state.name},ppp)
      .then(res=>{

        newThis.setState({listaArtigiani:res.data})
        console.log('brouos');
        console.log(newThis.state.listaArtigiani);
      });
    })


  }

  getStagione(){
    //usa per il get
    const ApiTags= new API ({url:'/stagione'});
    var entity= {name:"stagione"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.stagione
      .getAllParam({alias:newThis.state.name},ppp)
      .then(res=>{
        newThis.setState({listaStagione:res.data})
        console.log('brouos');
        console.log(newThis.state.listaStagione);
      });
    })
  }

  updateGalleryProdotto(array){


    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/prodotto'});
      var entity= {name:"prodotto"};

      Item.createEntity(entity);

      console.log('booooo');




      var obj = {
        id:newThis.props.location.state.id,
        gallery:array,



      }


      Item.endpoints.prodotto
      .update(obj,ppp)
      .then(res=>{

      //console.log(array);
      //alert('salvato')
    //newThis.setState({redirect:true})
      });
    })



  }


  postProdotto(){

    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/piatto'});
      var entity= {name:"piatto"};

      Item.createEntity(entity);


      var obj = {
        id:newThis.props.location.state.id,
        nome: newThis.state.nome,
        img: newThis.state.img,
        desc:newThis.state.descrizione,
        rifRistor:newThis.state.ristorantiSel,
        rifOrganiz:newThis.state.rifOrganiz,
        categorie:newThis.state.categorieSel,
        prezzo:newThis.state.prezzo,
        sconto:newThis.state.sconto,
        scontoAsp:newThis.state.scontoAsp,
        vegano:newThis.state.vegano,
        vegetariano:newThis.state.vegetariano,
        noGlutine:newThis.state.noGlutine,
        biologico:newThis.state.biologico,
        piccante:newThis.state.piccante,
        codAllerg:newThis.state.codAllerg,
        options:newThis.state.optiontoSave,
        componibile:newThis.state.componibile,
      }



      console.log('mostra');
      console.log(obj);
      Item.endpoints.piatto
      .update(obj,ppp)
      .then(res=>{

      newThis.setState({redirect:true})
        });

    })



  }


  _renderSelect2(item){
    //console.log('scoreggia');
    //console.log(item);
    //console.log(handleClick);
    return(
      <MenuItem
        key={item._id}
        label={item.nome}
        text={item.nome}
        onClick={()=>this.setState({collezione:item})}

      />
    )
  }


  getPar(){


    const ApiTags= new API ({url:'/lavori'});
    var entity= {name:"lavori"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.lavori
      .getAllOk(ppp)
      .then(res=>{
        //  console.log(res.data);
        });
    })
  }


  componentDidMount() {

    console.log('eskerin');

    this.getMyOrganiz();
    this.getMyStores();
    this.getValori(this.props.location.state.id);


  }

  creaObjOption(){
    let {option,nomeOptions,maxSelect,chois} =  this.state;

    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/option'});
      var entity= {name:"option"};

      Item.createEntity(entity);


      let obj = {
            nome: nomeOptions,
            chois:chois,
            rifRistor:newThis.state.ristoranti[0]._id,
            piatto:[newThis.props.location.state.id],
            maxSelect:maxSelect,
            option:option
      }



      console.log('mostra');
      console.log(obj);
      Item.endpoints.option
      .create(obj,ppp)
      .then(res=>{
      let {optiontoSave} = newThis.state;
      optiontoSave.push(res.data)
      newThis.setState({open:false,optiontoSave})
        });

    });
  }


  getXXXX(x){
    //usa per il get
    const ApiTags= new API ({url:'/categoriabyorg'});
    var entity= {name:"categoriabyorg"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.categoriabyorg
      .getOne({id:x},ppp)
      .then(res=>{

        newThis.setState({categorie:res.data})
        console.log('ciaooo');
        console.log(newThis.state.prodotto);
      });
    })
  }


  gestioneArtigiani(x){

    let {ristorantiSel} = this.state;
    let index = ristorantiSel.indexOf(x)
    if(index > -1){
      ristorantiSel.splice(index, 1);
    }else {
      ristorantiSel.push(x)
    }


    this.setState({ristorantiSel})

  }

  gestioneCategorie(x){

    let {categorieSel} = this.state;
    let index = categorieSel.indexOf(x)
    if(index > -1){
      categorieSel.splice(index, 1);
    }else {
      categorieSel.push(x)
    }


    this.setState({categorieSel})

  }

  rimuoviProdotto(){


    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'FIREBASE_AUTH_TOKEN' : id};
      const Item= new API ({url:'/piatto'});
      var entity= {name:"piatto"};

      Item.createEntity(entity);



      Item.endpoints.piatto
      .delete({id:newThis.props.location.state.id},ppp)
      .then(res=>{
        
      newThis.setState({redirect:true})
        });


    })



  }


  checedRist(x){
    let {ristorantiSel} = this.state;


    let index = ristorantiSel.indexOf(x)
    if(index > -1){
      return true
    }else {
      return false
    }
  }

  checed(x){
    let {categorieSel} = this.state;


    let index = categorieSel.indexOf(x)
    if(index > -1){
      return true
    }else {
      return false
    }
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({gallery}) => ({
      gallery: arrayMove(gallery, oldIndex, newIndex),
    }));
  };

render() {
    let {componibile,optinRet,creaNew,option, open,sconto,scontoAsp,codAllerg,vegano,vegetariano,noGlutine,biologico,piccante, img,categorie, ristoranti,gallery,redirect,clienti,nome,cod_prod,descrizione,prezzo,valori, trueVariante,continuativo,listax, listaArtigiani,listaStagione,artigiani} = this.state;
    if(redirect){
      return <Redirect to="/piatti" />
    }else {
      return (
        <div style={{background:'#f9fafb', height:'100%',overflowY: 'auto',padding:10,width:'100%',paddingBottom:50}}>
        <div className='flex' style={{maxWidth:900,marginLeft:'auto',marginRight:'auto'}}>
      <div className='x70c'>
      <Link to={{
        pathname: '/piatti'}
      }><p style={{marginTop:10,cursor:'pointer',color:'#6d6d6d',fontWeight:'600'}}>{"< piatti"}</p>
      </Link>
        <h1>{nome ? nome : '_'}</h1>
          {sconto > 0 ? <p className='tagFas'>sconto {sconto}% Delivery sul prodotto</p> : null}
          {scontoAsp > 0 ? <p style={{background:'#ff6d00'}} className='tagFas'>sconto {scontoAsp}% asporto sul prodotto</p> : null}

        <div>
        <div style={{marginBottom:30, padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <div style={{}}>
          <p>Nome</p>

          <input style={{width:'100%',marginBottom:20}} class="bp3-input .modifier" value={nome}onChange={(x)=>this.setState({nome:x.target.value})} modifier type="text" placeholder="nome" dir="auto" /> <br></br>
          <p>Desc</p>
          <textarea style={{width:'100%',marginBottom:20,height: 200 }} class="bp3-input .modifier" value={descrizione}onChange={(x)=>this.setState({descrizione:x.target.value})} modifier placeholder="descrizione" dir="auto"></textarea><br></br>
          <p>Codice allergeni</p>
          <input style={{width:'100%',marginBottom:20}} class="bp3-input .modifier" value={codAllerg}onChange={(x)=>this.setState({codAllerg:x.target.value})} modifier type="text" placeholder="nome" dir="auto" /> <br></br>

        </div></div>
        <div style={{marginBottom:30,padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
        <div style={{overflow: 'scroll hidden',whiteSpace:'nowrap',}}>

        <SortableList axis='x' items={gallery} onSortEnd={this.onSortEnd} />
        {/*gallery.map((x,i)=>{
          return (
            <Img style={{width:150}} src={x} loader={<p>pollo</p>}/>)
        })*/}

        {img ? (
          <img style={{width:100}} src={img} />
        ) : null}

        <Dropzone multiple={false} onDrop={acceptedFiles => this.handleUpload(acceptedFiles)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div class='drag' {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Trascina immagini del prodotto</p>
              </div>
            </section>
          )}
        </Dropzone>
        </div>
        </div>
        <div style={{marginBottom:30,padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <h3>Prezzi</h3>
          <p>Prezzo</p>
          <input class="bp3-input .modifier" modifier type="text" value={prezzo} onChange={(x)=>this.setState({prezzo:x.target.value})} placeholder="prezzo" dir="auto" /> <br></br>
          <p style={{marginTop:20}}>sconto delivery</p>
          <input style={{width:50}} class="bp3-input .modifier" modifier type="text" value={sconto} onChange={(x)=>this.setState({sconto:x.target.value})} placeholder="prezzo" dir="auto" /> <br></br>
          <sub>Lo sconto è espresso in %</sub>
          <p style={{marginTop:20}}>sconto asporto</p>
          <input style={{width:50}} class="bp3-input .modifier" modifier type="text" value={scontoAsp} onChange={(x)=>this.setState({scontoAsp:x.target.value})} placeholder="prezzo" dir="auto" /> <br></br>
          <sub>Lo sconto è espresso in %</sub>
      </div>






          {/*<label class="bp3-control bp3-checkbox .modifier">
            <input type="checkbox" modifier checked={trueVariante} onChange={()=>this.setState({trueVariante:!trueVariante})} />
            <span class="bp3-control-indicator"></span>
            il prodotto ha delle varianti
          </label>

          {trueVariante ? (
            <div style={{marginBottom:'1500px'}}>
              <h1 style={{color:'red'}}>opzione</h1>
                <TagInput
                    onChange={(variante: string[]) => this.setState({ variante })}
                    values={this.state.variante}
                />
            </div>
          ) : null}*/}

        </div>

      </div>




      <div className='x30c'>
        <div style={{marginTop:103,marginBottom:30, padding:30, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <div style={{paddingBottom:'10px'}}>


      <h1>Ristoranti</h1>
      <p style={{marginBottom:30}}>Seleziona i ristoranti in cui verra pubblicato queto piatto</p>
      {ristoranti.map((a,i)=>{
        return (
          <label class="bp3-control bp3-checkbox .modifier">
            <input type="checkbox" modifier checked={this.checedRist(a._id)} onChange={()=>this.gestioneArtigiani(a._id)} />
            <span class="bp3-control-indicator"></span>
            {a.nome}
          </label>
        )
      })}
    </div>

   </div>

{/*<div style={{marginTop:10,marginBottom:30, padding:30, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
     <div style={{paddingBottom:'10px'}}>


 <h1>Categorie</h1>
 <p style={{marginBottom:30}}>Seleziona i ristoranti in cui verra pubblicato queto piatto</p>
 {categorie.map((a,i)=>{
   return (
     <label class="bp3-control bp3-checkbox .modifier">
       <input type="checkbox" modifier checked={this.checed(a._id)} onChange={()=>this.gestioneCategorie(a._id)} />
       <span class="bp3-control-indicator"></span>
       {a.nome}
     </label>
   )
 })}
</div>

</div>*/}


<div style={{marginBottom:30, padding:30, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
<div style={{marginBottom:''}}>
  <h3  style={{marginTop:0}}>Opzioni piatto</h3>
  <label>componibile</label>
  <div>
    <input style={{width: 50,height:50}} checked={componibile} onChange={()=>this.setState({componibile:!this.state.componibile})} type='checkbox' />
  </div>
  {componibile ? (
    <div>
      <div style={{marginTop:30}}>
        {this.state.optiontoSave.map((option,i)=>{
          return (<div style={{background:'#f3f3f3',padding:10,borderRadius:5}}>
            <p style={{margin:0,fontWeight:600}}>{option.nome} <span style={{fontWeight:200}}>{option.chois}</span></p>
            <div style={{fontWeight:200}}>{
                option.option.map((o,u)=>{
                return <span>{o.nome} (€{o.price}) ,</span>
                })
              }</div>
            <div onClick={(x)=>this.removeFromTosave(i)} style={{marginLeft:0,cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"/></svg></div>
          </div>)
        })}
        <div onClick={()=>this.setState({open:true})} className='tagFas' style={{background:'#2b537d',cursor:'pointer',marginTop:20}}>aggiungi opzioni</div>

      </div>
    </div>
  ) : null}

    <Modal  open={open} onClose={()=>this.setState({open:false,creaNew:false})} center>
      <h2>Opzioni Piatti</h2>

      {creaNew ? (
        <div style={{position:'relative',width:'90%'}}>
          <div style={{display:'grid',gridTemplateColumns:'200px auto',paddingRight: 36}}  className='modals'>
            <div className='boxOption'>
                  <div>
                    <label>Nome</label>
                    <div>
                      <input onChange={(x)=>this.setState({nomeOptions:x.target.value})} type='text' />
                    </div>
                    <br></br>
                    <label>scelte massime</label>
                    <div>
                      <input onChange={(x)=>this.setState({maxSelect:x.target.value})} type='number' />
                    </div>
                    <br></br>
                    <label style={{marginTop:30}}>tipo di scelta</label>
                    <select style={{width:150}} onChange={(x)=>this.setState({chois:x.target.value})}>
                      <option value="Selezione">scelta singola</option>
                      <option selected value="Extra">Extra Multipla</option>
                      <option value="Deseleziona">Consenti di rimuovere scelte</option>
                    </select>
                  </div>




            </div>
            <div style={{background:'#eee',minHeight:300}}>
                <div onClick={()=>this.addOption()} style={{textAlign:'right',width:'100%',marginTop:10}}><div className='tagFas' style={{background:'transparent',border:'1px solid',color:'gray',textAlign:'right',cursor:'pointer'}}>nuova scelta</div></div>

                  <div style={{marginTop:30}}>
                    {option.map((item,i)=>{
                      return (
                        <div style={{background:'white',padding:10,width:'90%',display:'flex',marginLeft:'auto',marginRight:'auto',marginBottom:5,borderRadius:5}} key={i}>
                        <div>
                        </div>
                        <div>
                          <input onChange={(x)=>this.cambiaOpt(x,i)} style={{border:'1px solid #eee'}} type='text' name='nome' value={item.nome} />
                        </div>
                        <div style={{marginLeft:30}}>
                          <span>€</span><input onChange={(x)=>this.cambiaOpt(x,i)} style={{border:'1px solid #eee'}} type='number' name='price' value={item.price} />
                        </div>
                        <div>
                          <div onClick={(x)=>this.remove(i)} style={{marginLeft:30}}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"/></svg></div>
                        </div>
                        <div></div>
                      </div>)
                    })}
                  </div>

              </div>

          </div>
        </div>
      ) : (
        <div>
          {optinRet.map((option,i)=>{
            return (
              <div onClick={()=>this.addToArr(option)} style={{marginBottom:5,padding:10,cursor:'pointer'}}>
                <p style={{margin:0,fontWeight:800}}>{option.nome} <span style={{fontWeight:200}}>{option.chois}</span></p>
                <div style={{fontSize:10,fontWeight:200}}>
                  {option.option.map((x,o)=>{
                    return <span>{x.nome} ({x.price}),</span>
                  })}
                </div>
              </div>
            )
          })}
        </div>
      )}


        <div style={{padding:10,textAlign:'right'}}>
          <Button style={{color:'white',fontWeight:800,background:'#2b537d'}} onClick={()=> this.creaObjOption()}>Aggiungi</Button>
          <Button style={{color:'white',fontWeight:800,background:'#2b537d',marginLeft:20}} onClick={()=> this.setState({creaNew:true})}>nuovo</Button>
        </div>

      </Modal>





  {/*<TagInput sty
        onChange={(variante: string[]) => this.setState({ variante })}
        values={this.state.variante}
    />*/}
</div>
</div>




   <div style={{marginBottom:30, padding:30, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
   <div style={{marginBottom:''}}>
     <h3  style={{marginTop:0}}>Etichette</h3>

       <label class="bp3-control bp3-checkbox .modifier">
         <input name='vegetariano' type="checkbox" modifier checked={vegetariano} onChange={(x)=>this.setState({[x.target.name]:!this.state[x.target.name]})} />
         <span class="bp3-control-indicator"></span>
         Vegetariano
       </label>

       <label class="bp3-control bp3-checkbox .modifier">
         <input name='vegano' type="checkbox" modifier checked={vegano} onChange={(x)=>this.setState({[x.target.name]:!this.state[x.target.name]})} />
         <span class="bp3-control-indicator"></span>
         Vegano
       </label>

       <label class="bp3-control bp3-checkbox .modifier">
         <input name='noGlutine' type="checkbox" modifier checked={noGlutine} onChange={(x)=>this.setState({[x.target.name]:!this.state[x.target.name]})} />
         <span class="bp3-control-indicator"></span>
         noGlutine
       </label>

       <label class="bp3-control bp3-checkbox .modifier">
         <input name='biologico' type="checkbox" modifier checked={biologico} onChange={(x)=>this.setState({[x.target.name]:!this.state[x.target.name]})} />
         <span class="bp3-control-indicator"></span>
         biologico
       </label>

       <label class="bp3-control bp3-checkbox .modifier">
         <input name='piccante' type="checkbox" modifier checked={piccante} onChange={(x)=>this.setState({[x.target.name]:!this.state[x.target.name]})} />
         <span class="bp3-control-indicator"></span>
         piccante
       </label>


     {/*<TagInput sty
           onChange={(variante: string[]) => this.setState({ variante })}
           values={this.state.variante}
       />*/}
   </div>
   </div>
      </div>





        </div>
        <div style={{maxWidth:900,marginLeft:'auto',marginRight:'auto'}}>
        <button style={{float:'right',background:'#4a69bd',color:'white',fontWeight:'800',padding:10}} type="button" onClick={()=>this.postProdotto()}class="bp3-button .modifier" modifier>salva</button>
        <button style={{float:'right',background:'red',color:'white',fontWeight:'800',padding:10,marginRight:20}} type="button" onClick={()=>this.rimuoviProdotto()}class="bp3-button .modifier" modifier>Rimuovi</button>

        </div>
        </div>



      )
    }

  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(NewProd);
