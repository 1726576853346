export const LANDING = '/';
export const ADDPAGE = '/0/lavoro/new'
export const ADDPAGESINGOLA = '/0/lavoro/new/singola'
export const CLIENTI = '/clienti';
export const GRUPPI = '/categorie';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const LAVORO = '/lavoro/:id';
export const USER = '/staff/:id';
export const ORDINI = '/ordini';
export const PRENOTAZIONI = '/prenotazioni';
export const PRODOTTI = '/piatti';
export const POST = '/post';
export const NEWPROD = '/newprod';
export const NEWPIATTO = '/add_piatto';
export const NEWPOST = '/add_post';
export const NEWCLIENTE = '/newcliente';
export const MATERIALE = '/materiale';
export const NEWMATERIALE = '/newmateriale';
export const CATEGORIEEDIT = '/modificacategoria';
export const MODIFICAMATERIALE = '/modificamateriale';
export const MODIFICAPRODOTTO = '/modificapiatti';

export const MODIFICAPOST = '/modificapost';

export const MODIFICACLIENTE = '/modificacliente';
export const VENDITORE = '/venditore';
export const WEB = '/setting';
export const MENUS = '/menus';
export const ADDMENU = '/addmenu';
export const MENU = '/menu/:id';
export const RSETTING = '/ristorantesetting';
export const ORARISETTING = '/orarisetting';
export const RSETTINGADMIN = '/ristorantesettingadmin';
export const ORDER = '/ordine/:id';
export const VENDITOREORDINE = '/venditoreordine/:id';
export const PDF = '/pdf';
export const RADMIN = '/rsadmin';
export const QUIK = '/quik/:id';
