import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';




const config = {
  apiKey: "AIzaSyDFFMjkKomMBmjZ2_Mkki3Xz7F8XKICJeE",
    authDomain: "newsilviag.firebaseapp.com",
    databaseURL: "https://newsilviag.firebaseio.com",
    projectId: "newsilviag",
    storageBucket: "newsilviag.appspot.com",
    messagingSenderId: "376163678175",
    appId: "1:376163678175:web:3a1a48d5079343c0fdb66a",
    measurementId: "G-DER8TYXQ16"
};

class Firebase {

  constructor() {
    app.initializeApp(config);




    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);


  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);


  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: 'https://mydesk2-1c4ae.firebaseapp.com/?email=' + this.auth.currentUser.email,
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);


  tokenForApi = (callback) =>{
    //alert('Ciao ' + nome + ' ' + cognome);
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {

        console.log('qui se vuoi trovarmi!!!!');

        authUser.getIdToken().then(function(idToken) {
            //alert(idToken)  // <------ Check this line
            if (callback && typeof callback === 'function') callback(idToken);
           //console.log(idToken); // It shows the Firebase token now
        });

      }
    })

  }


  tokenAndID = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
          this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();
              this.auth.currentUser.getIdTokenResult()
              .then((idTokenResult) => {
                 console.log('qui dovrei vedere qualcosa');
                 console.log(idTokenResult.token);
                 console.log(idTokenResult.claims);

                 authUser = {
                   idToken:idTokenResult.token,
                   role:idTokenResult.claims.role ? idTokenResult.claims.role : null ,
                   prova:'pollastro',
                   uid: authUser.uid,
                   email: authUser.email,
                   emailVerified: authUser.emailVerified,
                   providerData: authUser.providerData,
                   ...dbUser,
                 };

                 console.log('qui finisco');
                 next(authUser);

              })
              .catch((error) => {
                console.log(error);
              });
            });
      } else {
        fallback();
      }
    });
  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        //console.log(authUser);
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            // default empty roles
            /*if (!dbUser.roles) {
              dbUser.roles = [];
            }*/


            this.auth.currentUser.getIdToken(true).then(function(idToken) {
                // Send token to your backend via HTTPS
                // ...
                //
                localStorage.setItem('idToken', JSON.stringify({idToken}));
                console.log(idToken);

              }).catch(function(error) {
                console.log('errr');
              });
            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });







  ordini = id => this.db.ref(`ordini/${id}/orderList`).orderByChild('timeToDelivery') ;
  prenotazioni = id => this.db.ref(`prenotazioni/${id}/prenotazioni`).orderByChild('giorno') ;
  rooms = () => this.db.ref('rooms');
  pages = () => this.db.ref('pages');
  room = id => this.db.ref(`rooms/${id}`);
  page = id => this.db.ref(`pages/${id}`);

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  // *** Message API ***

  message = uid => this.db.ref(`messages/${uid}`);

  messages = () => this.db.ref('messages');
}

export default Firebase;
