import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import {Link , Redirect} from "react-router-dom";
import {
    Button,
    H5,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Position,
    Spinner,
    Switch,
    Tag,
    Tooltip,
    TagInput
} from "@blueprintjs/core";
import { Select , ItemRenderer } from "@blueprintjs/select";
import { BrowserRouter as Router, Route } from "react-router-dom";



class ModificaCliente extends Component {

  constructor(props) {
    super(props);
    //this.getXXXX()
    this.state = {
      cliente:null,
      idRist:null,
      storico:[],
    };


  }



  getXXXX(){
    //usa per il get
    const ApiTags= new API ({url:'/cliente'});
    var entity= {name:"cliente"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.cliente
      .getOne({id:newThis.props.location.state.id},ppp)
      .then(res=>{
        console.log('quiii arrivo');
        console.log(res.data);
        newThis.setState({cliente:res.data})

      });
    })


  }


    getValori(x){
      console.log('gang'+x);

      const ApiTags= new API ({url:'/cliente/'+x});
      var entity= {name:"cliente"};
      ApiTags.createEntity(entity);
      var newThis = this;
      this.props.firebase.tokenForApi(function(id) {
        var ppp = {'Authorization' : 'Bearer '+id};
        ApiTags.endpoints.cliente
        .getOne({id:x},ppp)
        .then(res=>{

          newThis.setState({valori:res.data})
          console.log('gang');
          console.log(newThis.state.valori);
          console.log('suju');

          newThis.setState({societa:newThis.state.valori.societa})
          newThis.setState({rag_sociale:newThis.state.valori.rag_sociale})
          newThis.setState({cod_fiscale:newThis.state.valori.cod_fiscale})
          newThis.setState({p_iva:newThis.state.valori.p_iva})

        });
      })

    }


  postProdotto(){

    console.log('proviamo a vedere');
    console.log(this.state);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/cliente'});
      var entity= {name:"cliente"};

      Item.createEntity(entity);

      console.log('booooo');

      var obj = {
        id:newThis.props.location.state.id,
        societa:  newThis.state.societa,
        cod_fiscale:newThis.state.cod_fiscale,
        rag_sociale:newThis.state.rag_sociale,
        p_iva:newThis.state.p_iva

      }


      console.log('mostra');
      console.log(obj);
      Item.endpoints.cliente
      .update(obj,ppp)
      .then(res=>{

    newThis.setState({redirect:true})
      });
    })



  }


  getMyStores(){
    const ApiTags= new API ({url:'/ristorantiadmins/'});
    var entity= {name:"ristorantiadmins"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.ristorantiadmins
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        newThis.setState({idRist:res.data[0]._id})

        let isadmin = obj.roles.indexOf('ADMIN_S')
        if(isadmin > -1){
          //newThis.getOrdini(res.data[0]._id)
        }else {
          newThis.getOrdiniRist(newThis.props.location.state.id,res.data[0]._id)
          //newThis.getOrderSync(res.data[0]._id)
        }


      });
    })

  }


  _renderSelect2(item){
    //console.log('scoreggia');
    //console.log(item);
    //console.log(handleClick);
    return(
      <MenuItem
        key={item._id}
        label={item.nome}
        text={item.nome}
        onClick={()=>this.setState({collezione:item})}

      />
    )
  }


  getPar(){


    const ApiTags= new API ({url:'/lavori'});
    var entity= {name:"lavori"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.lavori
      .getAllOk(ppp)
      .then(res=>{
        //  console.log(res.data);
        });
    })
  }


  getOrdiniRist(id,idR){
		const ApiTags= new API ({url:'/oridifattiperrist'});
		var entity= {name:"oridifattiperrist"};
		ApiTags.createEntity(entity);
			var ppp = {};
			ApiTags.endpoints.oridifattiperrist
			.getAllMulty({id:id,idr:idR},ppp)
			.then(res=>{

          console.log('qui che cè');
          console.log(res.data);
					this.setState({storico:res.data})

				});
	}


  componentDidMount() {

    console.log('eskerin');
    console.log(this.props.location.state.id);
    this.getMyStores();
    this.getXXXX();


  }

render() {
    let {cliente,storico} = this.state;
    return (
      cliente ? (
        <div style={{ background:'#f9fafb', height:'100%',overflowY: 'auto',padding:10,width:'100%',paddingBottom:50}}>
          <div style={{marginTop:50}} className='flex'>
          <div className='x70c' >
            <h1>{cliente.nome}</h1>
            <div style={{marginBottom:30, padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
              <div style={{}}>
              <h3>{storico.length} ordini totali clinete</h3>
                <br></br>
                <br></br>
              {storico.map((ordine,i)=>{
                return (
                  <div key={i}>
                    <p>{ordine.type}</p>
                    <p>{new Date(Number(ordine.order_made_time)).toGMTString()}</p>
                    <br></br>
                    <p>Stato ordine: {ordine.StatusOrdine}</p>
                    <hr></hr>
                  </div>
                )
              })}
            </div></div>

          </div>
          <div className='x30c' >
            <div style={{marginTop:22}}>
              <p>Contatti</p>
                <div style={{marginBottom:30, padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
                  <div style={{}}>
                    <p>{cliente.email}</p>
                    <p>{cliente.telefono}</p>
                </div></div>
            </div>

          </div>
        </div>
      </div>
      ) : null


    )

  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(ModificaCliente);
