import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import Dropzone from 'react-dropzone'
import firebase from 'firebase';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import Img from 'react-image'
import {Link , Redirect} from "react-router-dom";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Noimg from "../VenditoreOrdine/no-img.jpg";
import {
    Button,
    H5,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Position,
    Spinner,
    Switch,
    Tag,
    Tooltip,
    TagInput
} from "@blueprintjs/core";
import { Select , ItemRenderer } from "@blueprintjs/select";
import { BrowserRouter as Router, Route } from "react-router-dom";



const SortableItem = SortableElement(({value}) => (
  <Link to={{
     pathname: '/modificacategoria/', state: {
     id: value._id}}
   }><div>
  <div style={{display:'flex',paddingLeft:0,paddingRight:20,cursor:'pointer',marginBottom:10,width:'100%'}}>
 <div  style={{display:'flex',width:'100%'}}>
   <div  style={{marginLeft:10,padding:10,width:600}}>
     <strong style={{color:'#000'}}>{value.nome} <span style={{fontWeight:200,color:value.active ? 'green' : 'red'}}>{value.active ? 'visibile' : 'non visibile'}</span></strong>

   </div>
   <div  style={{marginLeft:10,padding:10,width:600}}>
     <p style={{color:'#000'}}><span style={{marginRight:5}}>{value.piatti.length}</span>piatti</p>

   </div>
   <div  style={{marginLeft:10,padding:10,width:'100%'}}>
     <p style={{textAlign:'right',marginRight:'auto'}}>
     <span style={{color:'black'}} >modifica</span></p>
   </div>
 </div>
</div>
</div></Link>
));

const SortableList = SortableContainer(({items}) => {
  return (
    <div style={{position:'relative'}}>
      {items.map((value, index) => (
        <SortableItem disabled={true} key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  );
});

var _ = require('lodash');

class NewProd extends Component {

  constructor(props) {
    super(props);
    //this.getXXXX()
    this.state = {
      categorieMenu:[],
      addCategoria:null,
      menu:null,
      open:false,
      redirect:false,
      nome:null,
      desc:null,
      piattiOk:[],
      active:true,
      rifRistor:[],
      rifOrganiz:null,
      items:[],
      clienti:[],
      ristoranti:[],
      ristorantiSel:[],
      piatti:[],
      selectedP:{},
      arraySele:[],
      piattiFilter:[],
    };


  }

  onSortEnd = ({oldIndex, newIndex}) => {
    let {categorieMenu} = this.state;
    categorieMenu[newIndex].position = newIndex;

    let arrayNew = arrayMove(this.state.categorieMenu, oldIndex, newIndex);
    console.log(arrayNew);
    this.setState(({categorieMenu}) => ({
      categorieMenu: arrayMove(categorieMenu, oldIndex, newIndex),
    }));
    console.log(categorieMenu);
  };

  selectedPA(item){
    let {selectedP} = this.state;
    selectedP[item] = selectedP[item] ? !selectedP[item] : true
    this.setState({selectedP})
  }

  getMyOrganiz(x){
    const ApiTags= new API ({url:'/organizazioneadmin/'+x});
    var entity= {name:"organizazioneadmin"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.organizazioneadmin
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati organ');
        console.log(res.data._id);
        newThis.setState({rifOrganiz:res.data._id})
        newThis.getXXXX(res.data._id);


      });
    })

  }


  getMyStores(x){
    const ApiTags= new API ({url:'/ristorantiadmins/'+x});
    var entity= {name:"ristorantiadmins"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.ristorantiadmins
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati');
        console.log(res.data);
        newThis.setState({ristoranti:res.data})

      });
    })

  }

  getMenu(){

    console.log('mostro qqq');
    console.log(this.props.match.params.id);
    //usa per il get
    const ApiTags= new API ({url:'/menu'});
    var entity= {name:"menu"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.menu
      .getOne({id:newThis.props.match.params.id},ppp)
      .then(res=>{
        newThis.setState({menu:res.data})

      });
    })
  }


  getCategoryMenu(){


    //usa per il get
    const ApiTags= new API ({url:'/categoriabymenuall'});
    var entity= {name:"categoriabymenuall"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.categoriabymenuall
      .getOne({id:newThis.props.match.params.id},ppp)
      .then(res=>{
        newThis.setState({categorieMenu:res.data})

      });
    })
  }


  rimuoviProdotto(){


    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/menu'});
      var entity= {name:"menu"};

      Item.createEntity(entity);



      Item.endpoints.menu
      .delete({id:newThis.props.location.state.id},ppp)
      .then(res=>{

      newThis.setState({redirect:true})
        });


    })



  }

  addPiatti(array){
    var keys = Object.keys(array);

    var filtered = keys.filter(function(key) {
        return array[key]
    });
    console.log('filtered');
    console.log(filtered);


    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/categoria'});
      var entity= {name:"categoria"};

      Item.createEntity(entity);
;

      let arrayArti = []

      newThis.state.ristoranti.forEach((item, i) => {
          if(item[item._id]){
            arrayArti.push(item._id)
          }

      });


      var obj = {
        id:newThis.props.location.state.id,
        piatti:filtered,

      }


      Item.endpoints.categoria
      .update(obj,ppp)
      .then(res=>{

        newThis.setState({open:false})
        newThis.getValori(newThis.props.location.state.id);
        newThis.getXXXX(newThis.state.rifOrganiz)
        });


    })



  }

  postProdotto(){

    console.log('proviamo a vedere');
    console.log(this.state);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/menu'});
      var entity= {name:"menu"};

      Item.createEntity(entity);
;

      let arrayArti = []

      


      var obj = {
        id:newThis.state.menu._id,
        nome:newThis.state.menu.nome,
        desc:newThis.state.menu.desc,
        img:newThis.state.menu.img,
        active:true,
       // rifRistor:newThis.state.ristorantiSel,



      }



      console.log('mostra');
      console.log(obj);

      Item.endpoints.menu
      .update(obj,ppp)
      .then(res=>{

      newThis.setState({redirect:true})
        });


    })



  }

  gestioneArtigiani(x){

    let {ristorantiSel} = this.state;
    let index = ristorantiSel.indexOf(x)
    if(index > -1){
      ristorantiSel.splice(index, 1);
    }else {
      ristorantiSel.push(x)
    }


    this.setState({ristorantiSel})

  }


  getXXXX(xId){
    //usa per il get
    const ApiTags= new API ({url:'/piattobyorg'});
    var entity= {name:"piattobyorg"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.piattobyorg
      .getOne({id:xId},ppp)
      .then(res=>{

        newThis.setState({piatti:res.data,piattiFilter:res.data})
        console.log('ciaooo');
        console.log(res.data);
      });
    })
  }



  mettiOnline(idR){

    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/ristorante'});
      var entity= {name:"ristorante"};

      Item.createEntity(entity);


      var obj = {
        id:idR,
        menuPrincipale:newThis.props.match.params.id,
        
      }



     
      Item.endpoints.ristorante
      .update(obj,ppp)
      .then(res=>{

      //newThis.setState({redirect:true})
      alert('messo online')
    });

    })



  }

  getValori(x){
    console.log('gang'+x);

    const ApiTags= new API ({url:'/categoria/'+x});
    var entity= {name:"categoria"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.categoria
      .getOne({id:x},ppp)
      .then(res=>{
        console.log('qui vloreee');
        console.log(res.data);
        newThis.setState({valori:res.data})
        console.log('gang');
        console.log(newThis.state.valori);
        console.log('suju');

        /*let arrayCateg = [];
        newThis.state.valori.categorie.forEach((item, i) => {
          arrayCateg.push(item._id)
        });*/

        let selectedP = {}
        newThis.state.valori.piatti.forEach((item, i) => {
          selectedP[item._id] = true
        });
        console.log('selecteee');
        console.log(selectedP);

        newThis.setState({desc:newThis.state.valori.desc, nome:newThis.state.valori.nome,piattiOk:newThis.state.valori.piatti,selectedP})





        newThis.setState({ristorantiSel:newThis.state.valori.rifRistor})



      });
    })

  }


  





  componentDidMount() {
      this.getMenu()
      this.getCategoryMenu()
      this.getMyStores();

  }

  checedRist(x){
    let {ristorantiSel} = this.state;


    let index = ristorantiSel.indexOf(x)
    if(index > -1){
      return true
    }else {
      return false
    }
  }

  addCategoria(x){
    let categoria ={
      nome: x.target.value,
      rifMenu: this.props.match.params.id,
      active: true
    }
    this.setState({addCategoria:categoria})
  }
  actionAggiungi(){
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/categoria'});
      var entity= {name:"categoria"};

      Item.createEntity(entity);


      var obj = newThis.state.addCategoria;



      Item.endpoints.categoria
      .create(obj,ppp)
      .then(res=>{
            newThis.setState({open:false})
            newThis.getCategoryMenu()

        });


    })
  }

  changeee(type,text){
    let {menu} = this.state;
    menu[type] = text;
    this.setState({menu})
  }

  removeImg(){
    let {menu} = this.state;
    menu.img = null;
    this.setState({menu})
  }

  handleUpload = (images) => {
    let {menu} = this.state;
    var storage = firebase.storage();
    let newThis = this;
    //const image = images[0];
    images.forEach((image, i) => {
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        snapshot => {
          // progress function ...
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({ progress });
        },
        error => {
          // Error function ...
          console.log(error);
        },
        () => {
          // complete function ...
          storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then(url => {
              console.log('qui finitoooooo');
              console.log(url);
              //img = url;
              menu.img = url
              newThis.setState({menu})
              
              //this.setState({ url });
            });
        }
      );
    });



  };


render() {
    let {desc,categorieMenu, menu,piattiFilter,piattiOk,selectedP,piatti,open,ristoranti,gallery,redirect,clienti,nome,cod_prod,descrizione,prezzo,valori, trueVariante,continuativo,listax, listaArtigiani,listaStagione,artigiani} = this.state;
    if(redirect){
      return <Redirect to="/menus" />
    }else {
      return (
        <div style={{background:'#f9fafb', height:'100%',overflowY: 'auto',padding:10,width:'100%',paddingBottom:50}}>

        <div style={{maxWidth:900,marginLeft:'auto',marginRight:'auto',display:'flex',}}>
      <div className='x70c'>
      <Link to={{
        pathname: '/menus'}
      }><p style={{marginTop:10,cursor:'pointer',color:'#6d6d6d',fontWeight:'600'}}>{"< Menus"}</p>
      </Link>
        <h1>{menu ? menu.nome : null}</h1>
        <p>Gestisci il tuo menu {menu ? menu.nome : null}, aggiungi categorie</p>

        <div style={{marginTop:79}}>
        <div style={{marginBottom:30, padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <div style={{}}>
          <p>Nome Menu</p>
          <input style={{width:'100%',marginBottom:20}} class="bp3-input .modifier" value={menu ? menu.nome : null} onChange={(x)=>this.changeee('nome',x.target.value)} modifier type="text" placeholder="nome" dir="auto" /> <br></br>
          <p>Descrizione</p>
          <input style={{width:'100%',marginBottom:20}} class="bp3-input .modifier" value={menu ? menu.desc : null} onChange={(x)=>this.changeee('desc',x.target.value)} modifier type="text" placeholder="desc" dir="auto" /> <br></br>


          <div>
            {menu && menu.img ? (
              <div>
              <img style={{width:'100%'}} src={menu.img} />
              <br></br>
              <button onClick={()=>this.removeImg()}>elimina</button>
              </div>
            ) : null}
          </div>
          <Dropzone multiple={false} onDrop={acceptedFiles => this.handleUpload(acceptedFiles,true)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div class='drag' {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Trascina immagine galleria homePage</p>
              </div>
            </section>
          )}
        </Dropzone>
        </div></div>
      <Modal  open={open} onClose={()=>this.setState({open:false})} center>
        <h2>Aggiungi categoria</h2>

          <div className='modals'>

            <labe>Nome categoria</labe>
            <input onChange={(x)=>this.addCategoria(x)} style={{width:'100%',border:'none',border:'1px solid #ddd',marginBottom:20,padding:10,borderRadius:10}} type='text'/>

          </div>
          <div style={{padding:10}}>
            <Button style={{color:'white',fontWeight:800,background:'#1cb7cd'}} onClick={()=> this.actionAggiungi()}>Aggiungi</Button>
          </div>

        </Modal>
        <div style={{marginBottom:30, padding:30,maxWidth:'95%', overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <div style={{textAlign: 'right'}}><Button onClick={()=>this.setState({open:true})} style={{background:'#1cb7cd',color:'white',border:'none'}}>Aggiungi Categoria</Button></div>
        <div style={{marginTop:50}}>


          <SortableList items={categorieMenu} onSortEnd={this.onSortEnd} />
          {/*categorieMenu.map((p,i)=>{
            return (
              <Link to={{
                 pathname: '/modificacategoria', state: {
                 id: p._id}}
                 }><div style={{display:'flex',paddingLeft:20,paddingRight:20,cursor:'pointer',marginBottom:10}}>
                <div  style={{display:'flex',width:'100%'}}>
                  <div  style={{marginLeft:10,padding:10}}>
                    <strong style={{color:'#000'}}>{p.nome}</strong>
                  </div>
                  <div  style={{marginLeft:10,padding:10,width:'100%'}}>
                    <p style={{textAlign:'right',marginRight:'auto'}}>modifica</p>
                  </div>
                </div>
              </div></Link>
            )
          })*/}
        </div>
        </div>
        </div>
        </div>
        <div className='x30c' >
        <div style={{marginTop:108,marginBottom:30, padding:30, paddingTop: 10, overflow: 'var(--p-override-visible,hidden)',backgroundColor: 'var(--p-surface,#fff)',boxShadow: 'var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15))'}}>
          <h2>Gestione menu</h2>
          {ristoranti.length > 0 ?  (
            <div style={{paddingBottom:'10px'}}>
            <p>{ristoranti[0].nome}</p>
            <button onClick={()=>this.mettiOnline(ristoranti[0]._id)}>Metti menu online</button>
            </div>
          ) : null} 
          

        </div>

        </div>






        </div>
        <div style={{maxWidth:900,marginLeft:'auto',marginRight:'auto'}}>
          <button style={{float:'right',background:'#4a69bd',color:'white',fontWeight:'800',padding:10}} type="button" onClick={()=>this.postProdotto()}class="bp3-button .modifier" modifier>salva</button>

          <button style={{float:'right',background:'red',color:'white',fontWeight:'800',padding:10,marginRight:10}} type="button" onClick={()=>this.rimuoviProdotto()}class="bp3-button .modifier" modifier>Rimuovi</button>
        </div>
        </div>



      )
    }

  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(NewProd);
