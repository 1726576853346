
import React from 'react';
import API from "../../util/Api.js"
import {Link} from "react-router-dom";


class Right extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      items:[]
    };
  }

  getAllSuggestion(){
    /*const axios = require('axios');

    axios.get('https://mcm-api-web.herokuapp.com/api/v1/staff').then(response => {
       console.log(response.data);

    });*/

    console.log('koko');
    var token = localStorage.getItem('idToken');
    //console.log('Bearer '+JSON.parse(token).idToken);
    var ppp = {'Authorization' : 'Bearer '+JSON.parse(token).idToken};
    const ApiTags= new API ({url:'/staff'});
    var entity= {name:"staff"};
    ApiTags.createEntity(entity);
    var newThis = this;
    ApiTags.endpoints.staff
    .getAllOk(ppp)
    .then(res=>{

      console.log(res.data);
      newThis.setState({items:res.data})



    });

  }

  componentDidMount() {
    this.getAllSuggestion();




  }


  render() {
    const {items} = this.state;
    return (
      <div className='itemUser' style={{marginTop: 55}}>
        {items.map((item, index) => (
            <Link to={item.uid ? "/staff/"+item.uid : "/staff/"+item._id}><div key={index} style={{display:'flex'}}>
              <div style={{ width: '38%',padding: 10,paddingTop: 15, paddingBottom: 15}}><img style={{width:63,height:63, borderRadius:33,objectFit: 'cover'}} src={item.img_profilo} /></div>
              <div style={{ width: '100%',padding: 10}}>
                <h4 style={{margin:0}}>{item.nome+' '+item.cognome}</h4>
              <p>{item.domicilio}</p>
              <p>{item.uid ? '' : 'test'}</p>
              </div>
            </div></Link>
          ))}
      </div>
    );
  }
}



export default Right
