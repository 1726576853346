export function decimal(num) {
  var n = num.toFixed(2);
  return n
}

export function statusConvert(staus){
	let statuss = {
		new:'Nuovo in fase di approvazione',
		PREP:'In preparazione',
		APR:'Accettato',
		SPED:'Spedito/Pronto per il ritiro',
		CHIUD:'Concluso',
    RIFIUTA:'rifiutato/annullato',
    CHIUDI:'Concluso',
    ACCETTA:'Accettato',

	}
	return statuss[staus];
}
