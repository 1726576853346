import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import { Spinner ,Tab, Tabs} from "@blueprintjs/core";
import API from "../../util/Api.js"
import {Link} from "react-router-dom";
import paypI from '../img/paypa.svg'
import moment from 'moment';
import Countdown from 'react-countdown';
import Sound from 'react-sound';
import Map from '../Map';
import { Modal } from 'react-responsive-modal';
import {statusConvert} from  "../../util/functions.js"
import boopSfx from './eventually.mp3';

var _ = require('lodash');



const Completionist = () => <span>tempo scaduto</span>;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed, total }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <div className='conterDw'><span style={{fontWeight:900, color: hours == 0 && minutes < 7 ? 'red' : 'green'}}>{hours ? hours+':' : null}{minutes}:{seconds}</span></div>;
  }
};

function compare( a, b ) {
  if ( a.Key < b.Key ){
    return -1;
  }
  if ( a.Key > b.Key ){
    return 1;
  }
  return 0;
}

class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
        open:false,
        prenotazione:null,
        selectedDelivery:[],
        loading:true,
        items:[],
        ordini:[],
        scheduled:[],
        ordeNow:[],
        oderClose:[],
        ordiniSyn:[],
        deliveryS:[],
        newPreno:[],
        prenoDa:[],
        prenochiuse:[],
        tab:'ng',
        openClos:false,
        alertStatus:Sound.status.STOPPED,
    };
  }


  updateStatusPreno(status){

    let {prenotazione} = this.state;
    console.log('vediamom ');
    console.log(prenotazione);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/prenostatus'});
      var entity= {name:"prenostatus"};

      Item.createEntity(entity);

      var obj = {
        rifRistor:prenotazione.rifRistor,
        id:prenotazione._id,
        status:status,
      }


      Item.endpoints.prenostatus
      .update(obj,ppp)
      .then(res=>{

        newThis.setState({open:false,prenotazione:null})

        });

  }
  )}



  getMyStores(x){
    const ApiTags= new API ({url:'/ristorantiadmins/'+x});
    var entity= {name:"ristorantiadmins"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.ristorantiadmins
      .getOne({id:obj.mongoId},ppp)
      .then(res=>{

        console.log('mostro dati');
        console.log(obj.mongoId);
        console.log(res.data);
        newThis.setState({items:res.data,loading:false})

        let isadmin = obj.roles.indexOf('ADMIN_S')
        if(isadmin > -1){
          //newThis.getOrdini(res.data[0]._id)
        }else {
          //newThis.getOrdini(res.data[0]._id)
          newThis.getOrderSync(res.data[0]._id)
        }


      });
    })

  }

  getOrdini(idRist){
    console.log('pppppp');
    console.log(idRist);
    const ApiTags= new API ({url:'/ordiniristday'});
    var entity= {name:"ordiniristday"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenAndID(function(obj) {
      var ppp = {'Authorization' : 'Bearer '+obj.idToken};
      ApiTags.endpoints.ordiniristday
      .getOne({id:idRist},ppp)
      .then(res=>{
        console.log('clienenene');
        console.log(res.data);
        let sortato = _.sortBy(res.data, [function(o) { return o.orario; }]);
        let group = sortato.reduce((r, a) => {
         console.log("a", a);
         console.log('r', r);
         r[a.orario] = [...r[a.orario] || [], a];
         return r;
        }, {});
        console.log(res.data);
        console.log('vediamo il gruppo');

        newThis.setState({ordini:group})


      });
    })
  }


  getOrderSync(idRisto){
    const today = new Date()
    const yesterday = new Date(today)

    let newPreno = [];
    let prenoDa = [];
    let prenochiuse = [];


    let self = this;

    yesterday.setDate(yesterday.getDate() - 1)

    this.props.firebase.prenotazioni(idRisto).startAt(yesterday.getTime()/ 1000).on('value', function(snapshot) {
      console.log('prenotaxioni');

      let newPreno = [];
      let prenoDa = [];
      let prenochiuse = [];
      //console.log(snapshot.val());
      var returnArr = [];

      let statusAll = Sound.status.STOPPED;
      snapshot.forEach(function(childSnapshot) {
          var item = childSnapshot.val();
          item.key = childSnapshot.key;
          if(item.status == 'new'){
            newPreno.push(item);
          }else if(item.status == 'ACCETTA') {
            prenoDa.push(item);
          }else {
            prenochiuse.push(item);
          }
          if(item.status == 'new'){
            statusAll = Sound.status.PLAYING;
          }

          /*console.log('vediamo new');
          console.log(item.status);

          if(item.status != "CHIUDxx"){
            returnArr.push(item);
          }*/


      });
      self.setState({newPreno,prenoDa,prenochiuse,alertStatus:statusAll})
    })

    this.props.firebase.ordini(idRisto).startAt(yesterday.getTime()/ 1000).on('value', function(snapshot) {
      console.log('mostro ordini');

      let orderNow = [];
      let scheduled = [];
      let oderClose = [];
      let deliveryS = [];
      //console.log(snapshot.val());
      var returnArr = [];

      let statusAll = Sound.status.STOPPED;
      snapshot.forEach(function(childSnapshot) {
          var item = childSnapshot.val();
          item.key = childSnapshot.key;
          if(item.status == 'new' || item.status == 'APR' || item.status == 'PREP' || item.status == 'SPED' ){
            let isTime = moment().add(30, 'm').isBefore(moment.unix(Number(item.timeToDelivery)).add(-2,'h'));
            if((item.status == 'APR') && isTime){
              scheduled.push(item);
            }else {
              orderNow.push(item);
            }
          }else {
            oderClose.push(item);
          }
          if(item.status == 'new'){
            statusAll = Sound.status.PLAYING;
          }

          if(item.status != 'CHIUD' && item.type == 'delivery'){
            deliveryS.push(item)
          }
          /*console.log('vediamo new');
          console.log(item.status);

          if(item.status != "CHIUDxx"){
            returnArr.push(item);
          }*/


      });



      self.setState({ordiniSyn:returnArr,alertStatus:statusAll,orderNow,scheduled,oderClose,deliveryS})
      console.log(returnArr);
    });
  }

  componentDidMount() {


    this.getMyStores()






  }

  test(x){
    console.log('vediamo fascia');
    console.log(x);
    let arrayT = x.split('-');
    if(x != 'prima possibile'){
      let times = moment(arrayT[0], 'HH:mm')
      console.log('vediano time');
      console.log(times.toDate());

    }

  }

  loading(){
    let {loading} = this.state;
    if(loading){
      return 'bp3-skeleton';
    }else {
      return null
    }
  }

  statusOrder(status){
    switch (status) {
      case "new":
        return '#09b300';
      case "SPED":
        return '#ffd329';
      case "PREP":
        return '#6029ff';
      default:
        return '#ff293a';
    }
  }


  getPrenotazioni(idP){
    const ApiTags= new API ({url:'/prenotazione'});
      var entity= {name:"prenotazione"};
      ApiTags.createEntity(entity);
      var newThis = this;
      this.props.firebase.tokenForApi(function(id) {
        var ppp = {'Authorization' : 'Bearer '+id};
        ApiTags.endpoints.prenotazione
        .getOne({id:idP},ppp)
        .then(res=>{
            newThis.setState({prenotazione:res.data,open:true})
          });
      })
  }

schedulingToO(item,x){
  let {orderNow,scheduled} = this.state;
  //console.log(x.total);
  let isTime = x.total / 60000;
  //console.log(isTime);
  if(isTime < 30){
    console.log('figataaa');
    orderNow.push(item)
    let indexRemove = _.findIndex(scheduled, {mongoId: item.mongoId})
    scheduled.splice(indexRemove, 1);
  }
  this.setState({orderNow,scheduled})
}

smistOrder(array){
  let orderNow = [];
  let scheduled = [];
  let oderClose = [];

  array.forEach((item, i) => {
      if(item.status == 'new' || item.status == 'APR' || item.status == 'PREP' || item.status == 'SPED' ){
        let isTime = moment().add(30, 'm').isBefore(moment.unix(Number(item.timeToDelivery)));
        if(isTime){
          scheduled.push(item);
        }else {
          orderNow.push(item);
        }
      }else {
        oderClose.push(item);
      }
  });

  this.setState({orderNow,scheduled,oderClose})


}

render() {
    let {prenoDa,prenotazione,open,newPreno,deliveryS,openClos,orderNow,scheduled,oderClose, alertStatus,items,ordini,tab,ordiniSyn} = this.state;
    return (
      <div style={{position:'absolute',left:0,right:0,top:0,bottom:0}}>

        <Modal  open={open} onClose={()=>this.setState({open:false})} center>
          <div style={{minWidth:600}}>
            {prenotazione ? (
              <div>
                <p>Informazioni</p>

                <div>
                  <h1 style={{marginBottom:0}}>{prenotazione.cliente.nome}</h1>
                  <p style={{margin:0}}>{prenotazione.cliente.telefono}</p>
                  <a target='_blank' href={'https://api.whatsapp.com/send?phone='+prenotazione.cliente.telefono+'&text='}><button style={{ width: '100%',fontSize: 19,marginTop: 0,background:'#25d466'}} className='actionButton'>Inizia messaggio su whatsapp</button></a>
                </div>

                <div style={{marginTop:50}}>
                <p style={{margin:0}}><p className='tagFas'>{prenotazione.fasciaOraria}</p></p>
                <p  style={{margin:0}}>Persone: <span style={{fontWeight:800}}>{prenotazione.npersone}</span></p></div>

                {prenotazione.status == 'new' ? (

                  <div>
                    <div style={{marginTop:50}}>
                      <p onClick={()=>this.updateStatusPreno('ACCETTA')} style={{background:'#eee',color:'black',cursor:'pointer'}} className='tagFas'>Accetta</p>
                      <p onClick={()=>this.updateStatusPreno('RIFIUTA')} style={{background:'#ff5555',color:'white',cursor:'pointer'}}  className='tagFas'>Rifiuta</p>
                    </div>
                    <p>* Se rifiuti usa il numero del clente per fargli conoscere il motivo</p>
                  </div>
                ) : <div style={{marginTop:50}}>
                <p  onClick={()=>this.updateStatusPreno('CHIUDI')} style={{textAlign:'center'}} className='actionButton'>Chiudi prenotazione</p>
              </div> }

              </div>
            ) : null}

          </div>
        </Modal>
        {/*items.map((x,i)=>{
          return(
            <div key={i} style={{borderRadius:9}}  className='cardList'>
              <div>
                <div style={{width:100,height:100}}>
                  <img style={{width:'100%',objectFit: 'cover',height:100,borderRadius:300}} src={x.img} />
                </div>


              <div className={'f1'}>
                <h4 style={{margin:0}} className={this.loading()}>{x.nome}</h4>
                <div style={{margin:0}} className={this.loading()} >{x.indirizzoCompleto}</div>
                <Link to={"/quik/"+x._id}>Quik</Link>
              </div>
              <Sound
                  url={boopSfx}
                  playStatus={alertStatus}
                  playFromPosition={0 /* in milliseconds }
                  onFinishedPlaying={()=>this.setState({alertStatus:Sound.status.PLAYING})}
                />
              </div>
            </div>
          )
        })*/}
      <div className='boxHome'>
        {items.length > 0 ? <div className='slotDisp'><Link to={"/quik/"+items[0]._id}><p style={{padding:20,paddingBottom:10}}>Modifica disponibilità</p></Link></div> : null}
        <div style={{position:'absolute',left:0,right:0,top:0,bottom:0}}>

          {items[0] && items[0].delivery ? (
            <Map geo={items[0].indirizzoGeo} mark={deliveryS} value={null}  callback={(x)=>this.setState({objLocation:x})} />

          ) : null}
          </div>
        <Sound
            url={boopSfx}
            playStatus={alertStatus}
            playFromPosition={0 /* in milliseconds */}
            onFinishedPlaying={()=>this.setState({alertStatus:Sound.status.PLAYING})}
          />
        <div className='scrollMega' style={{position:'relative',zIndex:2,overflow: 'scroll'}}>


            <div style={{padding:10}}>
              <p style={{fontWeight:800,color:'#777777'}}>Ordini da evadere</p>
              {orderNow && orderNow.map((item,i)=>{

                if(item.status == 'new'){
                  return(
                    <Link to={"/ordine/"+item.idMongo}>
                      <div className='overAct cardss' style={{fontWeight:400,fontSize:14,color:'gray',padding:10,borderRadius:10,marginBottom:10, display: 'table',display:'flex',background: '#53ff7a'}}>
                        <div>
                          <p>{item.cliente}</p>
                          <p>Fascia: {item.fascia}</p>
                          <p  style={{background: item.type == 'take_away' ? '#ff6d00' :'#fcc157' }} className='tagFas'>{item.type}</p>
                          <p className='tagFas colorStatus'>{statusConvert(item.status)}</p>
                        </div>

                        <div style={{marginLeft: 'auto'}}><Countdown  zeroPadTime={2}  date={ new Date(moment.unix(item.timeToDelivery).add(-2,'h').add(30,'m'))} renderer={renderer} /></div>
                      </div>
                    </Link>
                    /*<Link to={"/ordine/"+item.idMongo}>
                      <div className='overAct cardss' style={{fontWeight:400,fontSize:14,color:'gray',padding:10,borderRadius:10,marginBottom:10, display: 'table',background: '#53ff7a'}}>
                        <p style={{color:'#000',fontWeight:'800'}}>New</p>
                        <p>Ritiro {moment.unix(Number(item.timeToDelivery)).format("HH:MM")}</p>
                        <p>{item.timeToDelivery}</p>
                        <div><Countdown date={Number(item.timeToDelivery * 1000)} /></div>
                        <p className='tagFas'>{item.type}</p>
                      </div>
                    </Link>*/
                  )
                }else {
                  return(
                      <Link to={"/ordine/"+item.idMongo}>
                        <div className='overAct cardss' style={{fontWeight:400,fontSize:14,color:'gray',padding:10,borderRadius:10,marginBottom:10, display: 'table',display:'flex'}}>
                          <div>
                            <p>{item.cliente}</p>
                            <p>Fascia: {item.fascia}</p>
                            <p  style={{background: item.type == 'take_away' ? '#ff6d00' :'#fcc157' }} className='tagFas'>{item.type}</p>
                            <p className='tagFas colorStatus'>{statusConvert(item.status)}</p>
                          </div>
                          <div style={{marginLeft: 'auto'}}><Countdown  zeroPadTime={2}  date={ new Date(moment.unix(item.timeToDelivery).add(-2,'h').add(30,'m'))} renderer={renderer} /></div>
                        </div>
                      </Link>
                  )
                }

              })}

              <p style={{fontWeight:800,color:'#777777'}}>Ordini programmati</p>
              {scheduled && scheduled.map((item,i)=>{

                if(item.status == 'new'){
                  return(
                    <Link to={"/ordine/"+item.idMongo}>
                      <div className='overAct cardss' style={{fontWeight:400,fontSize:14,color:'gray',padding:10,borderRadius:10,marginBottom:10, display: 'table',background: '#ffdc9d'}}>
                        <p style={{color:'#000',fontWeight:'800'}}>New</p>

                        <div><Countdown date={Number(item.timeToDelivery * 1000)} /></div>
                        <p className='tagFas'>{item.type}</p>
                      </div>
                    </Link>
                  )
                }else {
                  return(
                      <Link to={"/ordine/"+item.idMongo}>
                        <div className='overAct cardss' style={{fontWeight:400,fontSize:14,color:'gray',padding:10,borderRadius:10,marginBottom:10, display: 'table',display:'flex',background:'#fcc15647'}}>
                          <div>
                            <p>{item.cliente}</p>
                            <p>Fascia: {item.fascia}</p>
                            <p className='tagFas'>{item.type}</p>
                          </div>

                          <div style={{marginLeft: 'auto'}}><Countdown  onTick={(x)=>this.schedulingToO(item,x)} zeroPadTime={2}  date={ new Date(moment.unix(item.timeToDelivery).add(-2,'h').add(30,'m'))} renderer={renderer} /></div>
                        </div>
                      </Link>
                  )
                }

              })}
              <p onClick={()=>this.setState({openClos:!openClos})} style={{fontWeight:800,cursor:'pointer',color: openClos ? '#777777'  :'#ddd'}}>Ordini chiusi</p>
              {openClos ? (
                oderClose && oderClose.map((item,i)=>{

                  return(
                      <Link to={"/ordine/"+item.idMongo}>
                        <div className='overAct cardss' style={{fontWeight:400,fontSize:14,color:'gray',padding:10,borderRadius:10,marginBottom:10, display: 'table',display:'flex',background: '#e0e0e0',opacity: '0.5'}}>
                          <div>
                            <p>{item.cliente}</p>
                            <p className='tagFas'>{item.type}</p>
                          </div>
                        </div>
                      </Link>
                  )

                })
              ) : null}

              <p style={{fontWeight:800,cursor:'pointer',color: '#777777'}}>Nuove prenotazioni</p>
                {newPreno.map((item,i)=>{

                  return(
                      <div onClick={()=>this.getPrenotazioni(item.idMongo)} className='overAct cardss' style={{fontWeight:400,fontSize:14,color:'gray',padding:10,borderRadius:10,marginBottom:10, display: 'table',display:'flex',background: '#53ff7a',cursor:'pointer'}}>
                          <div>
                            <p style={{fontWeight:700,margin:5}}>{item.cliente}</p>
                            <p style={{marginBottom:0}}><p className='tagFas'>{item.fasciaOraria}</p></p>
                            <p style={{margintop:0}}>Numero persone: {item.npersone}</p>

                          </div>
                        </div>
                  )

                })}
                <p style={{fontWeight:800,cursor:'pointer',color: '#777777'}}>Prenotazioni</p>
                {prenoDa.map((item,i)=>{

                  return(
                      <div onClick={()=>this.getPrenotazioni(item.idMongo)} className='overAct cardss' style={{fontWeight:400,fontSize:14,color:'gray',padding:10,borderRadius:10,marginBottom:10, display: 'table',display:'flex',background: '#fff',cursor:'pointer'}}>
                          <div>
                            <p style={{fontWeight:700,margin:5}}>{item.cliente}</p>
                            <p style={{marginBottom:0}}><p className='tagFas'>{item.fasciaOraria}</p></p>
                            <p style={{margintop:0}}>Numero persone: {item.npersone}</p>

                          </div>
                        </div>
                  )

                })}


              {/*Object.keys(ordini).map((keyName, i) => (
                  <div className="travelcompany-input" key={i}>
                      <h2 className="input-label">{keyName}</h2>
                        {ordini[keyName].map((ordine,i)=>{
                          if(ordine.StatusOrdine == 'new'){
                            return (
                              <Link to={"/ordine/"+ordine._id}>
                                <div className='overAct cardss' style={{fontWeight:400,fontSize:14,color:'white',padding:10,borderRadius:10,marginBottom:10, display: 'table',background: '#53ff7a'}}>
                                  <h2 style={{color:'#000',textAlign: 'center'}}>NEW</h2>
                                </div>
                              </Link>
                            )
                          }else {
                            return(
                              <Link to={"/ordine/"+ordine._id}><div className='overAct cardss' style={{fontWeight:400,fontSize:14,color:'gray',padding:10,borderRadius:10,marginBottom:10, display: 'table'}}>

                              {ordine.type != 'take_away' ? (
                                <div style={{display:'flex'}}>
                                  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M5 18h-1c-.552 0-1-.448-1-1v-2h12v-9h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.121-.728 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-4c0 1.656-1.344 3-3 3s-3-1.344-3-3zm3-1.2c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm10 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-10-8.8h-8v-2h8v-1h-5v-1c0-.552.448-1 1-1h9c.552 0 1 .448 1 1v10h-11v-3h-2v-2h7v-1zm9 0v3h4.715l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.42z"/></svg>
                                  <h2 style={{margin:0, marginLeft:10,marginTop:5}}>Consegna a casa</h2>
                              </div>
                            ):(
                              <div style={{display:'flex'}}>
                                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M18 23.999l-12 .001-1.983-11.578.637-1.123 3.692 4.173 7.264.003 3.721-4.214.652 1.16-1.983 11.578zm-5.075-17l1.622-6.258c.12-.447.524-.741.965-.741.651 0 1.139.618.968 1.258l-1.485 5.741h.398l4.214-5.571c.317-.452.941-.562 1.393-.245.452.316.562.94.246 1.392l-3.412 4.424h3.166l-.007.036c.212.382 2.059 3.718 2.869 5.2.083.153.138.318.138.48 0 1.036-1.399 1.384-1.893.48-.61-1.115-1.806-3.279-2.451-4.446l-4.68 5.25h-5.963l-4.642-5.327c-.634 1.146-1.87 3.383-2.494 4.523-.495.907-1.877.551-1.877-.48 0-.162.04-.327.123-.48.828-1.514 2.737-4.962 2.88-5.22l-.003-.016h9.925zm1.075 4.5c0-.276-.224-.5-.5-.5h-3c-.276 0-.5.224-.5.5s.224.5.5.5h3c.276 0 .5-.224.5-.5"/></svg>
                                <h2 style={{margin:0, marginLeft:10,marginTop:5}}>Passa il cliente</h2>

                              </div>
                            )}
                              <div>
                                <p style={{color:'black',marginTop:10}}>{ordine.cliente ? ordine.cliente.nome : null }</p>
                                <p><span className='tagFas'>{ordine.orario}</span> Fascia oraria </p>
                                <p>{moment(Number(ordine.order_made_time)).format("DD-MM-YYYY HH:mm")}</p>
                                {this.test(ordine.orario)}
                              </div>
                              <div style={{display:'flex'}}>
                                <div style={{marginLeft:'auto',display: 'flex'}}>
                                  <p>{ordine.StatusOrdine}</p><div style={{height:10,width:10,marginRight:10, background:this.statusOrder(ordine.StatusOrdine),borderRadius:20}}></div>

                                  {ordine.paymanType == 'pay_pal' ? <img style={{fill:'#00457C'}} src={paypI} /> : null}
                                </div>

                              </div>

                            </div></Link>)
                          }

                        })}
                  </div>
              ))*/}

            </div>
          </div>

      </div>
    </div>



    )
  }
}


export default compose(
  withFirebase,
  withEmailVerification,
  //withAuthorization(condition),
)(HomePage);
