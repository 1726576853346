import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import {Link, Redirect} from "react-router-dom";
import ClientiPages from '../Clienti';
import Noimg from "../VenditoreOrdine/no-img.jpg";
import Img from 'react-image'

import {
    Button,
    Classes,
    Code,
    Divider,
    H5,
    HTMLSelect,
    IOptionProps,
    Label,
    Position,
    Switch,
    Drawer,
} from "@blueprintjs/core";




class HomePage extends Component {

  constructor(props) {
    super(props);
    this.getXXXX()
    this.state = {
      clienti:[],
      prodotto:[],
      isOpen:false,
      redirect_id:null,
      redirect:false

    };


  }

  getClienti(){
    //usa per il get
    console.log('brodemirolic');
    const ApiTags= new API ({url:'/cliente'});
    var entity= {name:"cliente"};
    ApiTags.createEntity(entity);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      var ppp = {'Authorization' : 'Bearer '+id};
      ApiTags.endpoints.cliente
      .getAllParam({alias:newThis.state.name},ppp)
      .then(res=>{

        newThis.setState({clienti:res.data})
        console.log('ciaooo');
        console.log(newThis.state.clienti);
      });
    })
  }




    getXXXX(){
      //usa per il get
      const ApiTags= new API ({url:'/prodotto'});
      var entity= {name:"prodotto"};
      ApiTags.createEntity(entity);
      var newThis = this;
      this.props.firebase.tokenForApi(function(id) {
        var ppp = {'Authorization' : 'Bearer '+id};
        ApiTags.endpoints.prodotto
        .getAllParam({alias:newThis.state.name},ppp)
        .then(res=>{

          newThis.setState({prodotto:res.data})
          console.log('ciaooo');
          console.log(newThis.state.prodotto);
        });
      })
    }


      postOrdine(id_societa, nome){

    console.log(id_societa);
    console.log('proviamo a vedere');
    console.log(this.state);
    var newThis = this;
    this.props.firebase.tokenForApi(function(id) {
      //console.log('Bearer '+JSON.parse(token).idToken);
      var ppp = {'Authorization' : 'Bearer '+id};
      const Item= new API ({url:'/ordine'});
      var entity= {name:"ordine"};

      Item.createEntity(entity);

      console.log('booooo');

      var obj = {
        cliente_id: id_societa,
        cliente_nome:nome,
        anno:'2020'
      }


      console.log('mostra');
      console.log(obj);
      Item.endpoints.ordine
      .create(obj,ppp)
      .then(res=>{

      //console.log(res.data._id);
      newThis.setState({redirect_id:res.data._id})
      newThis.setState({redirect:true})

      });
    })


      }




  componentDidMount() {

    this.getClienti()

  }

render() {
      let {items,prodotto,isOpen,clienti, redirect, redirect_id} = this.state
      if(redirect){
        return <Redirect to={'/venditoreordine/'+redirect_id} />
      }else {

    return (
      <div style={{height:'100%',overflowY: 'auto'}}>
        <Drawer     style={{padding:10}}
                    isOpen={isOpen}
                    icon="info-sign"
                    onClose={()=>this.setState({isOpen:false})}
                    title="Informazioni Cliente"
                    position={Position.BOTTOM}
                    size={Drawer.SIZE_LARGE = '100%'}
                >

                <div style={{height:'100%',overflowY: 'auto',padding:10}}>
                  <h1> clienti</h1>
                    <Link to="/newcliente"><button type="button" class="bp3-button bp3-icon-add .modifier" modifier>add new</button>
                    </Link>
                    <div class="bp3-input-group .modifier" style={{width:'200px',marginBottom:'50px'}}>
                      <span class="bp3-icon bp3-icon-search"></span>
                      <input class="bp3-input" modifier type="search" placeholder="Search input" dir="auto" />
                    </div>
                  {clienti.map((x,i)=>{
                    return (
                    <div style={{borderBottom:'10px solid #f4f4f7',height:'auto',display:'flex',marginBottom:'10px'}}>
                    <div style={{display:'block'}}>
                    <h3>{x.societa}</h3>
                    <p style={{color:'red'}}>{x._id}</p>
                      <p>{x.societa}</p>
                    </div>
                    <div>
                    <button onClick={()=>this.postOrdine(x._id,x.societa)} style={{position:'relative',top:'10px',right:'10px'}}>crea ordine</button>
                    </div>
                    </div>

                  )
                  })}


                </div>




          <Link to={{
                pathname: '/venditoreordine'
              }}>
              <button type="button" style={{backgroundColor:'yellow', borderRadius:'100px',height:'50px',width:'50%',fontSize:'20px',fontWeight:'bolder'}} modifier>aggiungi</button>
            </Link>

        </Drawer>

          <div style={{height:'100%',overflowY: 'auto',padding:0,marginBottom:'160px',backgroundColor:'#f4f5f2'}}>
            <h1 style={{textAlign:'center'}}> Crea ordine</h1>

              <div class="bp3-input-group .modifier" style={{width:'200px',marginBottom:'50px',marginRight:'auto',marginLeft:'auto'}}>
                <span class="bp3-icon bp3-icon-search"></span>
                <input class="bp3-input" modifier type="search" placeholder="Search input" dir="auto" />
              </div>

              <div style={{borderTop:'1px solid gray'}}>
            {prodotto.map((x,i)=>{
              return (
              <div style={{display:'flex',marginBottom:'0px',borderBottom:'1px solid gray',backgroundColor:'white'}}>
                <Img style={{width:150, margin:20}} src={x.gallery.length > 0 ? x.gallery[0] : Noimg } loader={<p>Carica</p>}/>
            <div>

                <h3 style={{fontSize:'30px'}}>{x.nome}</h3>
                <p>{x.descrizione}</p>
                <p>{x.prezzo}€</p>

                </div>
              </div>)
            })}
    </div>

          </div>

        <div style={{display:'flex', position:'fixed',top:'90%',width:'100%',left:'0px',marginLeft:'auto',marginRight:'auto'}}>
          <button type="button" onClick={()=>this.setState({isOpen:true})} style={{backgroundColor:'#1E93E4',border:'#1E93E4',maxWidth:300, borderRadius:'100px',height:'50px',width:'50%',fontSize:'20px',fontWeight:'bolder',color:'white'}} modifier>Crea ordine</button>
            <button type="button" style={{backgroundColor:'#FCE158', border:'#FCE158', borderRadius:'100px',height:'50px',width:'50%',maxWidth:300,fontSize:'20px',fontWeight:'bolder'}} modifier>Interessato</button>


        </div>
      </div>

    )
  }
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(HomePage);
